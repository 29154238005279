<template>
	<button class="btn m-2" type="submit" :disabled="isLoading">
		<template v-if="isLoading">
			<span class="spinner-border spinner-border-sm" role="status"></span>
			Submitting...
		</template>
		<template v-else>
			<slot />
		</template>
	</button>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters({
			isLoading: "shared/getLoading",
		}),
	},
};
</script>
<style scoped>
.btn-info:disabled,
.btn-info.disabled {
	color: #fff;
	background-color: #29623c;
	border-color: #29623c;
}
</style>
