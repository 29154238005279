<template>
	<!-- [ Main Content ] start -->
	<div>
		<div class="row" style="margin-bottom: 0">
			<div class="col-md-10"></div>
			<div class="card col-md-2">
				<div class="text-end">
					<div class="btn-group card-body" style="padding: 5px">
						<button
							class="btn waves-effect waves-light btn-sm btn-outline-secondary dropdown-toggle has-ripple"
							type="button"
							data-bs-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							--Filter By Position--<span
								class="ripple ripple-animate"
								style="
									height: 89.3438px;
									width: 89.3438px;
									animation-duration: 0.7s;
									animation-timing-function: linear;
									background: rgb(255, 255, 255);
									opacity: 0.4;
									top: -28.2344px;
									left: 1.68747px;
								"
							></span>
						</button>
						<div class="dropdown-menu" style="">
							<a
								v-for="position in positions"
								@click.prevent="getResultByFilter(position)"
								class="dropdown-item"
								href="#!"
								:key="'po_' + position.id"
								>{{ position.name }}</a
							>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row mb-2">
			<template v-if="response">
				<div class="col-md-6 col-lg-4">
					<div class="card" style="height: 212px">
						<div class="card-body text-center">
							<i class="feather icon-briefcase text-c-blue d-block f-40"></i>
							<h3 class="m-t-20">
								<span class="text-c-blue">{{ response.income_formatted }}</span>
							</h3>
							<p class="m-b-20">
								<strong
									>{{ user.party }} {{ election_type }} ELECTION TOTAL
									INCOME</strong
								>
							</p>
							<router-link
								to="/forms/reporting"
								tag="button"
								class="btn btn-primary btn-sm btn-round"
								>View List</router-link
							>
						</div>
					</div>
				</div>
				<div class="col-md-6 col-lg-4">
					<div class="card" style="height: 212px">
						<div class="card-body text-center">
							<i class="feather icon-briefcase text-c-red d-block f-40"></i>
							<h4 class="m-t-20">
								<span class="text-c-red">{{
									response.expenditure_formatted
								}}</span>
							</h4>
							<p class="m-b-20">
								<strong
									>{{ user.party }} {{ election_type }} ELECTION TOTAL
									EXPENSES</strong
								>
							</p>
							<router-link
								to="/forms/expenditures"
								tag="button"
								class="btn btn-danger btn-sm btn-round"
								>View List</router-link
							>
						</div>
					</div>
				</div>
			</template>
			<div v-show="response" class="col-md-12 col-lg-4" style="height: 212px">
				<div class="card">
					<div id="container" style="height: 212px"></div>
				</div>
			</div>
		</div>
		<div v-show="user_is_party_admin" class="col-xl-12">
			<div class="card table-card">
				<div class="card-body px-0 py-0">
					<div id="income_expense_container" style="height: 500px"></div>
				</div>
			</div>
		</div>
	</div>
	<!-- [ Main Content ] end -->
</template>
<script>
import { mapGetters } from "vuex";
import { userIsParty, userIsPartyAdmin } from "@/utils/permissions";

var Highcharts = require("highcharts");
// Load module after Highcharts is loaded
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/highcharts-3d")(Highcharts);

export default {
	data() {
		return {
			election_type: "PRESIDENTIAL",
			response: null,
		};
	},
	computed: {
		...mapGetters({
			positions: "settings/elective_positions",
			user: "auth/currentUser",
		}),
		user_is_party() {
			return userIsParty();
		},
		user_is_party_admin() {
			return userIsPartyAdmin();
		},
	},
	methods: {
		plotPieChart() {
			if (!Object.keys(this.response).length) {
				return;
			}

			Highcharts.chart("container", {
				chart: {
					type: "pie",
					options3d: {
						enabled: true,
						alpha: 35,
						beta: 0,
					},
				},

				colors: this.response.colours,
				title: false,
				subtitle: {
					text: "INCOME VS EXPENSES SUMMARY",
					align: "left",
				},
				accessibility: {
					point: {
						valueSuffix: "%",
					},
				},
				tooltip: {
					pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
				},
				plotOptions: {
					pie: {
						allowPointSelect: true,
						cursor: "pointer",
						depth: 35,
						dataLabels: {
							enabled: true,
							format: "{point.name}",
						},
					},
				},

				series: [
					{
						type: "pie",
						name: "Total",
						data: [
							["INCOME", parseFloat(this.response.income_unformatted)],
							["EXPENSES", parseFloat(this.response.expenditure_unformatted)],
							// {
							// 	name: "Xiaomi",
							// 	y: 12,
							// 	sliced: true,
							// 	selected: true,
							// },
						],
					},
				],
			});
		},
		plotChart() {
			if (!Object.keys(this.response).length) {
				return;
			}

			Highcharts.chart("income_expense_container", {
				chart: {
					type: "column",
					options3d: {
						enabled: true,
						alpha: 10,
						beta: 0,
						depth: 100,
					},
				},
				title: {
					text:
						this.user.party +
						" PRESIDENTIAL ELECTION INCOME VS EXPENSES BREAKDOWN ACCORDING TO STATES.",
				},
				subtitle: {
					text: "CALCULATED USING FORM EC16D & FORM EC16E RESPECTIVELY",
				},
				xAxis: {
					categories: this.response.chart_categories,
					crosshair: true,
				},
				yAxis: {
					title: {
						useHTML: true,
						text: "TOTAL AMOUNT",
					},
				},
				tooltip: {
					headerFormat:
						"<span style='font-size:10px'>{point.key}</span><table>",
					pointFormat:
						"<tr><td style='color:{series.color};padding:0'>{series.name}: </td>" +
						"<td style='padding:0'><b>{point.y:.1f}</b></td></tr>",
					footerFormat: "</table>",
					shared: true,
					useHTML: true,
				},
				plotOptions: {
					column: {
						pointPadding: 0.2,
						borderWidth: 0,
					},
				},
				series: this.response.chart_data,
			});
		},
		getResultByFilter(position) {
			this.election_type = position.name.toUpperCase();
			this.getDashboardData({ elective_position_id: position.id });
		},
		async getDashboardData(payload) {
			await this.$store
				.dispatch("dashboard/getDashboardData", payload)
				.then((response) => {
					this.response = response;
					this.plotPieChart();

					if (this.user_is_party && this.user_state_code == "NA") {
						this.plotChart();
					}
				});
		},
		async getElectivePositions() {
			await this.$store
				.dispatch("settings/getElectivePositions")
				.then((response) => {
					this.response = response;
				});
		},
	},
	mounted() {
		this.getDashboardData({ elective_position_id: 1 });
		if (!this.positions.length) {
			this.getElectivePositions();
		}
	},
};
</script>
<style scoped>
.table td {
	padding: 0.5rem 0.75rem !important;
	text-align: left !important;
}
</style>
