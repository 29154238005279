<template>
	<Modal title="Upload to gallery">
		<form @submit.prevent="saveFiles()">
			<div class="row">
				<div class="col-sm-12">
					<div class="form-group">
						<label class="control-label" for="Description">Description</label>
						<textarea
							v-model="description"
							class="form-control"
							id="Description"
							placeholder="Enter description..."
							rows="3"
							required
						></textarea>
					</div>
				</div>
				<div v-if="user_is_staff_admin" class="col-sm-12">
					<div class="form-group">
						<label class="form-label" for="state_code">Choose State:</label>
						<select
							v-model="state_code"
							class="form-select"
							id="state_code"
							required
						>
							<option value="">--select--</option>
							<option
								v-for="state in states"
								:value="state.code"
								:key="'s' + state.id"
							>
								{{ state.name }}
							</option>
						</select>
					</div>
				</div>
				<div v-if="!user_is_party" class="col-sm-6">
					<div class="form-group">
						<label class="form-label" for="party">Choose Party:</label>
						<select v-model="party_id" class="form-select" id="party" required>
							<option value="">--select--</option>
							<option
								v-for="party in parties"
								:value="party.id"
								:key="'p' + party.id"
							>
								{{ party.abbreviation }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="form-group">
						<label class="form-label" for="election_type"
							>Choose Election Type:</label
						>
						<select
							v-model="position_id"
							class="form-select"
							id="election_type"
							required
						>
							<option value="">--select--</option>
							<option
								v-for="position in positions"
								:value="position.id"
								:key="'ep' + position.id"
							>
								{{ position.name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="form-group">
						<label class="form-label" for="state_code">Campaign Date:</label>
						<input
							v-model="date_reported"
							type="date"
							name=""
							id=""
							class="form-control"
							required
						/>
					</div>
				</div>
				<div class="col-sm-6">
					<div class="form-group">
						<label class="control-label" for="file"
							>Choose file(s) <br />
							<span class="text-danger text-sm" style="font-size: 10px"
								>(Hold the cntrl key to select multiple files)</span
							></label
						>
						<input
							type="file"
							@change="getFiles()"
							ref="files"
							id="file"
							multiple
							accept="image/*"
							required
						/>
					</div>
				</div>
				<div v-if="files.length" class="col-md-12">
					<div class="table-responsive">
						<table class="table table-bordered table-striped mb-0">
							<thead>
								<tr>
									<th>S/N</th>
									<th>File Name</th>
									<th>Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(file, index) in files" :key="index">
									<td>{{ index + 1 }}.</td>
									<td>{{ file.name }}</td>
									<td>
										<a
											@click.prevent="removeFile(index)"
											href="#!"
											class="btn btn-danger btn-sm"
											><i class="feather icon-trash-2"></i>&nbsp;Delete
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="col-sm-12" style="border-top: 1px solid #e3e3e3">
					<div align="right" style="margin-top: 15px">
						<button
							@click.prevent="toggleModal('hide')"
							class="btn btn-secondary"
							style="margin-right: 5px"
						>
							Cancel
						</button>
						<LoadingButton class="btn-info">Submit</LoadingButton>
						<!-- <button type="submit" class="btn btn-info">Submit</button> -->
					</div>
				</div>
			</div>
		</form>
	</Modal>
</template>
<script>
import { userIsStaffAdmin, userIsParty } from "@/utils/permissions";
import Modal from "@/components/Modal.vue";
import { EventBus } from "@/utils/event_bus.js";
import { mapGetters, mapActions } from "vuex";

import LoadingButton from "@/components/LoadingButton.vue";

export default {
	name: "GalleryList",
	components: {
		Modal,
		LoadingButton,
	},

	data() {
		return {
			description: "",
			state_code: "",
			party_id: "",
			position_id: "",
			date_reported: "",
			files: [],
		};
	},

	computed: {
		...mapGetters({
			states: "settings/states",
			parties: "settings/parties",
			positions: "settings/elective_positions",
			error: "shared/getErrorResponse",
		}),
		user_is_staff_admin() {
			return userIsStaffAdmin();
		},
		user_is_party() {
			return userIsParty();
		},
	},

	methods: {
		...mapActions({
			getStates: "settings/getStates",
			getParties: "settings/getParties",
			getElectivePositions: "settings/getElectivePositions",
			uploadFiles: "galleries/uploadFiles",
			getGalleries: "galleries/getGalleries",
		}),

		getFiles() {
			let uploadedFiles = this.$refs.files.files;
			for (var i = 0; i < uploadedFiles.length; i++) {
				this.files.push(uploadedFiles[i]);
			}

			this.$refs.files = "";
		},

		removeFile(index) {
			this.files.splice(index, 1);
		},

		async saveFiles() {
			let formData = new FormData();

			for (var i = 0; i < this.files.length; i++) {
				let file = this.files[i];

				formData.append("files[" + i + "]", file);
			}

			formData.append("description", this.description);
			formData.append("state_code", this.state_code);
			formData.append("party_id", this.party_id);
			formData.append("elective_position_id", this.position_id);
			formData.append("date_reported", this.date_reported);

			await this.uploadFiles(formData).then((res) => {
				if (res && res === "success") {
					this.getGalleries();
					this.clearInputs();
					this.toggleModal("hide");
				}
			});
		},

		toggleModal(type) {
			EventBus.$emit("toggle-modal", type);
		},

		clearInputs() {
			this.description = "";
			this.state_code = "";
			this.party_id = "";
			this.position_id = "";
			this.date_reported = "";
			this.files = [];
		},
	},

	mounted() {
		if (!this.positions.length) {
			this.getElectivePositions();
		}
		if (!this.states.length) {
			this.getStates();
		}
		if (!this.parties.length) {
			this.getParties();
		}

		if (!this.user_is_staff_admin) {
			this.state_code = this.user_state_code;
		}

		if (this.user_is_party) {
			this.party_id = this.user_party_id;
		}

		EventBus.$on("toggle-modal", (type) => {
			if (type === "hide" && !this.error) {
				this.clearInputs();
			}
		});
	},
};
</script>
