<template>
	<div class="row">
		<!-- customar project  start -->
		<div v-if="user_can_view_report" class="col-md-12">
			<div class="card">
				<div class="card-body">
					<!-- search form -->
					<SearchForm @toggle-input="toggleInput" @search="getReports">
						<div v-if="position_id == 1 || position_id == 2" class="col-md-2">
							<div class="form-group text-center">
								<label class="form-label" for="prepare_by">Prepare By</label>
								<select
									@change="$store.commit('reports/setIncome', [])"
									v-model="params.prepare_by"
									class="form-select"
									id="prepare_by"
									required
								>
									<option value="">--select--</option>
									<option v-if="user_can_view_zonal_data" value="zone">
										By Zones
									</option>
									<option value="state">By States</option>
									<!-- <option v-if="position_id == 3" value="sen. district">
										By Senatorial District
									</option>
									<option v-if="position_id == 4" value="fed. constituency">
										By Federal Constituencies
									</option>
									<option v-if="position_id == 5" value="state constituency">
										By State Constituencies
									</option>
									<option
										v-if="position_id == 6 || position_id == 7"
										value="lga"
									>
										By L.G.A
									</option> -->
								</select>
							</div>
						</div>
					</SearchForm>

					<div
						v-if="user_is_staff_admin && Object.keys(income).length"
						class="alert alert-warning"
					>
						<strong
							>NOTE: This page shows only the data submitted by the various
							political parties (FORM EC16D & FORM EC16E) respectively,
							and</strong
						>
						<strong>
							<template v-if="params.prepare_by == 'zone'">
								the report excludes "NATIONAL" because national does not belong
								to any zone.</template
							>
							<template v-if="params.prepare_by == 'state'">
								the report includes "NATIONAL" because national is regarded as
								an entity.</template
							>
						</strong>
					</div>

					<div v-if="Object.keys(income).length" class="table-responsive">
						<table
							id="data-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<th>S/N</th>
									<th>POLITICAL PARTY</th>
									<th>TYPE OF ELECTION</th>
									<th>{{ params.prepare_by.toUpperCase() }}</th>
									<th>TOTAL INCOME</th>
									<th>TOTAL EXPENSES</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(income, index) in income.data"
									:key="'inc_' + index"
								>
									<td>{{ index + 1 }}.</td>
									<td>{{ income.party }}</td>
									<td>{{ income.position }}</td>
									<td>{{ income.name }}</td>
									<td>{{ income.total_income }}</td>
									<td>{{ income.total_expenses }}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<th></th>
									<th></th>
									<th></th>
									<th>TOTAL</th>
									<th>{{ income.total_income }}</th>
									<th>{{ income.total_expenses }}</th>
								</tr>
							</tfoot>
						</table>
					</div>
					<div v-if="show_alert" class="alert alert-warning">
						<strong
							>Please choose atleast the type of election, prepare by and click
							on the search button!</strong
						>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="col-md-12 alert alert-danger" align="center">
			<strong>You're not allowed to view this page!</strong>
		</div>
		<!-- customar project  end -->
	</div>
</template>
<script>
import SearchForm from "@/components/SearchForm";
import { mapGetters, mapActions } from "vuex";
import Datatable from "@/utils/datatable.js";
import { userHasPermission, userIsStaffAdmin } from "@/utils/permissions";

export default {
	components: {
		SearchForm,
	},
	data() {
		return {
			position_id: null,
			show_alert: false,
			params: {
				prepare_by: "",
			},
		};
	},
	computed: {
		...mapGetters({
			income: "reports/getIncome",
		}),
		user_is_staff_admin() {
			return userIsStaffAdmin();
		},
		user_can_view_report() {
			return userHasPermission("income-expenses-report-view");
		},
		user_can_view_zonal_data() {
			return userHasPermission("staff-zonal-data-view");
		},
	},
	methods: {
		...mapActions({
			getIncomeList: "reports/getIncomeList",
		}),

		async getReports(type = null) {
			if (type) {
				if (typeof type === "object") {
					if (type.elective_position_id == "") {
						document.getElementById("elective_position_id").style.borderColor =
							"red";
						return alert("Please choose the type of election");
					}
					if (
						type.elective_position_id != 1 &&
						type.elective_position_id != 2 &&
						type.state_code == ""
					) {
						document.getElementById("state_code").style.borderColor = "red";
						return alert("Please choose a state");
					}

					if (this.user_can_view_report && type.party_id == "") {
						document.getElementById("party_id").style.borderColor = "red";
						return alert("Please choose a party");
					}

					this.params.q = JSON.stringify(type); // Object containing the searchable fields
					this.params.page = null; // Reset page no
				}

				if (typeof type === "string") {
					this.params.page =
						type === "next"
							? parseInt(this.income.paginator.current_page) + 1
							: parseInt(this.income.paginator.current_page) - 1;
				}
			}

			// this.setPrepareBy(type.elective_position_id);
			this.reset();

			await this.getIncomeList(this.params).then(() =>
				Datatable("#data-table")
			);
		},

		setPrepareBy() {
			this.$store.commit("reports/setIncome", []);

			if (this.position_id == 3) {
				return (this.params.prepare_by = "sen. district");
			}
			if (this.position_id == 4) {
				return (this.params.prepare_by = "fed. constituency");
			}
			if (this.position_id == 5) {
				return (this.params.prepare_by = "state constituency");
			}
			if (this.position_id == 6 || this.position_id == 7) {
				return (this.params.prepare_by = "lga");
			}
		},

		reset() {
			let color = "#ced4da";
			let party_id = document.getElementById("party_id");
			let elective_position_id = document.getElementById(
				"elective_position_id"
			);
			let state_code = document.getElementById("state_code");

			this.show_alert = false;
			this.$store.commit("reports/setIncome", []);

			if (party_id) {
				party_id.style.borderColor = color;
			}

			if (elective_position_id) {
				elective_position_id.style.borderColor = color;
			}

			if (state_code) {
				state_code.style.borderColor = color;
			}
		},

		toggleInput(input) {
			this.position_id = input.value;
			this.setPrepareBy();
		},
	},
	mounted() {
		this.reset();
		this.show_alert = true;
	},
};
</script>
