<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table
							id="report-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Name</th>
									<th>Advert Rate</th>
									<th>Type</th>
									<!-- <th>Actions</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="channel in channels" :key="'c' + channel.id">
									<td>{{ channel.id }}.</td>
									<td>{{ channel.name }}</td>
									<td>{{ channel.rate }}</td>
									<td>
										<span class="badge bg-primary">{{ channel.type }}</span>
									</td>
									<!-- <td>
										<a href="#!" class="btn btn-info btn-sm"
											><i class="feather icon-edit"></i>&nbsp;Edit
										</a>
										<a href="#!" class="btn btn-danger btn-sm"
											><i class="feather icon-trash-2"></i>&nbsp;Delete
										</a>
									</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- customar project  end -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters({
			channels: "settings/channels",
		}),
	},

	mounted() {
		if (!this.channels.length) {
			this.$store.dispatch("settings/getMediaChannels");
		}
	},
};
</script>
