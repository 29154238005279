<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-md-8">
			<div class="card">
				<!-- <div v-if="can_create_document" class="card-header" align="right">
					<button
						@click.prevent="toggleForm()"
						class="btn btn-info float-right"
						style="border-radius: 6px"
					>
						Add New
					</button>
				</div> -->
				<div class="alert alert-warning">
					This module allows system administrators to define the specific
					supporting documents that political parties are required to submit
					with their reports. This information helps auditors identify the
					appropriate documents to request during the auditing process.
				</div>
				<div class="card-body">
					<div class="table-responsive">
						<table
							id="report-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Title</th>
									<th>Status</th>
									<th>Created By</th>
									<th v-if="can_update_document">Actions</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="document in documents" :key="'sm_' + document.id">
									<td>{{ document.id }}.</td>
									<td>{{ document.title }}</td>
									<td>
										<span
											:class="
												'badge bg-' +
												getStatusColor()[document.status.toLowerCase()]
											"
										>
											{{ document.status }}
										</span>
									</td>
									<td>{{ document.user ? document.user.name : null }}</td>
									<td v-if="can_update_document">
										<a
											@click.prevent="editForm(document)"
											href="#!"
											class="btn btn-info btn-sm"
											><i class="feather icon-edit"></i>&nbsp;Edit
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="show_form && (can_create_document || can_update_document)"
			class="col-md-4"
		>
			<form @submit.prevent="submitForm()" action="" method="post">
				<div class="card">
					<div class="card-header">
						<h5>{{ form_title }} Settings</h5>
					</div>
					<div class="card-body">
						<div class="form-group">
							<label class="form-label" for="title"
								>Title <span class="text-danger">*</span></label
							>
							<input
								v-model="form.title"
								type="text"
								name="title"
								id="title"
								class="form-control"
								placeholder="Eg. Bank Statement"
								required
							/>
						</div>
						<div class="form-group">
							<label class="form-label" for="status"
								>Status <span class="text-danger">*</span></label
							>
							<select v-model="form.status" class="form-select" id="status">
								<option value="">--select--</option>
								<option value="enabled">Enable</option>
								<option value="disabled">Disable</option>
							</select>
						</div>
					</div>
					<div
						v-if="can_create_document || can_update_document"
						class="card-footer"
					>
						<button
							@click.prevent="resetForm()"
							type="reset"
							class="btn"
							style="border-radius: 6px; background: #e3e3e3"
						>
							Cancel
						</button>
						<LoadingButton
							type="submit"
							class="btn btn-info"
							style="border-radius: 6px"
							>Submit</LoadingButton
						>
					</div>
				</div>
			</form>
		</div>
		<!-- customar project  end -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import LoadingButton from "@/components/LoadingButton.vue";
import { userHasPermission } from "@/utils/permissions";

export default {
	components: {
		LoadingButton,
	},
	data() {
		return {
			form_title: "New",
			show_form: true,
			form: {
				title: null,
				status: "enabled",
				id: null,
			},
		};
	},
	computed: {
		...mapGetters({
			documents: "settings/supporting_documents",
		}),
		can_create_document() {
			return userHasPermission("supporting-document-create");
		},
		can_update_document() {
			return userHasPermission("supporting-document-update");
		},
	},
	methods: {
		submitForm() {
			if (this.form_title === "New") {
				this.$store
					.dispatch("settings/createSupportingDocuments", this.form)
					.then((response) => {
						if (response === "success") {
							this.resetForm();
						}
					});
			} else {
				this.$store
					.dispatch("settings/updateSupportingDocuments", this.form)
					.then((response) => {
						if (response === "success") {
							this.resetForm();
						}
					});
			}

			this.form_title === "New";
		},
		getStatusColor() {
			return {
				enabled: "success",
				disabled: "danger",
			};
		},
		editForm(document) {
			this.form_title = "Edit";
			this.form.id = document.id;
			this.form.title = document.title;
			this.form.status = document.status.toLowerCase();

			this.show_form = true;
		},
		toggleForm() {
			this.form_title = "New";
			this.form.status = "enabled";
			this.resetForm(true);

			this.show_form = !this.show_form;
		},
		resetForm(toggle_form = false) {
			this.form.id = null;
			this.form.title = null;
			this.form.status = "enabled";

			if (!toggle_form) {
				this.form_title = "New";
				this.show_form = true;
			}
		},
	},
	mounted() {
		this.$store.dispatch("settings/getSupportingDocuments");
	},
};
</script>
<style scoped>
.form-control {
	padding: 10px;
}
</style>
