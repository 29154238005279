/* eslint-disable */

import Vue from "vue";
import router from "./router";
import store from "./store";
import App from "./App.vue";

// plugin setup
require("./Request");

Vue.config.productionTip = false;
// Vue.prototype.user_role = store.state.auth.currentUser ? store.state.auth.currentUser.role.toLowerCase() : null;
Vue.prototype.user_party_id = store.state.auth.currentUser ? store.state.auth.currentUser.party_id : null;
Vue.prototype.user_state_code = store.state.auth.currentUser ? store.state.auth.currentUser.state_code : null;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
