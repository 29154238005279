<template>
	<div class="row">
		<!-- customar project  start -->
		<div :class="show_form ? 'col-md-8' : 'col-md-12'">
			<div class="card">
				<div v-if="can_create_submission" class="card-header" align="right">
					<button
						@click.prevent="show_form = true"
						class="btn btn-info float-right"
						style="border-radius: 6px"
					>
						Add New
					</button>
				</div>
				<div class="card-body">
					<div class="alert alert-warning">
						This module enables administrators to configure the PFRAS portal
						with regard to report submission. This includes setting the opening
						and closing dates for report submissions, as well as the ability to
						pause or resume acceptance of reports.
					</div>
					<div class="table-responsive">
						<table
							id="report-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Title</th>
									<th>Start Date</th>
									<th>End Date</th>
									<th>Status</th>
									<th>Created By</th>
									<th v-if="can_update_submission">Actions</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="submission in submissions"
									:key="'sm_' + submission.id"
								>
									<td>{{ submission.id }}.</td>
									<td>{{ submission.title }}</td>
									<td>{{ submission.start_date }}</td>
									<td>{{ submission.end_date }}</td>
									<td>
										<span
											:class="
												'badge bg-' +
												getStatusColor()[submission.status.toLowerCase()]
											"
										>
											{{ submission.status }}
										</span>
									</td>
									<td>{{ submission.user ? submission.user.name : null }}</td>
									<td v-if="can_update_submission">
										<a
											@click.prevent="editForm(submission)"
											href="#!"
											class="btn btn-info btn-sm"
											><i class="feather icon-edit"></i>&nbsp;Edit
										</a>
										<!-- <a href="#!" class="btn btn-danger btn-sm"
											><i class="feather icon-trash-2"></i>&nbsp;Delete
										</a> -->
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<div v-if="show_form" class="col-md-4">
			<form @submit.prevent="submitForm()" action="" method="post">
				<div class="card">
					<div class="card-header">
						<h5>{{ form_title }} Settings</h5>
					</div>
					<div class="card-body">
						<div class="form-group">
							<label class="form-label" for="title"
								>Title <span class="text-danger">*</span></label
							>
							<input
								v-model="form.title"
								type="text"
								name="title"
								id="title"
								class="form-control"
								placeholder="Eg, 2023 General Elections"
								required
							/>
						</div>
						<div class="row">
							<div class="col-md-6 form-group">
								<label class="form-label" for="start_date"
									>Start Date <span class="text-danger">*</span></label
								>
								<input
									v-model="form.start_date"
									type="date"
									name="start_date"
									id="start_date"
									class="form-control"
									required
								/>
							</div>
							<div class="col-md-6 form-group">
								<label class="form-label" for="end_date"
									>End Date <span class="text-danger">*</span></label
								>
								<input
									v-model="form.end_date"
									type="date"
									name="end_date"
									id="end_date"
									class="form-control"
									required
								/>
							</div>
						</div>
						<div class="form-group">
							<label class="form-label" for="status"
								>Status <span class="text-danger">*</span></label
							>
							<select v-model="form.status" class="form-select" id="status">
								<option value="">--select--</option>
								<option value="pending">Pending</option>
								<option value="opened">Open Submission</option>
								<option value="paused">Pause Submission</option>
								<option value="closed">Close Submission</option>
							</select>
						</div>
						<!-- <div class="form-group">
							<div class="form-check">
								<input
									v-model="form.is_default"
									class="form-check-input"
									type="checkbox"
									value="yes"
									id="is_default"
								/>
								<label class="form-check-label" for="is_default"
									>Make this the default report submission start and end date.
								</label>
							</div>
						</div> -->
					</div>
					<div class="card-footer">
						<button
							@click.prevent="resetForm()"
							type="reset"
							class="btn"
							style="border-radius: 6px; background: #e3e3e3"
						>
							Cancel
						</button>
						<LoadingButton
							type="submit"
							class="btn btn-info"
							style="border-radius: 6px"
							>Submit</LoadingButton
						>
					</div>
				</div>
			</form>
		</div>
		<!-- customar project  end -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import LoadingButton from "@/components/LoadingButton.vue";
import { userHasPermission } from "@/utils/permissions";

export default {
	components: {
		LoadingButton,
	},
	data() {
		return {
			form_title: "New",
			show_form: false,
			form: {
				title: null,
				start_date: null,
				end_date: null,
				status: "pending",
				// is_default: 0,
				id: null,
			},
		};
	},
	computed: {
		...mapGetters({
			submissions: "settings/report_submission",
		}),
		can_create_submission() {
			return userHasPermission("report-submission-create");
		},
		can_update_submission() {
			return userHasPermission("report-submission-update");
		},
	},
	methods: {
		submitForm() {
			if (this.form.status == "opened") {
				if (
					!confirm(
						"You're about to make this start & end date the default for opening and closing of report submission!"
					)
				) {
					return;
				}
			}

			if (this.form_title === "New") {
				this.$store
					.dispatch("settings/createReportSubmission", this.form)
					.then((response) => {
						if (response === "success") {
							this.resetForm();
						}
					});
			} else {
				this.$store
					.dispatch("settings/updateReportSubmission", this.form)
					.then((response) => {
						if (response === "success") {
							this.resetForm();
						}
					});
			}

			this.form_title === "New";
		},
		getStatusColor() {
			return {
				opened: "success",
				pending: "primary",
				paused: "warning",
				closed: "danger",
			};
		},
		editForm(submission) {
			this.form_title = "Edit";
			this.form.id = submission.id;
			this.form.title = submission.title;
			this.form.start_date = submission.date_start;
			this.form.end_date = submission.date_end;
			this.form.status = submission.status.toLowerCase();
			// this.form.is_default = submission.default == "Default" ? true : false;

			this.show_form = true;
		},
		resetForm() {
			this.form.id = null;
			this.form.title = null;
			this.form.start_date = null;
			this.form.end_date = null;
			this.form.status = "pending";
			// this.form.is_default = 0;

			this.show_form = false;
		},
	},
	mounted() {
		// if (!this.submissions.length) {
		this.$store.dispatch("settings/getReportSubmission");
		// }
	},
};
</script>
<style scoped>
.form-control {
	padding: 10px;
}
</style>
