import router from "@/router";
import store from "@/store";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

export const login = (state, payload) => {
  state.currentUser = payload.user;
  state.permissions = payload.permissions;
  state.token = payload.token;

  setAuthenticated(state, true);
  store.commit("navigation/setNavigationLinks");
};

export const setAuthenticated = (state, type) => {
  state.authenticated = type;
};

export const setDefaultPassword = (state, payload) => {
  state.currentUser.is_default_password = payload.is_default_password;
  state.token = payload.token;
};

export const logout = (state) => {
  state.currentUser = null;
  state.token = null;
  state.permissions = [];
  ls.clear();
  ls.removeAll();

  setAuthenticated(state, false);
  clearStore();

  router.push("/login");

  location.reload();
};

const clearStore = () => {
  store.commit("settings/setRoles", []);
  store.commit("settings/setUsers", []);
  store.commit("navigation/clearNavigationLinks");
  store.commit("settings/setReportSubmission", []);
  store.commit("settings/setSupportingDocuments", []);
}
