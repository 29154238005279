<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<!-- search form -->
					<SearchForm @search="listExpenditure" />

					<div class="table-responsive">
						<table
							id="data-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Political Party</th>
									<th>Type of Election</th>
									<th>State</th>
									<th style="display: none">L.G.A</th>
									<!-- <th>Constituency</th> -->
									<th>Total Expenditure</th>
									<th>Campaign Date</th>
									<th>
										Supporting <br />
										Documents
									</th>
									<th>Submitted By</th>
									<th class="text-center">Actions</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(form, index) in forms.expenditures"
									:key="'ex' + index"
								>
									<td>{{ index + forms.paginator.from }}.</td>
									<td>
										<span class="badge bg-primary">{{ form.party }}</span>
									</td>
									<td>{{ form.position }}</td>
									<td>{{ form.state }}</td>
									<td style="display: none">{{ form.lga }}</td>
									<!-- <td>
										{{ form.constituency }} <br />
										{{ form.constituency_code }}
									</td> -->
									<td>
										<span class="badge bg-danger">{{ form.total_cost }}</span>
									</td>
									<td>
										<span class="badge bg-warning">{{
											form.date_reported
										}}</span>
									</td>
									<td>
										<template v-if="form.documents">
											<div v-for="doc in form.documents" :key="'doc_' + doc.id">
												<span class="badge bg-info">
													{{ doc.title }}
												</span>
											</div>
										</template>
									</td>
									<td class="text-center">{{ form.user }}</td>
									<td class="text-center">
										<div div class="btn-group" role="group">
											<router-link
												:to="{
													path: `/forms/${form.batch_id}/expenditures`,
												}"
												target="_blank"
												class="btn btn-info btn-sm"
												><i class="feather icon-eye"></i>&nbsp;View</router-link
											>
											<router-link
												v-if="form.allow_edit && form.is_owner"
												:to="{
													path: `/forms/${form.batch_id}/expenditures?edt=tr${form.batch_id}q`,
												}"
												class="btn btn-warning btn-sm"
												><i class="feather icon-edit"></i
												>&nbsp;Edit</router-link
											>
											<a
												v-if="form.allow_edit && form.is_owner"
												@click.prevent="setForm(form.batch_id, index)"
												href=""
												class="btn btn-danger btn-sm"
												><i class="feather icon-trash"></i>&nbsp;Delete</a
											>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- customar project  end -->

		<Paginator
			v-if="Object.keys(forms).length && forms.paginator.has_pages"
			:paginator="forms.paginator"
			@goto-page="listExpenditure"
		/>

		<!-- Delete modal -->
		<Modal title="Delete Report">
			<form @submit.prevent="deleteForm()">
				<div class="card-body">
					<h5 style="font-size: 1.5rem">
						Are you sure you want to delete this report?
					</h5>
				</div>
				<div class="card-footer" align="center">
					<button
						@click.prevent="toggleModal('hide')"
						class="btn btn-secondary"
						style="margin-right: 5px"
					>
						Cancel
					</button>
					<LoadingButton class="btn-danger"
						><i class="feather icon-trash"></i>&nbsp;Delete</LoadingButton
					>
				</div>
			</form>
		</Modal>
	</div>
</template>
<script>
import Paginator from "@/components/Paginator";
import SearchForm from "@/components/SearchForm";
import Modal from "@/components/Modal.vue";
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/utils/event_bus.js";
import Datatable from "@/utils/datatable.js";
import LoadingButton from "@/components/LoadingButton.vue";

export default {
	name: "ExpenditureList",
	components: {
		Paginator,
		SearchForm,
		LoadingButton,
		Modal,
	},
	data() {
		return {
			params: {},
			batch_id: null,
			form_index: null,
		};
	},
	computed: {
		...mapGetters({
			forms: "form_ec16e/listForms",
		}),
	},
	methods: {
		...mapActions({
			getFormList: "form_ec16e/getFormList",
			deleteFormById: "form_ec16e/deleteFormById",
		}),

		async listExpenditure(type = null) {
			if (type) {
				if (typeof type === "object") {
					this.params.q = JSON.stringify(type); // Object containing the searchable fields
					this.params.page = null; // Reset page no
				}

				if (typeof type === "string") {
					this.params.page =
						type === "next"
							? parseInt(this.forms.paginator.current_page) + 1
							: parseInt(this.forms.paginator.current_page) - 1;
				}
			}

			await this.getFormList(this.params).then(() => Datatable("#data-table"));
		},

		setForm(batch_id, index) {
			this.form_index = index;
			this.batch_id = batch_id;

			this.toggleModal("show");
		},

		toggleModal(type) {
			if (type === "hide") {
				this.batch_id = null;
				this.form_index = null;
			}

			EventBus.$emit("toggle-modal", type);
		},

		async deleteForm() {
			let payload = {
				batch_id: this.batch_id,
				form_index: this.form_index,
			};

			await this.deleteFormById(payload).then((response) => {
				if (response === "success") {
					this.toggleModal("hide");
				}
			});
		},
	},

	mounted() {
		// if (Object.keys(this.forms).length <= 0) {
		// }
		this.listExpenditure();
	},
};
</script>
