<template>
	<!-- [ Header ] start -->
	<header
		class="navbar pcoded-header navbar-expand-lg navbar-light header-blue"
	>
		<div class="m-header">
			<a class="mobile-menu" id="mobile-collapse" href="#!"><span></span></a>
			<a href="#!" class="b-brand">
				<!-- ========   change your logo hear   ============ -->
				<!-- <img src="assets/images/logo.png" alt="" class="logo" /> -->
			</a>
			<a href="#!" class="mob-toggler">
				<i class="feather icon-more-vertical"></i>
			</a>
		</div>
		<div class="collapse navbar-collapse">
			<ul class="navbar-nav me-auto">
				<li class="nav-item">
					<a href="#!" class="pop-search"
						><i class="feather icon-search"></i
					></a>
					<div class="search-bar">
						<input
							type="text"
							class="form-control border-0 shadow-none"
							placeholder="Search hear"
						/>
						<button
							type="button"
							class="close close btn-close position-absolute top-50 end-0 translate-middle"
							aria-label="Close"
						></button>
					</div>
				</li>
			</ul>
			<ul class="navbar-nav ms-auto">
				<!-- <li>
					<div class="dropdown">
						<a class="dropdown-toggle" href="#" data-bs-toggle="dropdown"
							><i class="icon feather icon-bell"></i
						></a>
					</div>
				</li> -->
				<li>
					<div class="dropdown drp-user">
						<a href="#" class="dropdown-toggle" data-bs-toggle="dropdown">
							<i class="feather icon-user"></i>
						</a>
						<div class="dropdown-menu dropdown-menu-end profile-notification">
							<div class="pro-head">
								<img
									src="@/assets/images/logo.png"
									class="img-radius"
									alt="User-Profile-Image"
								/>
								<span>{{ user.name }}</span>
								<a
									@click.prevent="showModal()"
									href="#"
									class="dud-logout"
									title="Logout"
								>
									<i class="feather icon-log-out"></i>
								</a>
							</div>
							<ul class="pro-body">
								<li v-if="can_view_profile">
									<router-link :to="'/profile'" class="dropdown-item">
										<i class="feather icon-user"></i> Profile
									</router-link>
								</li>
								<li>
									<a @click.prevent="showModal()" href="#" class="dropdown-item"
										><i class="feather icon-lock"></i> Log Out</a
									>
								</li>
							</ul>
						</div>
					</div>
				</li>
			</ul>
		</div>

		<ModalLogout />
	</header>
	<!-- [ Header ] end -->
</template>
<script>
import { mapGetters } from "vuex";
import ModalLogout from "@/components/ModalLogout.vue";
import { EventBus } from "@/utils/event_bus.js";
import { userHasPermission } from "@/utils/permissions";

export default {
	components: {
		ModalLogout,
	},
	computed: {
		...mapGetters({
			user: "auth/currentUser",
		}),
		can_view_profile() {
			return userHasPermission("profile-view");
		},
	},
	methods: {
		showModal() {
			EventBus.$emit("toggle-logout-modal", "show");
		},
	},
};
</script>
