import { expensesList } from "@/api/analytics";
import store from "@/store";

// Get expenses list
export const getExpensesList= (commit, payload) => {
  return new Promise((resolve) => {
    expensesList(payload).then((response) => {
      if (response.status_code === 200) {
        store.commit("analytics/setExpenses", response.data);
      }
      resolve();
    });
  });
};
