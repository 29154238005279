<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<!-- search form -->
					<SearchBar @plot-chart="plotHightCharts" />
					<hr style="height: 2px; color: unset" />

					<div v-if="Object.keys(response).length">
						<OtherChartTable
							v-if="response.chart_type != 'pie'"
							:response="response"
						/>

						<PieChartTable
							v-if="response.chart_type == 'pie'"
							:response="response"
						/>

						<figure class="highcharts-figure mb-5">
							<div id="container"></div>
						</figure>

						<hr style="height: 2px; color: unset" />

						<AnalyticsTable :response="response" />
					</div>
				</div>
			</div>
		</div>
		<!-- customar project  end -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import SearchBar from "@/views/Analytics/Partials/SearchBar.vue";
import PieChartTable from "@/views/Analytics/Partials/PieChartTable.vue";
import OtherChartTable from "@/views/Analytics/Partials/OtherChartTable.vue";
import AnalyticsTable from "@/views/Analytics/Partials/AnalyticsTable.vue";
var Highcharts = require("highcharts");
// Load module after Highcharts is loaded
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/modules/accessibility")(Highcharts);
// require("highcharts/modules/highcharts-3d")(Highcharts);
require("highcharts/modules/data")(Highcharts);

export default {
	components: {
		SearchBar,
		PieChartTable,
		OtherChartTable,
		AnalyticsTable,
	},

	computed: {
		...mapGetters({
			response: "analytics/getExpenses",
		}),
	},

	methods: {
		plotHightCharts() {
			Highcharts.chart("container", {
				data: {
					table: "simple-data-table",
				},
				chart: {
					type: this.response.chart_type,
				},
				title: {
					text: "EXPENSES BREAKDOWN FOR THE SELECTED QUERY ABOVE",
				},
				yAxis: {
					allowDecimals: false,
					title: {
						text: "values",
					},
				},
				tooltip: {
					formatter: function () {
						return (
							"<b>" +
							this.series.name +
							"</b><br/>" +
							this.point.y +
							" " +
							this.point.name.toLowerCase()
						);
					},
				},
				// colors: ["#4680ff", "#ff0000", "#9ccc65"],

				series: this.getSeries(),
			});
		},

		getSeries() {
			var list = [];
			let response = Object.keys(this.response.expenses);
			let series = {
				dataLabels: {
					enabled: true,
				},
			};

			if (response.length) {
				for (var i = 0; i <= response.length - 1; i++) {
					list.push(series);
				}
			}

			return list;
		},
	},

	mounted() {
		if (Object.keys(this.response).length) {
			this.$store.commit("analytics/setExpenses", []);
		}
	},
};
</script>
<style scoped>
.btn-success:hover {
	background: green !important;
	color: #fff;
}
.btn-success {
	background: green !important;
	border-color: green !important;
	color: #fff;
}
</style>
