<template>
	<!-- [ Main Content ] start -->
	<div>
		<StaffDashboard v-if="user_can_view_staff_dashboard" />

		<PartyDashboard v-if="user_can_view_party_dashboard" />
	</div>
	<!-- [ Main Content ] end -->
</template>
<script>
import StaffDashboard from "./partials/StaffDashboard.vue";
import PartyDashboard from "./partials/PartyDashboard.vue";
import { userHasPermission } from "@/utils/permissions";

export default {
	components: {
		StaffDashboard,
		PartyDashboard,
	},
	computed: {
		user_can_view_staff_dashboard() {
			return userHasPermission("staff-dashboard-view");
		},
		user_can_view_party_dashboard() {
			return userHasPermission("party-dashboard-view");
		},
	},
};
</script>
