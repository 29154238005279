export const setValidationErrors = (state, error) => {
  state.validations = error;
};
export const setIsLoading = (state, status) => {
  state.isLoading = status;
};

export const setPermissionError = (state) => {
  state.permissionError = "Your Email or Password incorrect";
};

export const updateUser = (state, data) => {
  state.profile = data ? data : {};
};
