<template>
	<div>
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col-md-10">
						<h3>Image Splash</h3>
					</div>
					<div v-if="can_create_image" class="col-md-2">
						<button
							@click.prevent="toggleModal('show')"
							class="btn btn-warning"
						>
							Upload new file
						</button>
					</div>
				</div>
			</div>
			<div class="card-body">
				<!-- <p>Showing various campaign images uploaded on the platform.</p> -->

				<!-- search form -->
				<SearchForm @search="listGalleries" />

				<hr />

				<div class="row">
					<div
						v-for="gallery in galleries.data"
						class="col-md-4"
						:key="'g' + gallery.id"
					>
						<a
							target="_blank"
							:href="gallery.file"
							data-lightbox="roadtrip"
							:data-title="gallery.details.description"
						>
							<div class="card user-card user-card-1">
								<div class="card-header border-0 p-2 pb-0">
									<div class="cover-img-block">
										<img
											:src="gallery.file"
											alt=""
											style="width: 100%; height: 185px"
										/>
									</div>
								</div>
								<div class="card-body pt-0">
									<p class="mb-1">{{ gallery.details.description }}</p>
									<hr />
									<span class="badge bg-secondary mr-1">{{
										gallery.details.state.name
									}}</span>
									<span class="badge bg-secondary mr-1">{{
										gallery.details.party.abbreviation
									}}</span>
									<span class="badge bg-secondary mr-1">{{
										gallery.details.position.name
									}}</span>
									<span class="badge bg-secondary">{{
										gallery.details.date_reported
									}}</span>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<template
			v-if="Object.keys(galleries).length && galleries.paginator.has_pages"
		>
			<Paginator :paginator="galleries.paginator" @goto-page="listGalleries" />
		</template>

		<GalleryForm />
	</div>
</template>
<script>
import GalleryForm from "@/views/Gallery/GalleryForm.vue";
import SearchForm from "@/components/SearchForm";
import Paginator from "@/components/Paginator.vue";
import { EventBus } from "@/utils/event_bus.js";
import { mapGetters, mapActions } from "vuex";
import { userHasPermission } from "@/utils/permissions";

export default {
	name: "GalleryList",
	components: {
		GalleryForm,
		Paginator,
		SearchForm,
	},
	data() {
		return {
			params: {},
		};
	},

	computed: {
		...mapGetters({
			galleries: "galleries/listGalleries",
		}),
		can_create_image() {
			return userHasPermission("gallery-create");
		},
	},

	methods: {
		...mapActions({
			getGalleries: "galleries/getGalleries",
		}),

		toggleModal(type) {
			EventBus.$emit("toggle-modal", type);
		},

		async listGalleries(type = null) {
			if (type) {
				if (typeof type === "object") {
					this.params.q = JSON.stringify(type); // Object containing the searchable fields
					this.params.page = null; // Reset page no
				}

				if (typeof type === "string") {
					this.params.page =
						type === "next"
							? parseInt(this.galleries.paginator.current_page) + 1
							: parseInt(this.galleries.paginator.current_page) - 1;
				}
			}

			await this.getGalleries(this.params);
		},
	},

	mounted() {
		// if (Object.keys(this.galleries).length <= 0) {
		this.listGalleries();
		// }
	},
};
</script>
<style scoped>
.mr-1 {
	margin-right: 2px;
}
</style>
