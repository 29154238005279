<template>
	<div>
		<div v-if="user_is_party">
			<div v-if="portal_status" class="row">
				<div class="col-md-5 mx-auto">
					<div
						class="alert alert-danger"
						style="padding: 0.5rem 0.7rem; margin-bottom: 0.5rem"
					>
						<strong style="font-weight: 700"
							>{{ report_submission.time_frame }}
						</strong>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-5 mx-auto">
					<div class="card" style="border-color: green">
						<h5
							v-if="portal_status"
							class="text-center mt-4"
							style="color: black"
						>
							{{ report_submission.msg }}
						</h5>
						<h4 class="text-center mt-2" style="color: rgb(234, 11, 11)">
							( INCOME FORM )
						</h4>
						<div class="card-body">
							<div v-if="portal_status">
								<div
									@click="$router.push('/forms/add/reporting').catch(() => {})"
									class="card"
									style="cursor: pointer"
								>
									<div class="card-body">
										<div style="display: flex">
											<div>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="76"
													height="96"
													viewBox="0 0 24 24"
												>
													<path
														d="M17.812 23c-7.812 0-7.812-8-7.812-8h-3l4.979-4 5.021 4h-3s-.812 6.094 3.812 8zm1.667-14.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.302c-.275-.717-.46-1.404-.578-2h-2.724c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-2.518c.062.62.21 1.329.499 2h2.019c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z"
														fill="green"
													/>
												</svg>
											</div>
											<div style="margin-left: 10px">
												<h5 class="font-weight:700">
													Manually Submit Income Report
												</h5>
												<p>
													Please click here to enter your income report manually
													in accordance with the INEC guidelines outlined in
													FORM EC16D (Income Form).
												</p>
											</div>
										</div>
									</div>
								</div>
								<div
									@click.prevent="toggleModal('show')"
									class="card"
									style="cursor: pointer"
								>
									<div class="card-body">
										<div style="display: flex">
											<div>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="76"
													height="96"
													viewBox="0 0 24 24"
												>
													<path
														d="M6 12h10v1h-10v-1zm7.816-3h-7.816v1h9.047c-.45-.283-.863-.618-1.231-1zm5.184 1.975v2.569c0 4.106-6 2.456-6 2.456s1.518 6-2.638 6h-7.362v-20h9.5c.312-.749.763-1.424 1.316-2h-12.816v24h10.189c3.163 0 9.811-7.223 9.811-9.614v-3.886c-.623.26-1.297.421-2 .475zm-13-3.975h6.5c-.134-.32-.237-.656-.319-1h-6.181v1zm17-2.5c0 2.485-2.017 4.5-4.5 4.5s-4.5-2.015-4.5-4.5 2.017-4.5 4.5-4.5 4.5 2.015 4.5 4.5zm-2-.5h-2v-2h-1v2h-2v1h2v2h1v-2h2v-1z"
														fill="green"
													/>
												</svg>
											</div>
											<div style="margin-left: 10px">
												<h5 class="font-weight:700">
													Upload Bulk Income Report
												</h5>
												<p>
													Please click here to upload the Excel file with the
													income report according to the details in the INEC
													FORM EC16D. You can also download the sample sheet by
													clicking the button below.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else>
								<div class="alert alert-danger">
									<strong class="text-center">{{
										report_submission.msg
									}}</strong>
								</div>
								<h2 class="text-center">OR</h2>
							</div>
							<h5 class="text-center">
								<u>
									<a :href="getUrl()" style="color: black"
										>Click here to download sample income excel sheet</a
									>
								</u>
							</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="alert alert-danger">
			<strong class="text-center">You're not allowed to view this page!</strong>
		</div>

		<ReportingUploadModal />
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/utils/event_bus.js";
import { userIsParty } from "@/utils/permissions";
import ReportingUploadModal from "@/views/Forms/Reporting/Partials/ReportingUploadModal.vue";

export default {
	name: "ReportingIndex",

	components: {
		ReportingUploadModal,
	},

	data() {
		return {
			portal_status: false,
		};
	},
	computed: {
		...mapGetters({
			report_submission: "settings/report_submission_status",
		}),
		user_is_party() {
			return userIsParty();
		},
	},
	methods: {
		...mapActions({
			statusReportSubmission: "settings/statusReportSubmission",
		}),

		getPortalStatus() {
			this.statusReportSubmission().then((response) => {
				this.portal_status = response.status;
			});
		},

		toggleModal(type) {
			EventBus.$emit("toggle-modal", type);
		},

		getUrl() {
			const url =
				process.env.VUE_APP_MODE === "local"
					? process.env.VUE_APP_LOCAL_URL
					: process.env.VUE_APP_PRODUCTION_URL;

			return url.replace("/api", "/download/income-sample");
		},
	},
	mounted() {
		if (this.user_is_party) {
			this.getPortalStatus();
		}
	},
};
</script>
<style scoped>
h5 {
	color: green !important;
}
.modal {
	background-color: rgb(14 11 11 / 74%) !important;
}
</style>
