<template>
	<div class="auth-wrapper" style="background: #e9f2ec">
		<div class="auth-content">
			<div class="card">
				<div class="row">
					<div class="col-md-12">
						<div
							class="card-body"
							style="padding-top: 0px; padding-bottom: 0px"
						>
							<div class="align-items-center text-center">
								<img
									src="assets/images/inec.jpeg"
									alt=""
									class="img-fluid mb-4"
								/>
							</div>
							<Alert></Alert>
							<zoom-center-transition :duration="200" mode="out-in">
								<!-- your content here -->
								<router-view></router-view>
							</zoom-center-transition>
						</div>
					</div>
				</div>
			</div>
			<div class="align-items-center text-center mt-2">
				<img
					src="assets/images/eulong.jpg"
					alt=""
					class="img-fluid mb-4"
					style=""
				/>
			</div>
		</div>
	</div>
</template>
<script>
import { ZoomCenterTransition } from "vue2-transitions";
import Alert from "@/components/Alert.vue";
export default {
	components: {
		Alert,
		ZoomCenterTransition,
	},
};
</script>
<style>
.btn-info {
	background: #29623c;
	border-color: #29623c;
}
.btn-info:hover {
	background-color: #268e49;
	border-color: #268e49;
}
body {
	background: #29623c;
}
.img-fluid {
	height: 100px;
}
</style>
