/* eslint-disable */
import "jquery/dist/jquery.min.js";
import $ from "jquery";
// import DataTable from "datatables.net-dt";

const Datatable = (table_id) => {
    
    $.noConflict();

    if ( DataTable.isDataTable( table_id ) ) {
        return;
    }

    new DataTable(table_id, {
        dom: "Bfrtip",
        // fixedHeader: true,
        select: true,
        paging: false,
        info: false,
        ordering: false,
        // lengthMenu: [
        //     [15, 30, -1 ],
        //     [ '15 rows', '30 rows', 'Show all' ]
        // ],
        buttons: [
            // 'pageLength',
            {
                extend: "colvis",
                text: "Columns",
            },
            {
                extend: "collection",
                text: "Export",
                className: "custom-html-collection",
                buttons: [
                    {
                        extend: "copy",
                        exportOptions: {
                            columns: [":visible:not(.not-export-col)"],
                        },
                    },
                    {
                        extend: "pdf",
                        exportOptions: {
                            columns: [":visible:not(.not-export-col)"],
                        },
                    },
                    {
                        extend: "print",
                        exportOptions: {
                            columns: [":visible:not(.not-export-col)"],
                        },
                    },
                    {
                        extend: "excel",
                        exportOptions: {
                            columns: [":visible:not(.not-export-col)"],
                        },
                    },
                    {
                        extend: "csv",
                        exportOptions: {
                            columns: [":visible:not(.not-export-col)"],
                        },
                    },
                ],
            },
        ],
    });
};

export default Datatable;