<template>
	<div class="table-responsive">
		<table id="data-table" class="table table-bordered data-table">
			<thead>
				<tr>
					<th v-for="head in response.tbl_heads.heads" :key="head">
						{{ head }}
					</th>
				</tr>
			</thead>
			<tbody>
				<template v-if="response.chart_type === 'pie'">
					<tr v-for="(expense, key) in response.expenses" :key="'exp' + key">
						<td
							v-if="key === 0"
							:rowspan="Object.keys(response.expenses).length"
							style="padding: 100px 20px"
						>
							<h3>{{ expense.position }}</h3>
						</td>
						<td v-else style="display: none"></td>
						<td>{{ expense.val1 }}</td>
						<td>{{ expense.total }}</td>
					</tr>
				</template>
				<template v-else>
					<tr
						v-for="(expense, val, key) in response.expenses"
						:key="'ex' + key"
					>
						<td
							v-if="key === 0"
							:rowspan="Object.keys(response.expenses).length"
							style="padding: 100px 20px"
						>
							<h3>{{ Object.values(expense)[key]["position"] }}</h3>
						</td>
						<td v-else style="display: none"></td>
						<td>{{ val }}</td>

						<template v-for="(head, index) in response.tbl_heads.heads">
							<td v-if="index > 1" :key="'p' + index">
								{{ expense[head] ? expense[head]["total_cost"] : 0 }}
							</td>
						</template>
					</tr>
				</template>
			</tbody>
		</table>
	</div>
</template>
<script>
export default {
	props: {
		response: {
			required: true,
			type: Object,
		},
	},
};
</script>
