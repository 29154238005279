<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<div class="align-items-center mb-3" align="center">
						<form @submit.prevent="getSenatorialDistricts()">
							<div class="row">
								<div class="col-sm-3">
									<div class="form-group text-center">
										<label class="form-label" for="state_code"
											>Choose State:</label
										>
										<select
											v-model="state_code"
											class="form-select"
											id="state_code"
										>
											<option value="">--select--</option>
											<option
												v-for="state in states"
												:value="state.code"
												:key="state.id"
											>
												{{ state.name }}
											</option>
										</select>
									</div>
								</div>
								<div class="col-sm-3" style="margin-top: 1.7rem">
									<div class="form-group">
										<button type="submit" class="btn btn-info">Search</button>
									</div>
								</div>
							</div>
						</form>
					</div>
					<div class="table-responsive">
						<table
							id="report-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Name</th>
									<th>Code</th>
									<th>State</th>
									<th>Zone</th>
									<!-- <th>Actions</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="(sd, index) in senatorial_zones" :key="'sd' + index">
									<td>{{ index + 1 }}.</td>
									<td>{{ sd.name }}</td>
									<td>{{ sd.code }}</td>
									<td>{{ sd.state.name }}</td>
									<td>{{ sd.state.zone.name }}</td>
									<!-- <td>
										<a href="#!" class="btn btn-info btn-sm"
											><i class="feather icon-edit"></i>&nbsp;Edit
										</a>
										<a href="#!" class="btn btn-danger btn-sm"
											><i class="feather icon-trash-2"></i>&nbsp;Delete
										</a>
									</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- customar project  end -->
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
	data() {
		return {
			state_code: "",
		};
	},

	computed: {
		...mapGetters({
			senatorial_zones: "settings/senatorial_zones",
			states: "settings/states",
		}),
	},

	methods: {
		...mapActions({
			getSenatorialZones: "settings/getSenatorialZones",
			getStates: "settings/getStates",
		}),

		getSenatorialDistricts() {
			let params = this.state_code
				? { state_code: this.state_code, include: "state.zone" }
				: { include: "state.zone" };
			this.getSenatorialZones(params);
		},

		listStates() {
			this.getStates({ keys: JSON.stringify(["name", "code"]) });
		},
	},

	mounted() {
		if (!this.senatorial_zones.length || this.senatorial_zones.length < 109) {
			this.getSenatorialDistricts();
		}
		if (!this.states.length || this.states.length < 35) {
			this.listStates();
		}
	},
};
</script>
