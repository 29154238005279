<template>
	<div v-if="Object.keys(forms).length" class="dt-responsive table-responsive">
		<table id="dt-live-dom" class="table table-striped table-bordered wrap">
			<thead
				style="border-top: 2px solid green; border-bottom: 2px solid green"
			>
				<tr>
					<th width="20%">DONOR NAME</th>
					<th width="20%">DONOR ADDRESS</th>
					<th width="20%">DONOR OCCUPATION</th>
					<th width="20%">TYPE OF <br />CONTRIBUTION <br />CASH/IN-KIND</th>
					<th width="20%">DESCRIPTION OF <br />CONTRIBUTION</th>
					<th>TOTAL VALUE(₦)</th>
					<th width="10%">DATE RECEIVED</th>
					<th width="10%">
						SUPPORTING <br />
						DOCUMENTS
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(form, index) in forms" :key="form.id">
					<td>
						<a
							@click.prevent="deleteForm(form.id, index)"
							href="#"
							class="btn btn-danger btn-sm"
							><i class="feather icon-trash-2"></i>
						</a>
						{{ form.donor_name }}
					</td>
					<td>{{ form.donor_address }}</td>
					<td>{{ form.donor_occupation }}</td>
					<td>{{ form.contribution_type }}</td>
					<td>{{ form.contribution_description }}</td>
					<td>{{ form.contribution_value }}</td>
					<td>{{ form.date_received }}</td>
					<td>
						<template v-if="form.documents">
							<div v-for="doc in form.documents" :key="'doc_' + doc.id">
								<span class="badge bg-info">
									{{ doc.title }}
								</span>
							</div>
						</template>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
import { mapActions } from "vuex";
import { EventBus } from "@/utils/event_bus.js";

export default {
	data() {
		return {
			forms: [],
		};
	},
	methods: {
		...mapActions({
			deleteById: "form_ec16d/deleteById",
			getFormToday: "form_ec16d/getFormToday",
		}),

		async deleteForm(form_id, index) {
			if (!confirm("Are you sure you want to delete?")) {
				return;
			}

			this.deleteById({ id: form_id }).then((response) => {
				if (response === "success") {
					this.forms.splice(index, 1);
				}
			});
		},

		getTodayForm() {
			this.getFormToday().then((response) => (this.forms = response));
		},

		setForm(response) {
			if (response) {
				this.forms.unshift(response.data);
			}
		},
	},
	mounted() {
		this.getTodayForm();

		// eslint-disable-next-line
		EventBus.$on("response_received", this.setForm);
	},
};
</script>
