export default {
    titles: {
            "dashboard": "INEC PARTIES FINANCIAL REPORTING & AUDIT SYSTEM (PFRAS)",
            "gallery": "PFRAS IMAGE GALLERY",
            "reporting": "FORM:EC16D POLITICAL PARTY CAMPAIGN FINANCE REPORTING FORM (INCOME)",
            "expenditures": "FORM:EC16E POLITICAL PARTY CAMPAIGN FINANCE REPORTING FORM (EXPENDITURE)",
            "tracking": "FORM:EC16F POLITICAL PARTY CAMPAIGN FINANCE TRACKING FORM (TRACKING)",
            "income-expenses": "POLITICAL PARTY INCOME & EXPENSES REPORT",
            "expenditure": "POLITICAL PARTY EXPENSES REPORTS (FORM: EC16E)",
            "users": "LIST OF SYSTEM USERS",
            "parties": "LIST OF GEO-POLITICAL PARTIES",
            "positions": "LIST OF ELECTIVE POSITIONS",
            "channels": "LIST OF MEDIA CHANNELS",
            "zones": "LIST OF POLITICAL ZONES",
            "states": "LIST OF STATES",
            "senatorial": "LIST OF SENATORIAL ZONES",
            "constituencies": "LIST OF FEDERAL CONSTITUENCIES",
            "state-constituencies": "LIST OF STATE CONSTITUENCIES",
            "report-submission": "POLITICAL PARTIES REPORT SUBMISSION SETTINGS",
            "supporting-documents": "SUPPORTING DOCUMENT SETTINGS",
            "lgas": "LIST OF L.G.A",
            "profile": "YOUR PROFILE",
            "file-manager": "LIST OF DOWNLOADABLE FILES",
        }
    
};