<template>
	<div style="margin-top: 65px">
		<!-- customar project  start -->
		<div class="row">
			<div class="col-md-6">
				<div class="card">
					<div class="card-header">
						<h5 style="font-size: 1.5rem">Profile Data</h5>
					</div>
					<div class="card-body">
						<div class="form-group">
							<label class="control-label">Full Name </label>
							<input
								:value="user.name"
								type="text"
								class="form-control"
								readonly
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Email </label>
							<input
								:value="user.email"
								type="text"
								class="form-control"
								readonly
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Phone Number </label>
							<input
								:value="user.phone"
								type="text"
								class="form-control"
								readonly
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Designation </label>
							<input
								:value="user.designation"
								type="text"
								class="form-control"
								readonly
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Gender </label>
							<input
								:value="user.gender"
								type="text"
								class="form-control"
								readonly
							/>
						</div>
						<div class="form-group">
							<label class="control-label">Role</label>
							<input
								:value="user.role"
								type="text"
								class="form-control"
								readonly
							/>
						</div>
						<div v-if="user.role.toLowerCase() == 'party'" class="form-group">
							<label class="control-label">Party </label>
							<input
								:value="user.party"
								type="text"
								class="form-control"
								readonly
							/>
						</div>
						<div class="form-group">
							<label class="control-label">State </label>
							<input
								:value="user.state"
								type="text"
								class="form-control"
								readonly
							/>
						</div>
						<div class="form-group">
							<label class="control-label">L.G.A </label>
							<input
								:value="user.lga"
								type="text"
								class="form-control"
								readonly
							/>
						</div>

						<div class="form-group">
							<label class="control-label">Account Status </label>
							<input
								:value="user.status"
								type="text"
								class="form-control"
								readonly
							/>
						</div>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<form @submit.prevent="updatePassword()" action="" method="post">
					<div class="card">
						<div class="card-header">
							<h5 style="font-size: 1.5rem">Update Password</h5>
						</div>
						<div class="card-body">
							<div class="form-group">
								<label class="control-label">Current Password </label>
								<input
									v-model="form.current_password"
									type="password"
									class="form-control"
									required
								/>
							</div>
							<div class="form-group">
								<label class="control-label">New Password </label>
								<input
									v-model="form.password"
									type="password"
									class="form-control"
									required
								/>
							</div>
							<div class="form-group">
								<label class="control-label">Confirm New Password </label>
								<input
									v-model="form.password_confirmation"
									type="password"
									class="form-control"
									required
								/>
							</div>
						</div>
						<div class="card-footer">
							<LoadingButton class="btn-info">Update</LoadingButton>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import LoadingButton from "@/components/LoadingButton.vue";
export default {
	components: {
		LoadingButton,
	},

	data() {
		return {
			form: {
				password_confirmation: null,
				current_password: null,
				password: null,
				// id: this.user.id,
			},
		};
	},

	computed: {
		...mapGetters({
			user: "auth/currentUser",
		}),
	},

	methods: {
		async updatePassword() {
			this.form.id = this.user.id;
			await this.$store
				.dispatch("settings/updateUserPassword", this.form)
				.then((response) => {
					if (response === "success") {
						this.form.password_confirmation = null;
						this.form.current_password = null;
						this.form.password = null;
						this.form.id = null;
					}
				});
		},
	},
};
</script>
<style scoped>
.form-control {
	padding: 10px;
}
</style>
