/* eslint-disable */
import Vue from "vue";
import Router from "vue-router";
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import { routes } from "./routes";
import { beforeEach } from "./beforeEach";

Vue.use(Router);

const router = new Router({
  routes,
  mode: "history",
  history: true,
  // linkActiveClass: "active",
  // linkExactActiveClass: "active",
  saveScrollPosition: true,
});

router.beforeEach(beforeEach);

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
      // Start the route progress bar.
      NProgress.start()
  }
  next();
});

router.afterEach((to, from) => {
    // Complete the animation of the route progress bar.
    NProgress.done()
})

export default router;
