import Request from "@/Request";

export function login(payload) {
  return Request({
    url: "/auth/login",
    method: "post",
    data: payload,
  });
}

export function logout() {
  return Request({
    url: "/auth/logout",
    method: "post",
  });
}
