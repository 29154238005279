import Request from "@/Request";

export function createForm(payload) {
  return Request({
    url: "/expenditures",
    method: "post",
    data: payload,
  });
}

export function updateForm(payload) {
  return Request({
    url: "/expenditures/" + payload.id,
    method: "put",
    data: payload,
  });
}

export function formList(payload) {
  return Request({
    url: "/expenditures",
    method: "get",
    params: payload,
  });
}

export function formById(payload) {
  return Request({
    url: `/expenditures/${payload.batch_id}/view`,
    method: "get",
  });
}

export function deleteForm(payload) {
  return Request({
    url: `/expenditures/${payload.batch_id}`,
    method: "delete",
  });
}

export function formImport(payload) {
  return Request({
    url: "/expenditures/import",
    method: "post",
    data: payload,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
