export default {
  lgas: [],
  zones: [],
  users: [],
  roles: [],
  states: [],
  parties: [],
  channels: [],
  locations: [],
  advert_types: [],
  expenditures: [],
  senatorial_zones: [],
  report_submission: [],
  elective_positions: [],
  state_constituencies: [],
  supporting_documents: [],
  federal_constituencies: [],
  report_submission_status: [],
};
