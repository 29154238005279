<template>
	<form @submit.prevent="saveAll()" method="post">
		<Form @set-input="setInput" />
		<fieldset class="dt-responsive table-responsive">
			<legend>PRINT MEDIA EXPENSES:</legend>
			<table id="dt-live-dom" class="table table-bordered wrap">
				<thead>
					<tr>
						<!-- <th>S/NO</th> -->
						<th style="width: 15%">NEWS PAPER/MAGAZINE</th>
						<th>DESCRIPTION</th>
						<th style="width: 10%">RATE</th>
						<th style="width: 5%">QUANTITY</th>
						<th style="width: 10%">UNIT COST</th>
						<th style="width: 15%">TOTAL COST</th>
						<th>REMARKS</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(form, index) in forms" :key="form.id">
						<td>
							<a
								@click.prevent="deleteForm(form.id, index)"
								href="#"
								class="btn btn-danger btn-sm"
								><i class="feather icon-trash-2"></i>
							</a>
							{{ form.media_channel ? form.media_channel.name : null }}
						</td>
						<td>{{ form.description }}</td>
						<td>{{ form.rate }}</td>
						<td>{{ form.quantity }}</td>
						<td>{{ form.unit_cost }}</td>
						<td>{{ form.total_cost }}</td>
						<td>{{ form.remarks }}</td>
					</tr>
					<tr>
						<!-- <td>1</td> -->
						<td>
							<select
								@change="setRate($event.target.value)"
								class="form-select"
								id="media_channel_id"
								required
							>
								<option value="">--select--</option>
								<option
									v-for="md in media"
									:value="JSON.stringify(md)"
									:key="'md' + md.id"
								>
									{{ md.name }}
								</option>
							</select>
						</td>
						<td>
							<textarea
								v-model="form.description"
								class="form-control"
								type="text"
								id="rdescription"
								name="description"
								rows="1"
								placeholder="Enter description"
							></textarea>
						</td>
						<td>
							<input
								@input="inputMustBeCurrency('rate')"
								@change="calculateTotalCost()"
								v-model="form.rate"
								class="form-control"
								type="text"
								id="rate"
								name="rate"
								style="padding: 0.625rem 2px"
								required
							/>
						</td>
						<td>
							<input
								@input="inputMustBeUnsignedInteger('quantity')"
								@change="calculateTotalCost()"
								v-model="form.quantity"
								class="form-control"
								type="text"
								id="quantity"
								name="quantity"
								required
							/>
						</td>
						<td>
							<input
								@input="inputMustBeCurrency('unit_cost')"
								@change="calculateTotalCost()"
								v-model="form.unit_cost"
								class="form-control"
								type="text"
								id="unit_cost"
								name="unit_cost"
								style="padding: 0.625rem 2px"
								required
							/>
						</td>
						<td>
							<input
								v-model="form.total_cost"
								class="form-control"
								type="text"
								id="total_cost"
								name="total_cost"
								style="padding: 0.625rem 2px"
								readonly
								required
							/>
						</td>
						<td>
							<textarea
								v-model="form.remarks"
								class="form-control"
								type="text"
								id="remarks"
								name="remarks"
								rows="1"
								placeholder="Enter remarks"
							></textarea>
						</td>
					</tr>
				</tbody>
			</table>
		</fieldset>
		<div align="center" style="margin-bottom: 0; margin-top: 2rem">
			<LoadingButton class="btn btn-info">Save All</LoadingButton>
		</div>
	</form>
</template>
<script>
import { mustBeUnsignedInteger, mustBeCurrency } from "@/utils/input_filter.js";
import { EventBus } from "@/utils/event_bus.js";
import LoadingButton from "@/components/LoadingButton.vue";
import { mapActions, mapGetters } from "vuex";
import Form from "@/components/Form.vue";

export default {
	props: {
		candidate_name: {
			type: String,
			// required: true,
		},
		advert_type_id: {
			type: String,
			required: true,
		},
	},
	components: {
		Form,
		LoadingButton,
	},
	computed: {
		...mapGetters({
			media: "settings/channels",
		}),
	},
	data() {
		return {
			forms: [],
			form: {
				media_channel_id: "",
				description: "",
				rate: "",
				quantity: "",
				unit_cost: "",
				total_cost: 0,
				remarks: "",

				party_id: "",
				state_code: "",
				elective_position_id: "",
				lga_id: "",
				date_reported: "",
				constituency_id: "",
				advert_type_id: this.advert_type_id ?? "", // Print Media ID
				candidate_name: this.candidate_name ?? "",
			},
		};
	},
	methods: {
		...mapActions({
			addPrintMediaForm: "form_ec16f/addPrintMediaForm",
			deleteById: "form_ec16f/deleteById",
		}),

		async saveAll() {
			await this.addPrintMediaForm(this.form).then((response) => {
				if (response) {
					this.forms.push(response);
					this.clearFormInputs();
				}
			});
		},

		clearFormInputs() {
			this.form.media_channel_id = "";
			this.form.description = "";
			this.form.rate = "";
			this.form.quantity = "";
			this.form.unit_cost = "";
			this.form.total_cost = 0;
			this.form.remarks = "";

			document.getElementById("media_channel_id").value = "";
		},

		calculateTotalCost() {
			if (this.form.quantity === "") {
				this.form.quantity = 1;
			}

			let total =
				parseInt(this.form.quantity) * parseFloat(this.form.unit_cost);
			this.form.total_cost = isNaN(total) ? 0 : total;
		},

		inputMustBeUnsignedInteger(input_id) {
			return mustBeUnsignedInteger(input_id);
		},

		inputMustBeCurrency(input_id) {
			return mustBeCurrency(input_id);
		},

		setInput(params) {
			this.form[params.name] = params.value;
		},

		setRate(media) {
			let md = JSON.parse(media);

			this.form.rate = md.price;
			this.form.unit_cost = md.price;
			this.form.media_channel_id = md.id;
		},

		async deleteForm(form_id, index) {
			if (!confirm("Are you sure you want to delete?")) {
				return;
			}

			this.deleteById({ id: form_id }).then((response) => {
				if (response === "success") {
					this.forms.splice(index, 1);
				}
			});
		},

		getChannelName(media_channel_id) {
			return this.media.find((md) => md.id == media_channel_id).name;
		},
	},
	created() {
		EventBus.$on("set-params", this.setInput);
	},
	mounted() {
		// if (!this.media.length) {
		// }
		this.$store.dispatch("settings/getMediaChannels");
	},
};
</script>
<style scoped>
fieldset {
	background-color: #eeeeee;
}

legend {
	background-color: #29623c;
	color: white;
	padding: 5px 10px;
}
.btn-info {
	width: 200px;
	border-radius: 6px;
}
</style>
