<template>
	<div class="card" style="border: 2px solid green">
		<div class="card-header">
			<h2 align="center" style="font-weight: 700" class="text-primary">
				FORM DETAILS <br />
				<span v-if="form" class="badge bg-danger" style="font-size: 12px"
					>By {{ form[0].user }}</span
				>
			</h2>
		</div>
		<div v-if="form" class="card-block">
			<div class="row">
				<div class="col-md-12 card">
					<div
						class="row"
						style="
							background: rgb(242 231 231 / 44%);
							text-transform: uppercase;
						"
					>
						<div class="col-md-5">
							<table class="table wrap" style="border-collapse: unset">
								<tr>
									<th>Name of Political Party:</th>
									<th>
										<strong>{{ form[0].party }}</strong>
									</th>
								</tr>
								<tr>
									<th>State:</th>
									<th>
										<strong>{{ form[0].state }}</strong>
									</th>
								</tr>
								<tr v-if="form[0].sd">
									<th>Senatorial Zone:</th>
									<th>
										<strong>{{ form[0].sd }}</strong
										><br />
										<strong v-if="form[0].sd_code"
											>({{ form[0].sd_code }})</strong
										>
									</th>
								</tr>
								<tr v-if="form[0].constituency">
									<th>Federal Constituency:</th>
									<th>
										<strong>{{ form[0].constituency }}</strong
										><br />
										<strong>({{ form[0].constituency_code }})</strong>
									</th>
								</tr>
								<tr v-if="form[0].state_constituency">
									<th>State Constituency:</th>
									<th>
										<strong>{{ form[0].state_constituency }}</strong
										><br />
										<strong>({{ form[0].state_constituency_code }})</strong>
									</th>
								</tr>
							</table>
						</div>
						<div class="col-md-2"></div>
						<div class="col-md-5">
							<table class="table wrap" style="border-collapse: unset">
								<tr v-if="form[0].position">
									<th>Type of Election:</th>
									<th>
										<strong>{{ form[0].position }}</strong>
									</th>
								</tr>
								<tr v-if="form[0].lga">
									<th>L.G.A:</th>
									<th>
										<strong>{{ form[0].lga }}</strong>
									</th>
								</tr>
								<tr>
									<th>Campaign Date:</th>
									<th>
										<strong>{{ form[0].date_reported }}</strong>
									</th>
								</tr>
								<tr v-if="form[0].approved_by">
									<th>Approved By:</th>
									<th>
										<strong>{{ form[0].approved_by }}</strong>
									</th>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="dt-responsive table-responsive">
				<table id="dt-live-dom" class="table table-bordered nowrap">
					<thead
						style="border-top: 2px solid green; border-bottom: 2px solid green"
					>
						<tr>
							<th>S/NO</th>
							<th>EXPENDITURE HEAD</th>
							<th>NUMBER/QUANTITY</th>
							<th>UNIT COST</th>
							<th>TOTAL COST</th>
							<th>REMARKS</th>
							<th v-if="$route.query.edt">ACTION</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="expenditure,index in form" :key="'ex' + expenditure.id">
							<td>{{ index + 1 }}.</td>
							<td>
								{{ expenditure.expenditure }}
							</td>
							<td>
								{{ expenditure.quantity }}
							</td>
							<td>
								{{ expenditure.unit_cost }}
							</td>
							<td>
								{{ expenditure.total_cost }}
							</td>
							<td>
								{{ expenditure.remarks }}
							</td>
							<td v-if="$route.query.edt">
								<a
									@click.prevent="setReport(expenditure)"
									href="#!"
									class="btn btn-info btn-sm"
									><i class="feather icon-edit"></i>&nbsp;Edit
								</a>
							</td>
						</tr>
					</tbody>
					<tfoot style="border-bottom: 2px solid green">
						<tr>
							<th>S/NO</th>
							<th>EXPENDITURE HEAD</th>
							<th>NUMBER/QUANTITY</th>
							<th>UNIT COST</th>
							<th>TOTAL COST</th>
							<th>REMARKS</th>
							<th v-if="$route.query.edt">ACTION</th>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
		<div align="center" style="margin-bottom: 1rem">
			<a
				@click.prevent="closeTab()"
				class="btn btn-secondary"
				style="border-radius: 6px"
				>Go Back</a
			>
		</div>

		<ExpenditureEditModal :report="report" />
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/utils/event_bus.js";
import ExpenditureEditModal from "./ExpenditureEditModal.vue";

export default {
	name: "ExpenditureView",
	components: {
		ExpenditureEditModal,
	},
	data() {
		return {
			report: null,
		};
	},
	computed: {
		...mapGetters({
			form: "form_ec16e/singleForm",
		}),
	},
	methods: {
		...mapActions({
			getFormById: "form_ec16e/getFormById",
		}),

		setReport(report) {
			this.report = report;
			return EventBus.$emit("toggle-modal", "show");
		},
		closeTab() {
			if (this.$route.query.edt) {
				return this.$router.back();
			}

			if (confirm("Are you sure you want to close this window?")) {
				window.close();
			}
		},
	},
	mounted() {
		if (this.form && this.form.batch_id == this.$route.params.batch_id) {
			return;
		}

		this.getFormById({ batch_id: this.$route.params.batch_id});
	},
};
</script>
