<template>
	<div
		v-if="toggle_modal == 'show'"
		class="modal fade show"
		tabindex="-1"
		role="dialog"
		aria-labelledby="modal"
		aria-modal="true"
		style="
			display: block;
			background-color: rgba(0, 0, 0, 0.5);
			transition: opacity 0.2s ease;
		"
	>
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Log Out</h5>
					<button
						@click.prevent="hideModal()"
						type="button"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
				</div>
				<div class="modal-body">
					<form @submit.prevent="logout()">
						<div class="card-body">
							<h5 style="font-size: 1.5rem">
								Are you sure you want to logout?
							</h5>
						</div>
						<div class="card-footer" align="center">
							<button
								@click.prevent="hideModal()"
								class="btn btn-secondary"
								style="margin-right: 5px"
							>
								Cancel
							</button>
							<LoadingButton class="btn-danger">Logout</LoadingButton>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from "@/utils/event_bus.js";
import LoadingButton from "@/components/LoadingButton.vue";
export default {
	components: {
		LoadingButton,
	},
	data() {
		return {
			toggle_modal: "hide",
		};
	},
	methods: {
		async logout() {
			await this.$store.dispatch("auth/Logout");
		},

		hideModal() {
			EventBus.$emit("toggle-logout-modal", "hide");
		},
	},
	mounted() {
		EventBus.$on("toggle-logout-modal", (type) => {
			this.toggle_modal = type;
		});
	},
};
</script>
