<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<div class="row align-items-center m-l-0">
						<div class="col-sm-8">
							<div class="align-items-center mb-3" align="center">
								<form @submit.prevent="filterUsers()">
									<div class="row">
										<div class="col-sm-3">
											<div class="form-group text-center">
												<label class="form-label" for="state"
													>Choose State:</label
												>
												<select
													v-model="state_code"
													class="form-select"
													id="state"
												>
													<option value="">--select--</option>
													<option
														v-for="state in states"
														:value="state.code"
														:key="'state_' + state.code"
													>
														{{ state.name }}
													</option>
												</select>
											</div>
										</div>
										<div v-if="user_is_staff_admin" class="col-md-3">
											<div class="form-group">
												<label class="form-label" for="role"
													>Choose Role:</label
												>
												<select v-model="role" class="form-select" id="role">
													<option value="">--select--</option>
													<option
														v-for="role in roles"
														:value="role.id"
														:key="'role_' + role.id"
													>
														{{ role.display_name }}
													</option>
												</select>
											</div>
										</div>
										<div class="col-sm-3" style="margin-top: 1.7rem">
											<div class="form-group">
												<button
													type="submit"
													class="btn btn-info"
													style="border-radius: 6px"
												>
													Search
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
					<div class="alert alert-warning">
						This module enables the administrator to create and manage user
						accounts, assign user roles and party affiliations. The
						administrator can also modify user account details, including
						password changes.
					</div>
					<div class="text-end mb-2">
						<button
							v-if="can_create_user"
							@click.prevent="toggleModal('show')"
							class="btn btn-success btn-sm btn-round has-ripple"
						>
							<i class="feather icon-plus"></i> Add New User
						</button>
						<a
							v-if="user_is_staff_admin"
							href="https://app.pfras.ng/user"
							target="_blank"
							class="btn btn-link ml-2"
						>
							<i class="feather icon-lock"></i> Manage Roles & Permission
						</a>
					</div>
					<div class="table-responsive">
						<table
							id="report-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Full Name</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Gender</th>
									<th>Designation</th>
									<th>State</th>
									<th>L.G.A</th>
									<th>Status</th>
									<th>Role</th>
									<th>Party</th>
									<th>Last Login</th>
									<th v-if="can_update_user">Actions</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(user, index) in users.users"
									:key="'user_' + user.id"
								>
									<td>{{ index + 1 }}.</td>
									<td>{{ user.name }}</td>
									<td>{{ user.email }}</td>
									<td>{{ user.phone }}</td>
									<td>{{ user.gender }}</td>
									<td>{{ user.designation }}</td>
									<td>{{ user.state }}</td>
									<td>{{ user.lga }}</td>
									<td>
										<span
											class="badge"
											:class="
												user.status == 'Active' ? 'bg-success' : 'bg-danger'
											"
											>{{ user.status }}</span
										>
									</td>
									<td>
										<span class="badge bg-secondary">{{ user.role }}</span>
									</td>
									<td>{{ user.party }}</td>
									<td>
										<strong>{{ user.last_login }}</strong>
									</td>
									<td v-if="can_update_user">
										<a
											@click.prevent="setUser(user)"
											href="#!"
											class="btn btn-info btn-sm"
											><i class="feather icon-edit"></i>&nbsp;Edit
										</a>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<template v-if="Object.keys(users).length && users.paginator.has_pages">
				<Paginator :paginator="users.paginator" @goto-page="listUsers" />
			</template>
		</div>
		<!-- customar project  end -->

		<UserModal :user="user" />
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/utils/event_bus.js";
import UserModal from "./UserModal.vue";
import Paginator from "@/components/Paginator";
import { userHasPermission, userIsStaffAdmin } from "@/utils/permissions";

export default {
	components: {
		UserModal,
		Paginator,
	},
	data() {
		return {
			user: null,
			state_code: "",
			role: "",
		};
	},

	computed: {
		...mapGetters({
			users: "settings/users",
			states: "settings/states",
			roles: "settings/roles",
		}),
		can_create_user() {
			return userHasPermission("users-create");
		},
		can_update_user() {
			return userHasPermission("users-update");
		},
		user_is_staff_admin() {
			return userIsStaffAdmin();
		},
	},

	methods: {
		...mapActions({
			getUsers: "settings/getUsers",
			getStates: "settings/getStates",
		}),

		listUsers(type = null) {
			let params = {
				include: JSON.stringify(["state", "lga", "party", "roles"]),
			};

			if (type) {
				if (typeof type === "object") {
					params.q = JSON.stringify(type); // Object containing the searchable fields
					params.page = null; // Reset page no
				}

				if (typeof type === "string") {
					params.page =
						type === "next"
							? parseInt(this.users.paginator.current_page) + 1
							: parseInt(this.users.paginator.current_page) - 1;
				}
			}

			this.getUsers(params);
		},

		filterUsers() {
			this.listUsers({
				state_code: this.state_code,
				role: this.role,
			});
		},

		listStates() {
			this.getStates({ keys: JSON.stringify(["name", "code"]) });
		},

		toggleModal(type) {
			EventBus.$emit("toggle-modal", type);
			EventBus.$emit("btn-type", "add");
		},

		setUser(user) {
			this.user = user;
			return EventBus.$emit("toggle-modal", "show");
		},
	},

	mounted() {
		if (!this.states.length || this.states.length < 35) {
			this.listStates();
		}

		if (!this.users.length) {
			this.listUsers();
		}
	},
};
</script>
