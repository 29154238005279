import { createForm, updateForm, formList, formToday, formById, formDeleteById, formImport } from "@/api/form_ec16d";
import store from "@/store";

export const addForm = (commit, payload) => {
  return new Promise((resolve) => {
    createForm(payload).then((response) => {
      if (response.status_code === 201) {
        resolve(response);
      }
    });
  });
};

export const editForm = (commit, payload) => {
  return new Promise((resolve) => {
    updateForm(payload).then((response) => {
      if (response.status_code === 201) {
        getFormList();
      }
      resolve("success");
    });
  });
};

export const getFormList = (commit, payload) => {
  return new Promise((resolve) => {
    formList(payload).then((response) => {
      if (response.status_code === 200) {
        store.commit("form_ec16d/setForms", response.data);
      }
      resolve();
    });
  });
};


export const getFormToday = (commit, payload) => {
  return new Promise((resolve) => {
    formToday(payload).then((response) => {
      if (response.status_code === 200) {
        resolve(response.data);
      }
    });
  });
};

export const getFormById = (commit, payload) => {
  return new Promise((resolve) => {
    formById(payload).then((response) => {
      if (response.status_code === 200) {
        store.commit("form_ec16d/setSingleForm", response.data);
      }
      resolve();
    });
  });
};

export const deleteById = (commit, payload) => {
  return new Promise((resolve) => {
    formDeleteById(payload).then((response) => {
      if (response.status_code === 201) {
        resolve("success");
      }
    });
  });
};

export const formImportCSV = (commit, payload) => {
  return new Promise((resolve) => {
    formImport(payload).then((response) => {
      if (response.status_code === 201) {
        getFormList();
        resolve("success");
      }
    });
  });
};
