<template>
	<div class="card" style="border: 2px solid green">
		<div class="card-header">
			<div align="center" style="font-weight: 700" class="text-danger">
				(Please fill the form below and click on <b>Save All</b> to submit)
			</div>
		</div>
		<!-- <form action="" method="post"> -->
		<div class="card-block">
			<div class="row">
				<div class="col-md-6">
					<div class="row mb-3">
						<label for="advert_type_id" class="col-sm-4 col-form-label"
							>Advert Type:<span class="text-danger">*</span></label
						>
						<div class="col-sm-8">
							<select
								@change="setAdvert($event)"
								class="form-select"
								id="advert_type_id"
								name="advert_type_id"
								required
							>
								<option value="">--select--</option>
								<option
									v-for="advert in advert_types"
									:value="advert.id"
									:key="'ad' + advert.id"
								>
									{{ advert.name }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<div class="col-md-6">
					<div class="row mb-3">
						<label for="candidate_name" class="col-sm-4 col-form-label"
							>Name of Candidate:<span class="text-danger">*</span></label
						>
						<div class="col-sm-8">
							<input
								v-model="candidate_name"
								@change="setInputValue($event)"
								name="candidate_name"
								id="candidate_name"
								type="text"
								class="form-control date"
								required
							/>
						</div>
					</div>
				</div>
			</div>

			<div v-if="!advert_type" class="alert alert-danger">
				<strong>Before you proceed, Please select advert type!</strong>
			</div>

			<!-- billboard form -->
			<AdvertBillboardForm
				v-if="advert_type == 'billboard'"
				:advert_type_id="advert_type_id"
				:candidate_name="candidate_name"
			/>

			<!-- electronic form -->
			<AdvertElectronicForm
				v-if="advert_type == 'electronic'"
				:advert_type_id="advert_type_id"
				:candidate_name="candidate_name"
			/>

			<!-- print form -->
			<AdvertPrintForm
				v-if="advert_type == 'print'"
				:advert_type_id="advert_type_id"
				:candidate_name="candidate_name"
			/>

			<!-- coverage form -->
			<AdvertCoverageForm
				v-if="advert_type == 'coverage'"
				:advert_type_id="advert_type_id"
				:candidate_name="candidate_name"
			/>

			<!-- campaign form -->
			<ExpenditureAdd
				:show_part="false"
				:candidate_name="candidate_name"
				:advert_type_id="advert_type_id"
				v-if="advert_type == 'campaign'"
			/>

			<div align="center">
				<router-link
					to="/forms/tracking"
					class="btn btn-link"
					style="border-radius: 6px"
					>Cancel</router-link
				>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from "@/utils/event_bus.js";
import { mapGetters } from "vuex";
import AdvertBillboardForm from "@/views/Forms/Tracking/Partials/Forms/AdvertBillboardForm.vue";
import AdvertElectronicForm from "@/views/Forms/Tracking/Partials/Forms/AdvertElectronicForm.vue";
import AdvertPrintForm from "@/views/Forms/Tracking/Partials/Forms/AdvertPrintForm.vue";
import AdvertCoverageForm from "@/views/Forms/Tracking/Partials/Forms/AdvertCoverageForm.vue";
// import AdvertCampaignForm from "@/views/Forms/Tracking/Partials/AdvertCampaignForm.vue";
import ExpenditureAdd from "../Expenditure/ExpenditureAdd.vue";

export default {
	name: "TrackingAdd",
	components: {
		AdvertBillboardForm,
		AdvertElectronicForm,
		AdvertPrintForm,
		AdvertCoverageForm,
		// AdvertCampaignForm,
		ExpenditureAdd,
	},
	data() {
		return {
			advert_type: "",
			advert_type_id: "",
			candidate_name: "",
		};
	},
	// watch: {
	// 	advert_type(val) {
	// 		if (val == "campaign") {
	// 			// this.$store.commit("settings/setExpenditures", []);
	// 		}
	// 	},
	// },
	computed: {
		...mapGetters({
			advert_types: "settings/advert_types",
		}),

		adverts() {
			return {
				1: "billboard",
				2: "electronic",
				3: "print",
				4: "coverage",
				5: "campaign",
			};
		},
	},
	methods: {
		setAdvert(event) {
			this.advert_type_id = event.target.value;
			this.advert_type = this.adverts[event.target.value];

			this.setInputValue(event);
		},
		setInputValue(event) {
			let params = {
				name: event.target.id,
				value: event.target.value,
			};

			EventBus.$emit("set-params", params);
		},
	},
	mounted() {
		if (!this.advert_types.length) {
			this.$store.dispatch("settings/getAdvertTypes");
		}
	},
};
</script>
<style scoped>
fieldset {
	background-color: #eeeeee;
}

legend {
	background-color: #29623c;
	color: white;
	padding: 5px 10px;
}

.btn {
	width: 200px;
	border-radius: 6px;
}
</style>
