export const lgas = (state) => state.lgas;
export const roles = (state) => state.roles;
export const zones = (state) => state.zones;
export const users = (state) => state.users;
export const states = (state) => state.states;
export const parties = (state) => state.parties;
export const channels = (state) => state.channels;
export const locations = (state) => state.locations;
export const expenditures = (state) => state.expenditures;
export const advert_types = (state) => state.advert_types;
export const senatorial_zones = (state) => state.senatorial_zones;
export const report_submission = (state) => state.report_submission;
export const elective_positions = (state) => state.elective_positions;
export const state_constituencies = (state) => state.state_constituencies;
export const supporting_documents = (state) => state.supporting_documents;
export const federal_constituencies = (state) => state.federal_constituencies;
export const report_submission_status = (state) => state.report_submission_status;
