<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table
							id="report-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Name of Zone</th>
									<th>States</th>
									<!-- <th>Actions</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="zone in zones" :key="'z' + zone.id">
									<td>{{ zone.id }}.</td>
									<td>{{ zone.name }}</td>
									<td>
										<span
											v-for="(state, index) in zone.states"
											class="badge bg-primary mr-2"
											:key="'s' + index"
										>
											{{ state.name }}
										</span>
									</td>
									<!-- <td>
										<a href="#!" class="btn btn-info btn-sm"
											><i class="feather icon-edit"></i>&nbsp;Edit
										</a>
										<a href="#!" class="btn btn-danger btn-sm"
											><i class="feather icon-trash-2"></i>&nbsp;Delete
										</a>
									</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- customar project  end -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters({
			zones: "settings/zones",
		}),
	},

	mounted() {
		// if (!this.zones.length) {
		this.$store.dispatch("settings/getZones");
		// }
	},
};
</script>
<style scoped>
.mr-2 {
	margin-right: 0.5rem;
}
</style>
