<template>
	<div>
		<div
			v-if="showErrorAlert()"
			class="alert bg-danger alert-dismissible fade show"
		>
			<strong>Error! </strong>
			<template v-if="typeof error === 'object'">
				<ul>
					<li v-for="(err, index) in error" :key="index">
						{{ err }}
					</li>
				</ul>
			</template>
			<template v-else> {{ error }}</template>

			<button
				@click.prevent="hideAlerts()"
				type="button"
				class="btn-close"
				data-bs-dismiss="alert"
				aria-label="Close"
			></button>
		</div>
		<div
			v-if="showSuccessAlert()"
			class="alert bg-success alert-dismissible fade show"
		>
			<strong>Success!</strong> {{ success }}
			<button
				@click.prevent="hideAlerts()"
				type="button"
				class="btn-close"
				data-bs-dismiss="alert"
				aria-label="Close"
			></button>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters({
			success: "shared/getSuccessResponse",
			error: "shared/getErrorResponse",
		}),
	},
	methods: {
		hideAlerts() {
			this.$store.commit("shared/setErrorResponse", null);
			this.$store.commit("shared/setSuccessResponse", null);
		},
		setAlertTimeout() {
			window.scrollTo(0, 0);

			setTimeout(() => {
				this.hideAlerts();
			}, 10000);
		},
		showErrorAlert() {
			if (this.error) {
				this.setAlertTimeout();
				return true;
			}

			return false;
		},
		showSuccessAlert() {
			if (this.success) {
				this.setAlertTimeout();
				return true;
			}

			return false;
		},
	},
};
</script>
