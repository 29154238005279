<template>
	<!-- [ breadcrumb ] start -->
	<div class="page-header">
		<div class="page-block">
			<div class="row align-items-center">
				<div class="col-md-12">
					<div class="page-header-title">
						<h2 class="m-b-10 text-center">
							Welcome! {{ user.name }} From
							{{ user_is_party ? user.party : " " }}
							{{ user.state }}
							{{
								(user_is_party && user_state_code == "NA") ||
								user_state_code == "FCT"
									? " "
									: " State"
							}}
						</h2>
					</div>
					<ul class="breadcrumb text-center" style="display: block">
						<!-- <li class="breadcrumb-item">
							<a href="" style="font-size: 21px"
								><i class="feather icon-home"></i
							></a>
						</li> -->
						<li class="breadcrumb-item text-right">
							<a href="" style="font-size: 18px">{{ getTitle() }}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<!-- [ breadcrumb ] end -->
</template>
<script>
import { mapGetters } from "vuex";
import { userIsParty } from "@/utils/permissions";

export default {
	computed: {
		...mapGetters({
			titles: "breadcrumbsTitle/titles",
			user: "auth/currentUser",
		}),
		user_is_party() {
			return userIsParty();
		},
	},

	methods: {
		getTitle() {
			if (this.$route && this.$route.path) {
				let route = this.$route.path.split("/");

				return this.titles[route[route.length - 1]];
			}

			return "INEC PARTIES FINANCIAL REPORTS & AUDITING SYSTEM (PFRAS)";
		},
	},
};
</script>
<style scoped>
@media screen and (max-width: 720px) {
	.page-header-title {
		visibility: hidden;
		display: none;
	}
}
</style>
