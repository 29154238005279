var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-5 mx-auto"},[_c('div',{staticClass:"card",staticStyle:{"border-color":"green"}},[_c('h4',{staticClass:"text-center mt-4"},[_vm._v("Downloads Centre")]),_c('div',{staticClass:"card-body"},[_c('div',[_c('a',{staticClass:"card",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.getUrl('income-sample')}},[_vm._m(0)]),_c('a',{staticClass:"card",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.getUrl('expenditure-sample')}},[_vm._m(1)]),_c('a',{staticClass:"card",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.getUrl('supporting-documents')}},[_vm._m(2)]),_c('a',{staticClass:"card",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.getUrl('senatorial-zones')}},[_vm._m(3)]),_c('a',{staticClass:"card",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.getUrl('federal-constituencies')}},[_vm._m(4)]),_c('a',{staticClass:"card",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.getUrl('state-constituencies')}},[_vm._m(5)]),_c('a',{staticClass:"card",staticStyle:{"cursor":"pointer"},attrs:{"href":_vm.getUrl('parties')}},[_vm._m(6)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"font-weight:700"},[_vm._v("Download Form EC16D Sample File")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"font-weight:700"},[_vm._v("Download Form EC16E Sample File")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"font-weight:700"},[_vm._v("Download Supporting Documents")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"font-weight:700"},[_vm._v("Download Senatorial Zones")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"font-weight:700"},[_vm._v("Download Federal Constituencies")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"font-weight:700"},[_vm._v("Download State Constituencies")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"font-weight:700"},[_vm._v("Download Political Parties")])])
}]

export { render, staticRenderFns }