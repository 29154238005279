<template>
	<!-- [ navigation menu ] start -->
	<nav class="pcoded-navbar menu-light">
		<div class="navbar-wrapper">
			<div class="navbar-content scroll-div">
				<!-- Sidebar Header here -->
				<SidebarHeader></SidebarHeader>

				<!-- Sidebar Links here -->
				<SidebarLinks></SidebarLinks>

				<div class="card text-center">
					<div class="card-block">
						<template v-if="user_is_party"
							><img :src="user.party_logo" :alt="user.party" width="150px"
						/></template>
						<template v-else>
							<img src="@/assets/images/eu.jpg" alt="EU Logo" width="150px" />
						</template>
					</div>
				</div>
			</div>
		</div>
	</nav>
	<!-- [ navigation menu ] end -->
</template>
<script>
import SidebarHeader from "./SidebarHeader";
import SidebarLinks from "./SidebarLinks";
import { mapGetters } from "vuex";
import { userIsParty } from "@/utils/permissions";

export default {
	components: {
		SidebarHeader,
		SidebarLinks,
	},
	computed: {
		...mapGetters({
			user: "auth/currentUser",
		}),
		user_is_party() {
			return userIsParty();
		},
	},
};
</script>
