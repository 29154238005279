import TomSelect from "tom-select";
import "tom-select/dist/css/tom-select.css";


const initializeMultipleSelect = (select_id, data) => {
    let select = document.getElementById(select_id);
    let selectizeInstance = null;

    if (select) {
        // Check if an instance already exists
        if (select.selectize) {
            selectizeInstance = select.selectize;
            selectizeInstance.destroy();
        }

        let config = {
            maxItems: null,
            plugins: {
                checkbox_options: true,
                // remove_button: true,
                input_autogrow: true,
                dropdown_header: {
                    title: "--Choose--",
                },
            },
            create: false,
            persist: false,
            openOnFocus: true,
            hideSelected: true,
            // placeholder: "--All--",
            valueField: "id",
            labelField: "title",
            // searchField: "title",
            options: data,
        };

        // Initialize tom-select instance
        selectizeInstance = new TomSelect("#" + select_id, config);
    }
}

export default initializeMultipleSelect;
