import Request from "@/Request";

export function uploadFilesToGallery(payload) {
  return Request({
    url: "/galleries",
    method: "post",
    data: payload,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

export function galleryList(payload) {
  return Request({
    url: "/galleries",
    method: "get",
    params: payload,
  });
}
