
export default [
        {
            title: "Dashboard",
            url: "/dashboard",
            icon: "icon-home",
            has_sub_menu: false,
            permission:"dashboard"
        },
        {
            title: "Forms",
            url: "forms",
            icon: "icon-user-check",
            has_sub_menu: true,
            open: false,
            permission:"forms-view",

            sub_menus: [
                // {
                //     title: "FORM EC 16C (Annual)",
                //     url: "annual",
                //     icon: null,
                //     has_sub_menu: true,
                //     open: false,

                //     sub_menus: [
                //         {
                //             title: "View List",
                //             url: "/",
                //             icon: null,
                //             has_sub_menu: false,
                //         },
                //         {
                //             title: "Add New",
                //             url: "/",
                //             icon: null,
                //             has_sub_menu: false,
                //         }
                //     ]
                // },
                {
                    title: "FRM EC16D (Income)",
                    url: "income",
                    icon: null,
                    has_sub_menu: true,
                    open: false,
                    permission:"income-view",

                    sub_menus: [
                        {
                            title: "Submit New Report",
                            url: "/forms/home/reporting",
                            icon: null,
                            has_sub_menu: false,
                            permission:"income-create",
                        },
                        {
                            title: "View Submitted Reports",
                            url: "/forms/reporting",
                            icon: null,
                            has_sub_menu: false,
                            permission:"income-view",
                        }
                    ]
                },
                {
                    title: "FRM EC16E (Expenditure)",
                    url: "expenditures",
                    icon: null,
                    has_sub_menu: true,
                    open: false,
                    permission:"expenses-view",

                    sub_menus: [
                        {
                            title: "Submit New Report",
                            url: "/forms/home/expenditures",
                            icon: null,
                            has_sub_menu: false,
                            permission:"expenses-create",
                        },
                        {
                            title: "View Submitted Reports",
                            url: "/forms/expenditures",
                            icon: null,
                            has_sub_menu: false,
                            permission:"expenses-view",
                        }
                    ]
                },
                {
                    title: "FRM EC16F (Tracking)",
                    url: "tracking",
                    icon: null,
                    has_sub_menu: true,
                    open: false,
                    permission:"tracking-view",

                    sub_menus: [
                        {
                            title: "Submit New Report",
                            url: "/forms/add/tracking",
                            icon: null,
                            has_sub_menu: false,
                            permission:"tracking-create",
                        },
                        {
                            title: "View Submitted",
                            url: "/forms/tracking",
                            icon: null,
                            has_sub_menu: false,
                            permission:"tracking-view",
                        }
                    ]
                },
            ]
        },
        
        {
            title: "Reports",
            url: "#!",
            icon: "icon-life-buoy",
            has_sub_menu: true,
            open: false,
            permission:"reports-view",

            sub_menus: [
                {
                    title: "Income Report",
                    url: "/forms/reporting",
                    icon: null,
                    has_sub_menu: false,
                    permission:"staff-nation-wide",
                },
                {
                    title: "Expenses Report",
                    url: "/forms/expenditures",
                    icon: null,
                    has_sub_menu: false,
                    permission:"staff-nation-wide",
                },
                {
                    title: "Income & Expenses Report",
                    url: "/reports/income-expenses",
                    icon: null,
                    has_sub_menu: false,
                    permission:"income-expenses-report-view",
                },
                
                // {
                //     title: "FORM EC16E (Exp. Reports)",
                //     url: "/reports/expenditure",
                //     icon: null,
                //     has_sub_menu: false,
                //     permission:"party_roles",
                // },
                // {
                //     title: "FORM EC 16F Reports",
                //     url: "/",
                //     icon: null,
                //     has_sub_menu: false,
                // },
            ]
        },
        {
            title: "Analytics",
            url: "/analytics/tracking",
            icon: "icon-book",
            has_sub_menu: false,
            // open: false,
            permission:"analytics-view",

            sub_menus: [
                // {
                //     title: "FORM EC16D",
                //     url: "/",
                //     icon: null,
                //     has_sub_menu: false,
                // },
                // {
                //     title: "FORM EC16E",
                //     url: "/analytics/advertExpenses",
                //     icon: null,
                //     has_sub_menu: false,
                // },
                {
                    title: "Report Analysis",
                    url: "/analytics/tracking",
                    icon: null,
                    has_sub_menu: false,
                    permission:"analytics-view",
                },
            ]
        },
        {
            title: "Settings",
            url: "settings",
            icon: "icon-lock",
            has_sub_menu: true,
            open: false,
            permission:"settings-view",

            sub_menus: [
                {
                    title: "System Users",
                    url: "/settings/users",
                    icon: null,
                    has_sub_menu: false,
                    permission:"users-view",
                },
                {
                    title: "Political Parties",
                    url: "/settings/parties",
                    icon: null,
                    has_sub_menu: false,
                    permission:"parties-view",
                },
                {
                    title: "Elective Positions",
                    url: "/settings/positions",
                    icon: null,
                    has_sub_menu: false,
                    permission:"positions-view",
                },
                {
                    title: "Media Channels",
                    url: "/settings/channels",
                    icon: null,
                    has_sub_menu: false,
                    permission:"media-view",
                },
                {
                    title: "Zones",
                    url: "/settings/zones",
                    icon: null,
                    has_sub_menu: false,
                    permission:"zones-view",
                },
                {
                    title: "States",
                    url: "/settings/states",
                    icon: null,
                    has_sub_menu: false,
                    permission:"states-view",
                },
                {
                    title: "Senatorial Zones",
                    url: "/settings/senatorial",
                    icon: null,
                    has_sub_menu: false,
                    permission:"zones-view",
                },
                {
                    title: "Federal Constituencies",
                    url: "/settings/constituencies",
                    icon: null,
                    has_sub_menu: false,
                    permission:"constituencies-view",
                },
                {
                    title: "State Constituencies",
                    url: "/settings/state-constituencies",
                    icon: null,
                    has_sub_menu: false,
                    permission:"constituencies-view",
                },
                {
                    title: "Report Submission",
                    url: "/settings/report-submission",
                    icon: null,
                    has_sub_menu: false,
                    permission:"report-submission-view",
                },
                {
                    title: "Supporting Documents",
                    url: "/settings/supporting-documents",
                    icon: null,
                    has_sub_menu: false,
                    permission:"supporting-document-view",
                },
                // {
                //     title: "L.G.A",
                //     url: "/settings/lgas",
                //     icon: null,
                //     has_sub_menu: false,
                // },
                
            ]
        },
        // {
        //     title: "Audit Trail",
        //     url: "/",
        //     icon: "icon-home",
        //     has_sub_menu: false,
        // },
        {
            title: "Gallery",
            url: "/gallery",
            icon: "icon-image",
            has_sub_menu: false,
            permission:"gallery-view"
        },
        {
            title: "Downloads",
            url: "/download-center",
            icon: "icon-sunset",
            has_sub_menu: false,
            permission:"downloads-view",
        },
        // {
        //     title: "Support",
        //     url: "/support",
        //     icon: "icon-sunset",
        //     has_sub_menu: false,
        //     permission:"roles",
        // },
        {
            title: "Log Out",
            url: "/logout",
            icon: "icon-lock",
            has_sub_menu: false,
            permission:"dashboard",
        },
];

