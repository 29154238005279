<template>
	<div
		v-if="toggle_modal == 'show'"
		class="modal fade show"
		tabindex="-1"
		role="dialog"
		aria-labelledby="modal"
		aria-modal="true"
		style="
			display: block;
			background-color: rgba(0, 0, 0, 0.5);
			transition: opacity 0.2s ease;
		"
	>
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">{{ title }}</h5>
					<button
						@click.prevent="toggleModal('hide')"
						type="button"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
				</div>
				<div class="modal-body">
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from "@/utils/event_bus.js";

export default {
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			toggle_modal: "hide",
		};
	},

	methods: {
		toggleModal(type) {
			EventBus.$emit("toggle-modal", type);
		},
	},

	mounted() {
		EventBus.$on("toggle-modal", (type) => {
			this.toggle_modal = type;
		});
	},
};
</script>
