<template>
	<form @submit.prevent="saveAll()">
		<Form @set-input="setInput" />

		<fieldset class="dt-responsive table-responsive">
			<legend>BILLBOARD ADVERTISEMENTS:</legend>
			<table id="dt-live-dom" class="table table-striped table-bordered nowrap">
				<thead>
					<tr>
						<!-- <th>S/NO</th> -->
						<th style="width: 15%">GENDER</th>
						<th style="width: 25%">LOCATION OF ADVERTISEMENT</th>
						<th style="width: 20%">DESCRIPTION</th>
						<th style="width: 10%">QUANTITY</th>
						<th style="width: 10%">UNIT COST</th>
						<th style="width: 10%">TOTAL COST</th>
						<th style="width: 25%">REMARKS</th>
						<!-- <th></th> -->
					</tr>
				</thead>
				<tbody>
					<tr v-for="(form, index) in forms" :key="form.id">
						<td>
							<a
								@click.prevent="deleteForm(form.id, index)"
								href="#"
								class="btn btn-danger btn-sm"
								><i class="feather icon-trash-2"></i>
							</a>
							{{ form.gender }}
						</td>
						<td>{{ form.location }}</td>
						<td>{{ form.description }}</td>
						<td>{{ form.quantity }}</td>
						<td>{{ form.unit_cost }}</td>
						<td>{{ form.total_cost }}</td>
						<td>{{ form.remarks }}</td>
					</tr>
					<tr>
						<!-- <td>1</td> -->
						<td>
							<select
								v-model="form.gender"
								class="form-select"
								id="gender"
								required
							>
								<option value="">--select--</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
							</select>
						</td>
						<td>
							<textarea
								v-model="form.location"
								class="form-control"
								id="location"
								name="location"
								rows="1"
								placeholder="Enter location"
								required
							></textarea>
						</td>
						<td>
							<textarea
								v-model="form.description"
								class="form-control"
								id="description"
								name="description"
								rows="1"
								placeholder="Enter description"
							></textarea>
						</td>
						<td>
							<input
								@input="inputMustBeUnsignedInteger('quantity')"
								@change="calculateTotalCost()"
								v-model="form.quantity"
								class="form-control"
								type="text"
								id="quantity"
								name="quantity"
								placeholder="Eg. 10"
								required
							/>
						</td>
						<td>
							<input
								@input="inputMustBeCurrency('unit_cost')"
								@change="calculateTotalCost()"
								v-model="form.unit_cost"
								class="form-control"
								type="text"
								id="unit_cost"
								name="unit_cost"
								placeholder="Eg. 10000"
								required
							/>
						</td>
						<td>
							<textarea
								v-model="form.total_cost"
								class="form-control"
								id="total_cost"
								name="total_cost"
								placeholder="Eg. 100,000"
								rows="1"
								required
								readonly
							>
							</textarea>
						</td>
						<td>
							<textarea
								v-model="form.remarks"
								class="form-control"
								type="text"
								id="remarks"
								name="remarks"
								rows="1"
								placeholder="Enter remarks"
							></textarea>
						</td>
					</tr>
				</tbody>
			</table>
		</fieldset>
		<div align="center" style="margin-bottom: 0; margin-top: 2rem">
			<LoadingButton class="btn btn-info">Save All</LoadingButton>
		</div>
	</form>
</template>
<script>
import { mustBeUnsignedInteger, mustBeCurrency } from "@/utils/input_filter.js";
import { EventBus } from "@/utils/event_bus.js";
import LoadingButton from "@/components/LoadingButton.vue";
import { mapActions } from "vuex";
import Form from "@/components/Form.vue";

export default {
	props: {
		candidate_name: {
			type: String,
			// required: true,
		},
		advert_type_id: {
			type: String,
			required: true,
		},
	},
	components: {
		Form,
		LoadingButton,
	},
	data() {
		return {
			forms: [],
			form: {
				gender: "",
				location: "",
				description: "",
				quantity: "",
				unit_cost: "",
				total_cost: 0,
				remarks: "",
				party_id: "",
				state_code: "",
				elective_position_id: "",
				lga_id: "",
				date_reported: "",
				constituency_id: "",
				advert_type_id: this.advert_type_id ?? "", // Billboard ID
				candidate_name: this.candidate_name ?? "",
			},
		};
	},
	methods: {
		...mapActions({
			addBillBoardForm: "form_ec16f/addBillBoardForm",
			deleteById: "form_ec16f/deleteById",
		}),

		async saveAll() {
			await this.addBillBoardForm(this.form).then((response) => {
				if (response) {
					this.forms.push(response);
					this.clearFormInputs();
				}
			});
		},

		clearFormInputs() {
			this.form.gender = "";
			this.form.location = "";
			this.form.description = "";
			this.form.quantity = "";
			this.form.unit_cost = "";
			this.form.total_cost = 0;
			this.form.remarks = "";
		},

		calculateTotalCost() {
			if (this.form.quantity === "") {
				this.form.quantity = 1;
			}

			let total =
				parseInt(this.form.quantity) * parseFloat(this.form.unit_cost);
			this.form.total_cost = isNaN(total) ? 0 : total;
		},

		inputMustBeUnsignedInteger(input_id) {
			return mustBeUnsignedInteger(input_id);
		},

		inputMustBeCurrency(input_id) {
			return mustBeCurrency(input_id);
		},

		async deleteForm(form_id, index) {
			if (!confirm("Are you sure you want to delete?")) {
				return;
			}

			this.deleteById({ id: form_id }).then((response) => {
				if (response === "success") {
					this.forms.splice(index, 1);
				}
			});
		},

		setInput(params) {
			this.form[params.name] = params.value;
		},
	},
	created() {
		EventBus.$on("set-params", this.setInput);
	},
};
</script>

<style scoped>
fieldset {
	background-color: #eeeeee;
}

legend {
	background-color: #29623c;
	color: white;
	padding: 5px 10px;
}
.btn-info {
	width: 200px;
	border-radius: 6px;
}
</style>
