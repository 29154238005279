<template>
	<table style="display: none" id="simple-data-table">
		<thead>
			<tr>
				<th v-for="head in response.tbl_heads.chart_heads" :key="head">
					{{ head }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(expense, val, key) in response.expenses" :key="key">
				<td>{{ val }}</td>

				<template v-for="(head, index) in response.tbl_heads.chart_heads">
					<td v-if="index > 0" :key="'p' + index">
						{{ expense[head] ? expense[head]["total_cost"] : 0 }}
					</td>
				</template>
			</tr>
		</tbody>
	</table>
</template>
<script>
export default {
	props: {
		response: {
			required: true,
			type: Object,
		},
	},
};
</script>
