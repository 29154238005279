<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-md-12">
			<div class="card">
				<div class="card-body">
					<!-- search form -->
					<SearchForm @search="getForms"></SearchForm>

					<div class="table-responsive">
						<table
							id="data-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<!-- <th>S/N</th> -->
									<th>Political <br />Party</th>
									<th>Type of Election</th>
									<th>State</th>
									<th>Donor Name</th>
									<th>Donor Address</th>
									<th>donor Occupation</th>
									<th>
										Type of <br />
										Contribution
									</th>
									<th>
										Description of <br />
										Contribution
									</th>
									<th>Contribution <br />Value (₦)</th>
									<th>Date Received</th>
									<th>
										Supporting <br />
										Documents
									</th>
									<th>Approved BY</th>
									<th>Submitted BY</th>
									<th v-if="user_is_party">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="form in forms.income" :key="'fm_' + form.id">
									<td>{{ form.party ? form.party : null }}</td>
									<td>
										<span class="badge bg-primary">{{
											form.position ? form.position : null
										}}</span>
									</td>
									<!-- <td>{{ form.candidate }}</td> -->
									<td>{{ form.state ? form.state : null }}</td>
									<td>{{ form.donor_name }}</td>
									<td>{{ form.donor_address }}</td>
									<td>{{ form.donor_occupation }}</td>
									<td>{{ form.contribution_type }}</td>
									<td>{{ form.contribution_description }}</td>
									<td>
										<span class="badge bg-danger">{{
											form.contribution_value
										}}</span>
									</td>
									<td>{{ form.date_received }}</td>
									<td>
										<template v-if="form.documents">
											<div v-for="doc in form.documents" :key="'doc_' + doc.id">
												<span class="badge bg-info">
													{{ doc.title }}
												</span>
											</div>
										</template>
									</td>
									<td class="text-center">{{ form.approved_by }}</td>
									<td class="text-center">
										{{ form.user ? form.user : null }}
									</td>
									<td v-if="user_is_party">
										<template v-if="form.allow_edit">
											<template v-if="form.is_owner">
												<a
													@click.prevent="setReport(form)"
													href="#!"
													class="btn btn-info btn-sm"
													><i class="feather icon-edit"></i>&nbsp;Edit
												</a>
											</template>
											<template v-else>
												<strong class="text-muted text-link"
													>Edit Disabled</strong
												>
											</template>
										</template>

										<strong v-else class="text-danger text-link">CLOSED</strong>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- customar project  end -->

		<ReportingEditModal :report="report" />

		<template
			v-if="forms && Object.keys(forms).length && forms.paginator.has_pages"
		>
			<Paginator :paginator="forms.paginator" @goto-page="getForms" />
		</template>
	</div>
</template>
<script>
import SearchForm from "@/components/SearchForm";
import Paginator from "@/components/Paginator";
import { mapGetters, mapActions } from "vuex";
import Datatable from "@/utils/datatable.js";
import { EventBus } from "@/utils/event_bus.js";
import ReportingEditModal from "./ReportingEditModal.vue";
import { userIsParty } from "@/utils/permissions";

export default {
	name: "ReportingList",
	components: {
		SearchForm,
		Paginator,
		ReportingEditModal,
	},
	data() {
		return {
			report: null,
			params: {},
		};
	},
	computed: {
		...mapGetters({
			forms: "form_ec16d/listForms",
		}),
		user_is_party() {
			return userIsParty();
		},
	},
	methods: {
		...mapActions({
			getFormList: "form_ec16d/getFormList",
		}),

		async getForms(type = null) {
			if (type) {
				if (typeof type === "object") {
					this.params.q = JSON.stringify(type); // Object containing the searchable fields
					this.params.page = null; // Reset page no
				}

				if (typeof type === "string") {
					this.params.page =
						type === "next"
							? parseInt(this.forms.paginator.current_page) + 1
							: parseInt(this.forms.paginator.current_page) - 1;
				}
			}

			await this.getFormList(this.params).then(() => Datatable("#data-table"));
		},

		setReport(report) {
			this.report = report;
			return EventBus.$emit("toggle-modal", "show");
		},
	},
	mounted() {
		this.getForms();
	},
};
</script>
