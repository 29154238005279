<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<!-- search form -->
					<SearchForm @search="getForms">
						<div class="col-sm-2">
							<div class="form-group text-center">
								<label class="form-label" for="advert_type_id"
									>Advert Type</label
								>
								<select
									v-model="params.advert_type_id"
									name="advert_type_id"
									class="form-select"
									id="advert_type_id"
									required
								>
									<option value="">--select--</option>
									<option
										v-for="advert in advert_types"
										:value="advert.id"
										:key="'ad' + advert.id"
									>
										{{ advert.name }}
									</option>
								</select>
							</div>
						</div>
					</SearchForm>

					<div v-if="Object.keys(forms).length <= 0" class="alert alert-danger">
						<strong
							>Please choose atleast an advert type and click on submit!</strong
						>
					</div>

					<!-- Contents Here -->
					<div v-if="forms && Object.keys(forms).length">
						<AdvertBillboardList
							v-if="params.advert_type_id == 1"
							:forms="forms.tracking"
						/>

						<AdvertElectronicList
							v-if="params.advert_type_id == 2"
							:forms="forms.tracking"
						/>

						<AdvertPrintList
							v-if="params.advert_type_id == 3"
							:forms="forms.tracking"
						/>

						<AdvertCoverageList
							v-if="params.advert_type_id == 4"
							:forms="forms.tracking"
						/>

						<AdvertCampaignList
							v-show="params.advert_type_id == 5"
							:forms="forms.tracking"
						/>
					</div>
				</div>
			</div>
		</div>
		<!-- customar project  end -->

		<template
			v-if="forms && Object.keys(forms).length && forms.paginator.has_pages"
		>
			<Paginator :paginator="forms.paginator" @goto-page="getForms" />
		</template>
	</div>
</template>
<script>
import Datatable from "@/utils/datatable.js";
import { mapGetters, mapActions } from "vuex";
import SearchForm from "@/components/SearchForm";
import Paginator from "@/components/Paginator";
import AdvertBillboardList from "./Partials/Lists/AdvertBillboardList.vue";
import AdvertCampaignList from "./Partials/Lists/AdvertCampaignList.vue";
import AdvertCoverageList from "./Partials/Lists/AdvertCoverageList.vue";
import AdvertElectronicList from "./Partials/Lists/AdvertElectronicList.vue";
import AdvertPrintList from "./Partials/Lists/AdvertPrintList.vue";

export default {
	name: "Tracking",
	components: {
		Paginator,
		SearchForm,
		AdvertBillboardList,
		AdvertCampaignList,
		AdvertCoverageList,
		AdvertElectronicList,
		AdvertPrintList,
	},
	data() {
		return {
			params: {
				advert_type_id: "",
			},
		};
	},
	watch: {
		"params.advert_type_id": function () {
			this.$store.commit("form_ec16f/setForms", []);
		},
	},
	computed: {
		...mapGetters({
			advert_types: "settings/advert_types",
			forms: "form_ec16f/listForms",
		}),
	},
	methods: {
		...mapActions({
			getFormList: "form_ec16f/getFormList",
		}),

		async getForms(type = null) {
			if (type) {
				if (typeof type === "object") {
					this.params.q = JSON.stringify(type); // Object containing the searchable fields
					this.params.page = null; // Reset page no
				}

				if (typeof type === "string") {
					this.params.page =
						type === "next"
							? parseInt(this.forms.paginator.current_page) + 1
							: parseInt(this.forms.paginator.current_page) - 1;
				}
			}

			await this.getFormList(this.params).then(() => Datatable("#data-table"));
		},
	},
	mounted() {
		if (!this.advert_types.length) {
			this.$store.dispatch("settings/getAdvertTypes");
		}
	},
};
</script>
