<template>
	<div
		v-if="toggle_modal == 'show'"
		class="modal fade show"
		tabindex="-1"
		role="dialog"
		aria-labelledby="modal"
		aria-modal="true"
		style="
			display: block;
			background-color: rgb(1 1 1 / 86%);
			transition: opacity 0.2s ease;
		"
	>
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-header">
					<h5 class="modal-title">Update Your Password</h5>
					<button
						@click.prevent="hideModal()"
						type="button"
						class="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
				</div>
				<div class="modal-body">
					<form @submit.prevent="updatePassword()" action="" method="post">
						<div class="">
							<Alert></Alert>

							<div class="form-group">
								<label class="control-label">Current Password </label>
								<input
									v-model="form.current_password"
									type="password"
									class="form-control"
									required
								/>
							</div>
							<div class="form-group">
								<label class="control-label">New Password </label>
								<input
									v-model="form.password"
									type="password"
									class="form-control"
									required
								/>
							</div>
							<div class="form-group">
								<label class="control-label">Confirm New Password </label>
								<input
									v-model="form.password_confirmation"
									type="password"
									class="form-control"
									required
								/>
							</div>
						</div>
						<div align="right">
							<button
								@click.prevent="hideModal()"
								class="btn btn-danger"
								style="margin-right: 5px"
							>
								Skip
							</button>
							<LoadingButton class="btn-info">Update</LoadingButton>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Alert from "@/components/Alert.vue";
import { EventBus } from "@/utils/event_bus.js";
import LoadingButton from "@/components/LoadingButton.vue";

export default {
	components: {
		Alert,
		LoadingButton,
	},
	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			form: {
				password_confirmation: null,
				current_password: null,
				password: null,
				// id: this.user.id,
			},
			toggle_modal: "hide",
		};
	},
	methods: {
		async updatePassword() {
			this.form.id = this.user.id;
			await this.$store
				.dispatch("settings/updateUserPassword", this.form)
				.then((response) => {
					if (response === "success") {
						this.hideModal();
					}
				});
		},

		hideModal() {
			EventBus.$emit("toggle-password-modal", "hide");
		},
	},
	mounted() {
		EventBus.$on("toggle-password-modal", (type) => {
			this.toggle_modal = type;
		});
	},
};
</script>
<style scoped>
.form-control {
	padding: 4px;
}
</style>
