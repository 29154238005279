<template>
	<div class="row">
		<div class="col-md-6">
			<div v-if="user_is_staff" class="row mb-3">
				<label for="party_id" class="col-sm-4 col-form-label"
					>Name of Political Party<span class="text-danger">*</span></label
				>
				<div class="col-sm-8">
					<select
						@change="emitInput($event)"
						class="form-select"
						id="party_id"
						required
					>
						<option value="">--select--</option>
						<option
							v-for="party in parties"
							:value="party.id"
							:key="'p' + party.id"
						>
							{{ party.abbreviation }}
						</option>
					</select>
				</div>
			</div>

			<div class="row mb-3">
				<label for="date_reported" class="col-sm-4 col-form-label"
					>Reporting Date <span class="text-danger">*</span></label
				>
				<div class="col-sm-8">
					<input
						@change="emitInput($event)"
						type="date"
						id="date_reported"
						class="form-control date"
						data-mask="99/99/9999"
						required
					/>
				</div>
			</div>

			<div v-if="constituencies.length" class="row mb-3">
				<label for="constituency_id" class="col-sm-4 col-form-label"
					>Fed. Constituency:</label
				>
				<div class="col-sm-8">
					<select
						@change="emitInput($event)"
						class="form-select"
						id="constituency_id"
					>
						<option value="">--select--</option>
						<option
							v-for="constituency in constituencies"
							:value="constituency.id"
							:key="'c' + constituency.id"
						>
							{{ constituency.name + " (" + constituency.code + ")" }}
						</option>
					</select>
				</div>
			</div>

			<div v-if="state_constituencies.length" class="row mb-3">
				<label for="state_constituency_id" class="col-sm-4 col-form-label"
					>State Constituency:</label
				>
				<div class="col-sm-8">
					<select
						@change="emitInput($event)"
						class="form-select"
						id="state_constituency_id"
					>
						<option value="">--select--</option>
						<option
							v-for="constituency in state_constituencies"
							:value="constituency.id"
							:key="'sc_' + constituency.id"
						>
							{{ constituency.name + " (" + constituency.code + ")" }}
						</option>
					</select>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="row mb-3">
				<label for="elective_position_id" class="col-sm-4 col-form-label"
					>Type of Election <span class="text-danger">*</span></label
				>
				<div class="col-sm-8">
					<select
						@change="emitInput($event)"
						class="form-select"
						id="elective_position_id"
						required
					>
						<option value="">--select--</option>
						<option
							v-for="position in positions"
							:value="position.id"
							:key="'ep' + position.id"
						>
							{{ position.name }}
						</option>
					</select>
				</div>
			</div>
			<div v-if="user_is_staff_admin" class="row mb-3">
				<label for="state_code" class="col-sm-4 col-form-label"
					>State <span class="text-danger">*</span></label
				>
				<div class="col-sm-8">
					<select
						@change="getByState($event)"
						class="form-select"
						id="state_code"
						required
					>
						<option value="">--select--</option>
						<option
							v-for="state in states"
							:value="state.code"
							:key="'s' + state.id"
						>
							{{ state.name }}
						</option>
					</select>
				</div>
			</div>
			<div v-if="senatorial_zones.length" class="row mb-3">
				<label for="sd_id" class="col-sm-4 col-form-label"
					>Senatorial District
				</label>
				<div class="col-sm-8">
					<select @change="emitInput($event)" class="form-select" id="sd_id">
						<option value="">--select--</option>
						<option
							v-for="sd in senatorial_zones"
							:value="sd.id"
							:key="'sd_' + sd.id"
						>
							{{ sd.name }}
						</option>
					</select>
				</div>
			</div>
			<div v-if="lgas.length" class="row mb-3">
				<label for="lga_id" class="col-sm-4 col-form-label">L.G.A </label>
				<div class="col-sm-8">
					<select @change="emitInput($event)" class="form-select" id="lga_id">
						<option value="">--select--</option>
						<option v-for="lga in lgas" :value="lga.id" :key="'lg' + lga.id">
							{{ lga.name }}
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// import { EventBus } from "@/utils/event_bus.js";
import { mapGetters, mapActions } from "vuex";
import {
	userIsStaffAdmin,
	userIsStaff,
	userIsParty,
} from "@/utils/permissions";

export default {
	computed: {
		...mapGetters({
			lgas: "settings/lgas",
			states: "settings/states",
			parties: "settings/parties",
			constituencies: "settings/federal_constituencies",
			state_constituencies: "settings/state_constituencies",
			senatorial_zones: "settings/senatorial_zones",
			positions: "settings/elective_positions",
		}),
		user_is_staff() {
			return userIsStaff();
		},
		user_is_staff_admin() {
			return userIsStaffAdmin();
		},
		user_is_party() {
			return userIsParty();
		},
	},

	methods: {
		...mapActions({
			getFederalConstituencies: "settings/getFederalConstituencies",
			getSenatorialZones: "settings/getSenatorialZones",
			getStateConstituencies: "settings/getStateConstituencies",
			getParties: "settings/getParties",
			getElectivePositions: "settings/getElectivePositions",
			getStates: "settings/getStates",
			getLgas: "settings/getLgas",
			getLocationsByState: "settings/getLocationsByState",
		}),

		listParties() {
			let params = { keys: JSON.stringify(["id", "abbreviation"]) };
			this.getParties(params);
		},
		listStates() {
			let params = { keys: JSON.stringify(["id", "name", "code"]) };
			this.getStates(params);
		},
		listFederalConstituencies() {
			let params = { keys: JSON.stringify(["id", "name"]) };
			this.getFederalConstituencies(params);
		},
		async getByState(e) {
			let params = { state_code: e.target.value };
			this.emitInput(e);

			await this.getLocationsByState(params);
		},
		clearInputs() {
			if (this.lgas.length) {
				this.$store.commit("settings/setLgas", []);
			}

			if (this.constituencies.length) {
				this.$store.commit("settings/setFederalConstituencies", []);
			}

			if (this.state_constituencies.length) {
				this.$store.commit("settings/setStateConstituencies", []);
			}

			if (this.senatorial_zones.length) {
				this.$store.commit("settings/setSenatorialZones", []);
			}
		},
		emitInput(event) {
			let params = {
				name: event.target.id,
				value: event.target.value,
			};
			this.$emit("set-input", params);
		},

		getUserDetails() {
			let event = {
				target: {
					id: "state_code",
					value: this.user_state_code,
				},
			};

			this.getByState(event);
			this.emitInput(event);
		},

		setPoliticalParty() {
			let params = {
				name: "party_id",
				value: this.user_party_id,
			};
			this.$emit("set-input", params);
		},
	},

	created() {
		this.clearInputs();
	},

	mounted() {
		if (!this.parties.length) {
			this.listParties();
		}

		if (!this.states.length || this.states.length < 36) {
			this.listStates();
		}

		// if (!this.constituencies.length || this.constituencies.length < 360) {
		// 	this.listFederalConstituencies();
		// }

		if (!this.positions.length) {
			this.getElectivePositions();
		}

		if (!this.user_is_staff_admin) {
			this.getUserDetails();
		}

		if (this.user_is_party) {
			this.setPoliticalParty();
		}
	},
};
</script>
