<template>
	<div class="table-responsive">
		<fieldset class="dt-responsive table-responsive">
			<legend>BILLBOARD ADVERTISEMENTS:</legend>
			<table id="data-table" class="table table-striped table-bordered nowrap">
				<thead>
					<tr>
						<th>PARTY</th>
						<th>POSITION</th>
						<th>CANDIDATE</th>
						<th>GENDER</th>
						<th>STATE</th>
						<th>
							LOCATION OF <br />
							ADVERTISEMENT
						</th>
						<th>DESCRIPTION</th>
						<th>QUANTITY</th>
						<th>UNIT COST</th>
						<th>TOTAL COST</th>
						<th>REMARKS</th>
						<th>RECORDED BY</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="form in forms" :key="form.id">
						<td>{{ form.party ? form.party.abbreviation : null }}</td>
						<td>
							<span class="badge bg-primary">{{
								form.position ? form.position.name : null
							}}</span>
						</td>
						<td>{{ form.candidate }}</td>
						<td>{{ form.gender }}</td>
						<td>{{ form.state ? form.state.name : null }}</td>
						<td>{{ form.location }}</td>
						<td>{{ form.description }}</td>
						<td>{{ form.quantity }}</td>
						<td>{{ form.unit_cost }}</td>
						<td>
							<span class="badge bg-danger">{{ form.total_cost }}</span>
						</td>
						<td>{{ form.remarks }}</td>
						<td>{{ form.user ? form.user.name : null }}</td>
					</tr>
				</tbody>
			</table>
		</fieldset>
	</div>
</template>
<script>
export default {
	props: {
		forms: {
			type: Array,
			required: true,
		},
	},
};
</script>
<style scoped>
fieldset {
	background-color: #eeeeee;
}

legend {
	background-color: #29623c;
	color: #fff;
	padding: 5px 10px;
}
</style>
