<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table
							id="report-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<th>S/N</th>
									<th>State</th>
									<th>State Code</th>
									<th>Zone</th>
									<!-- <th>Actions</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="state in states" :key="state.code">
									<td>{{ state.id }}.</td>
									<td>{{ state.name }}</td>
									<td>{{ state.code }}</td>
									<td>
										<span class="badge bg-primary">
											{{ state.zone ? state.zone.name : null }}
										</span>
									</td>
									<!-- <td>
										<a href="#!" class="btn btn-info btn-sm"
											><i class="feather icon-edit"></i>&nbsp;Edit
										</a>
										<a href="#!" class="btn btn-danger btn-sm"
											><i class="feather icon-trash-2"></i>&nbsp;Delete
										</a>
									</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- customar project  end -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters({
			states: "settings/states",
		}),
	},

	mounted() {
		this.$store.dispatch("settings/getStates");
		// if (!this.states.length || this.states.length < 36) {
		// 	this.$store.dispatch("settings/getStates");
		// }
	},
};
</script>
