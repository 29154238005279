// Layouts
import AppLayout from "@/views/Layouts/AppLayout.vue";
import AuthLayout from "@/views/Layouts/AuthLayout.vue";

// Dashboard views
import DashBoard from "@/views/DashBoard/Home.vue";

// // views
import Login from "@/views/Auth/Login.vue";
import ForgotPassword from "@/views/Auth/ForgotPassword.vue";
import ResetPassword from "@/views/Auth/ResetPassword.vue";

// Gallery
import GalleryList from "@/views/Gallery/GalleryList.vue";

// Profile
import Profile from "@/views/Profile/Profile.vue";

// Download Center
import DownloadIndex from "@/views/Downloads/DownloadIndex.vue";

// Forms layout
import FormsLayout from "@/views/Forms/FormsLayout.vue";

// Expenditure Forms
import ExpenditureList from "@/views/Forms/Expenditure/ExpenditureList.vue";
import ExpenditureIndex from "@/views/Forms/Expenditure/ExpenditureIndex.vue";
import ExpenditureAdd from "@/views/Forms/Expenditure/ExpenditureAdd.vue";
import ExpenditureView from "@/views/Forms/Expenditure/ExpenditureView.vue";

// Tracking Forms
import TrackingList from "@/views/Forms/Tracking/TrackingList.vue";
import TrackingAdd from "@/views/Forms/Tracking/TrackingAdd.vue";

// Reporting Forms
import ReportingIndex from "@/views/Forms/Reporting/ReportingIndex.vue";
import ReportingList from "@/views/Forms/Reporting/ReportingList.vue";
import ReportingAdd from "@/views/Forms/Reporting/ReportingAdd.vue";

// ReportS
import ReportsLayout from "@/views/Reports/ReportsLayout.vue";
import IncomeExpenses from "@/views/Reports/IncomeExpenses.vue";
// import ReportingAdd from "@/views/Forms/Reporting/ReportingAdd.vue";

// Analytics
import AnalyticsLayout from "@/views/Analytics/AnalyticsLayout.vue";
import TrackingAnalytics from "@/views/Analytics/TrackingAnalytics.vue";

// Settings
import LgaList from "@/views/Settings/LgaList.vue";
import ZoneList from "@/views/Settings/ZoneList.vue";
import UserList from "@/views/Settings/UserList.vue";
import StateList from "@/views/Settings/StateList.vue";
import PartyList from "@/views/Settings/PartyList.vue";
import SettingsLayout from "@/views/Settings/SettingsLayout.vue";
import ConstituencyList from "@/views/Settings/ConstituencyList.vue";
import MediaChannelList from "@/views/Settings/MediaChannelList.vue";
import ReportSubmission from "@/views/Settings/ReportSubmission.vue";
import SupportingDocument from "@/views/Settings/SupportingDocument.vue";
import SenatorialZoneList from "@/views/Settings/SenatorialZoneList.vue";
import ElectivePositionList from "@/views/Settings/ElectivePositionList.vue";
import StateConstituencyList from "@/views/Settings/StateConstituencyList.vue";

let authviews = {
  path: "/auth",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: ForgotPassword,
    },
    {
      path: "/reset-password",
      name: "ResetPassword",
      component: ResetPassword,
    },
  ],
};

let gallery = {
    path: "/gallery",
    name: "GalleryList",
    component: GalleryList,
};

let profile = {
    path: "/profile",
    name: "Profile",
    component: Profile,
};

let downloads = {
    path: "/download-center",
    name: "DownloadIndex",
    component: DownloadIndex,
};

let forms = {
  path: "forms",
  component: FormsLayout,
  redirect: "/forms",
  children: [
      {
        path: "expenditures",
        name: "Expenditure List",
        component: ExpenditureList,
      },
      {
        path: "home/expenditures",
        name: "ExpenditureIndex",
        component: ExpenditureIndex,
      },
      {
        path: "add/expenditures",
        name: "Add Expenditures",
        component: ExpenditureAdd,
      },
      {
        path: ":batch_id/expenditures",
        name: "ExpenditureView",
        component: ExpenditureView,
        props:true
      },
      {
        path: "tracking",
        name: "Tracking",
        component: TrackingList,
      },
      {
        path: "add/tracking",
        name: "Add Tracking",
        component: TrackingAdd,
      },
      {
        path: "reporting",
        name: "ReportingList",
        component: ReportingList,
      },
      {
        path: "home/reporting",
        name: "ReportingIndex",
        component: ReportingIndex,
      },
      {
        path: "add/reporting",
        name: "Add Reporting",
        component: ReportingAdd,
      },
  ],
};

let settings = {
  path: "settings",
  component: SettingsLayout,
  name: "SettingsLayout",
  // redirect: "/settings",
  children: [
      {
        path: "users",
        name: "User Settings",
        component: UserList,
      },
      {
        path: "parties",
        name: "Political Parties",
        component: PartyList,
      },
      {
        path: "positions",
        name: "Elective Positions",
        component: ElectivePositionList,
      },
      {
        path: "channels",
        name: "Media Channels",
        component: MediaChannelList,
      },
      {
        path: "zones",
        name: "Political Zones",
        component: ZoneList,
      },
      {
        path: "states",
        name: "States",
        component: StateList,
      },
      {
        path: "senatorial",
        name: "Senatorial Zones",
        component: SenatorialZoneList,
      },
      {
        path: "constituencies",
        name: "Constituencies",
        component: ConstituencyList,
      },
      {
        path: "state-constituencies",
        name: "State Constituencies",
        component: StateConstituencyList,
      },
      {
        path: "report-submission",
        name: "ReportSubmission",
        component: ReportSubmission,
      },
      {
        path: "supporting-documents",
        name: "SupportingDocument",
        component: SupportingDocument,
      },
      {
        path: "lgas",
        name: "L.G.A Settings",
        component: LgaList,
      },
  ],
};


let reports = {
  path: "reports",
  component: ReportsLayout,
  name: "ReportsLayout",
  // redirect: "/income",
  children: [
      {
        path: "income-expenses",
        name: "IncomeExpenses",
        component: IncomeExpenses,
      },
  ],
};

let analytics = {
  path: "analytics",
  component: AnalyticsLayout,
  name: "AnalyticsLayout",
  // redirect: "/advertExpenses",
  children: [
      {
        path: "tracking",
        name: "Tracking Analytics",
        component: TrackingAnalytics,
      },
  ],
};


export const routes = [
  // {
  //   path: "/",
  //   redirect: "/dashboard",
  //   name: "Home",
  // },
  authviews,
  {
    path: "/",
      redirect: "/dashboard",
    component: AppLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        components: { default: DashBoard },
      },
      gallery,
      profile,
      forms,
      settings,
      reports,
      analytics,
      downloads
    ],
  },
];

export default routes;
