<template>
	<div>
		<div>
			
			<div class="row">
				<!-- customar project  start -->
				<div class="col-md-5 mx-auto">
					<div class="card" style="border-color:green;">
						<h4 class="text-center mt-4" >Downloads Centre</h4>
						<div class="card-body">
							<div >
								<a :href="getUrl('income-sample')"  class="card" style="cursor: pointer;">
									<div class="card-body">
										<h5 class="font-weight:700">Download Form EC16D Sample File</h5>
									</div>
								</a>
                                <a :href="getUrl('expenditure-sample')"  class="card" style="cursor: pointer;">
									<div class="card-body">
										<h5 class="font-weight:700">Download Form EC16E Sample File</h5>
									</div>
								</a>
                                <a :href="getUrl('supporting-documents')" class="card" style="cursor: pointer;">
									<div class="card-body">
										<h5 class="font-weight:700">Download Supporting Documents</h5>
									</div>
								</a>
                                <a :href="getUrl('senatorial-zones')" class="card" style="cursor: pointer;">
									<div class="card-body">
										<h5 class="font-weight:700">Download Senatorial Zones</h5>
									</div>
								</a>
                                <a :href="getUrl('federal-constituencies')"  class="card" style="cursor: pointer;">
									<div class="card-body">
										<h5 class="font-weight:700">Download Federal Constituencies</h5>
									</div>
								</a>
                                <a :href="getUrl('state-constituencies')"  class="card" style="cursor: pointer;">
									<div class="card-body">
										<h5 class="font-weight:700">Download State Constituencies</h5>
									</div>
								</a>
                                <a :href="getUrl('parties')" class="card" style="cursor: pointer;">
									<div class="card-body">
										<h5 class="font-weight:700">Download Political Parties</h5>
									</div>
								</a>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	
	</div>
</template>
<script>
export default {
	methods: {
		getUrl(type) {
			const url = process.env.VUE_APP_MODE === "local" ? process.env.VUE_APP_LOCAL_URL : process.env.VUE_APP_PRODUCTION_URL;

			return url.replace("/api", `/download/${type}`);
		}
	},
};
</script>
<style scoped>
h5{
    color:green !important;
}
.card-body{
    padding: 1rem 1.25rem;
}
.card{
    margin-bottom: 10px;
}
</style>
