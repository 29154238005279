import store from "@/store";

function getPermissions() {
  return store.getters["auth/permissions"];
}

function userHasPermission(permission) {
  const permissions = getPermissions();

  return permissions.includes(permission);
}

function userIsPartyAdmin() {
  return getPermissions().includes("party-nation-wide-access");
}

function userIsPartyStateAdmin() {
  return getPermissions().includes("party-state-wide-access");
}

function userIsParty() {
  return getPermissions().includes("party-section-access");
}

function userIsStaffAdmin() {
  return getPermissions().includes("staff-nation-wide-access");
}

function userIsStaffStateAdmin() {
  return getPermissions().includes("staff-state-wide-access");
}

function userIsStaff() {
  return getPermissions().includes("staff-section-access");
}

export { userIsPartyAdmin, userIsPartyStateAdmin, userIsParty, userIsStaffAdmin, userIsStaffStateAdmin, userIsStaff, userHasPermission };
