import { uploadFilesToGallery, galleryList } from "@/api/gallery";
import store from "@/store";

export const uploadFiles = (commit, payload) => {
  return new Promise((resolve) => {
    uploadFilesToGallery(payload).then((response) => {
      if (response.status_code === 201) {
        resolve("success");
      }
    });
  });
};

export const getGalleries = (commit, payload) => {
  return new Promise((resolve) => {
    galleryList(payload).then((response) => {
      if (response.status_code === 200) {
        store.commit("galleries/setGalleries", response.data);
      }
      resolve();
    });
  });
};
