<template>
	<div>
		<div v-if="user_is_party">
			<div v-if="portal_status" class="row">
				<div class="col-md-5 mx-auto">
					<div
						class="alert alert-danger"
						style="padding: 0.5rem 0.7rem; margin-bottom: 0.5rem"
					>
						<strong style="font-weight: 700"
							>{{ report_submission.time_frame }}
						</strong>
					</div>
				</div>
			</div>

			<div class="row">
				<!-- customar project  start -->
				<div class="col-md-5 mx-auto">
					<div class="card" style="border-color: green">
						<h5
							v-if="portal_status"
							class="text-center mt-4"
							style="color: black"
						>
							{{ report_submission.msg }}
						</h5>
						<h4 class="text-center mt-2" style="color: rgb(234, 11, 11)">
							( EXPENDITURE FORM )
						</h4>
						<div class="card-body">
							<div v-if="portal_status">
								<div
									@click="
										$router.push('/forms/add/expenditures').catch(() => {})
									"
									class="card"
									style="cursor: pointer"
								>
									<div class="card-body">
										<div style="display: flex">
											<div>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="76"
													height="96"
													viewBox="0 0 24 24"
												>
													<path
														d="M22 11v10h-20v-10h20zm2-2h-24v14h24v-14zm-18 3h-3v2h3v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm-4 6h-10v2h10v-2zm4-3h-4v2h4v-2zm-14 0h-4v2h4v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm3 0h-2v2h2v-2zm-4.357-9.812c-1.037-.043-2.293-.086-3.049-1.027-.393-.489-.594-1.136-.594-1.847 0-.422.071-.867.217-1.314l1.206.363c-.205.634-.269 1.506.172 2.054.45.56 1.392.512 2.376.584 2.135.156 3.03 1.423 3.03 2.678 0 .461-.121.92-.345 1.321h-1.569c1.016-.804 1.059-2.706-1.444-2.812z"
														fill="green"
													/>
												</svg>
											</div>
											<div style="margin-left: 10px">
												<h5 class="font-weight:700">
													Manually Input Expenditure Report
												</h5>
												<p>
													Please click here to manually input your expenditure
													report according to the guidelines provided by INEC in
													the FORM EC16E (Expenditure Form).
												</p>
											</div>
										</div>
									</div>
								</div>
								<div
									@click.prevent="toggleModal('show')"
									class="card"
									style="cursor: pointer"
								>
									<div class="card-body">
										<div style="display: flex">
											<div>
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="76"
													height="96"
													viewBox="0 0 24 24"
												>
													<path
														d="M16 16h-3v5h-2v-5h-3l4-4 4 4zm3.479-5.908c-.212-3.951-3.473-7.092-7.479-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h3.5v-2h-3.5c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78 3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-3.5v2h3.5c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408z"
														fill="green"
													/>
												</svg>
											</div>
											<div style="margin-left: 10px">
												<h5 class="font-weight:700">
													Upload Bulk Expenditure Report
												</h5>
												<p>
													Please click here to bulk upload your expenditure
													report according to the guidelines provided by INEC in
													the FORM EC16E (Expenditure Form); OR you can download
													the sample sheet by clicking the button below.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div v-else>
								<div class="alert alert-danger">
									<strong class="text-center">{{
										report_submission.msg
									}}</strong>
								</div>
								<h2 class="text-center">OR</h2>
							</div>
							<h5 class="text-center">
								<u>
									<a :href="getUrl()" style="color: black"
										>Click here to download sample expenditure sheet</a
									>
								</u>
							</h5>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="alert alert-danger">
			<strong class="text-center">You're not allowed to view this page!</strong>
		</div>

		<ExpenditureUploadModal />
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { EventBus } from "@/utils/event_bus.js";
import { userIsParty } from "@/utils/permissions";
import ExpenditureUploadModal from "@/views/Forms/Expenditure/ExpenditureUploadModal.vue";

export default {
	name: "ExpenditureIndex",

	components: {
		ExpenditureUploadModal,
	},

	data() {
		return {
			portal_status: false,
		};
	},
	computed: {
		...mapGetters({
			report_submission: "settings/report_submission_status",
		}),
		user_is_party() {
			return userIsParty();
		},
	},
	methods: {
		...mapActions({
			statusReportSubmission: "settings/statusReportSubmission",
		}),

		getPortalStatus() {
			this.statusReportSubmission().then((response) => {
				this.portal_status = response.status;
			});
		},

		toggleModal(type) {
			EventBus.$emit("toggle-modal", type);
		},

		getUrl() {
			const url =
				process.env.VUE_APP_MODE === "local"
					? process.env.VUE_APP_LOCAL_URL
					: process.env.VUE_APP_PRODUCTION_URL;

			return url.replace("/api", "/download/expenditure-sample");
		},
	},
	mounted() {
		if (this.user_is_party) {
			this.getPortalStatus();
		}
	},
};
</script>
<style scoped>
h5 {
	color: green !important;
}
.modal {
	background-color: rgb(14 11 11 / 74%) !important;
}
</style>
