/* eslint-disable */

// Install input filters.
export const mustBeInteger = (input_id) => {
    setInputFilter(
        document.getElementById(input_id),
        function (value) {
            return /^-?\d*$/.test(value);
        },
        "Must be an integer"
    );
};

export const mustBeUnsignedInteger = (input_id) => {
    setInputFilter(
        document.getElementById(input_id),
        function (value) {
            return /^\d*$/.test(value);
        },
        "Must be an unsigned integer"
    );
};

export const mustBeBetweenNumbers = (start, end, input_id) => {
    setInputFilter(
        document.getElementById(input_id),
        function (value) {
            return /^\d*$/.test(value) && (value === start || parseInt(value) <= end);
        },
        `Must be between ${start} and ${end}`
    );
};

export const mustBeFloat = (input_id) => {
    setInputFilter(
        document.getElementById(input_id),
        function (value) {
            return /^-?\d*[.,]?\d*$/.test(value);
        },
        "Must be a floating (real) number"
    );
};

export const mustBeCurrency = (input_id) => {
    setInputFilter(
        document.getElementById(input_id),
        function (value) {
            return /^-?\d*[.,]?\d{0,2}$/.test(value);
        },
        "Must be a currency value without comma"
    );
};

export const mustBeString = (input_id) => {
    setInputFilter(
			document.getElementById(input_id),
			function (value) {
				return /^[a-z]*$/i.test(value);
			},
			"Must be alphabetic characters"
		);
};

export const mustBeHexa = (input_id) => {
    setInputFilter(
        document.getElementById(input_id),
        function (value) {
            return /^[0-9a-f]*$/i.test(value);
        },
        "Must use hexadecimal characters"
    );
};


function setInputFilter(textbox, inputFilter, errMsg) {
    [
        "input",
        "keydown",
        "keyup",
        "mousedown",
        "mouseup",
        "select",
        "contextmenu",
        "drop",
        "focusout",
    ].forEach(function (event) {
        textbox.addEventListener(event, function (e) {
            if (inputFilter(this.value)) {
                // Accepted value
                if (["keydown", "mousedown", "focusout"].indexOf(e.type) >= 0) {
                    this.classList.remove("input-error");
                    this.setCustomValidity("");
                }
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
                // Rejected value - restore the previous one
                this.classList.add("input-error");
                this.setCustomValidity(errMsg);
                this.reportValidity();
                this.value = this.oldValue;
                this.setSelectionRange(
                    this.oldSelectionStart,
                    this.oldSelectionEnd
                );
            } else {
                // Rejected value - nothing to restore
                this.value = "";
            }
        });
    });
}


		
		
		
		
		
		