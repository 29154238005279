import Request from "@/Request";

export function createBillBoardForm(payload) {
  return Request({
    url: "/tracking/billboards",
    method: "post",
    data: payload,
  });
}

export function createElectronicMediaForm(payload) {
  return Request({
    url: "/tracking/electronicMedia",
    method: "post",
    data: payload,
  });
}

export function createPrintMediaForm(payload) {
  return Request({
    url: "/tracking/printMedia",
    method: "post",
    data: payload,
  });
}

export function createMediaCoverageForm(payload) {
  return Request({
    url: "/tracking/mediaCoverage",
    method: "post",
    data: payload,
  });
}

export function createCampaignForm(payload) {
  return Request({
    url: "/tracking/campaign",
    method: "post",
    data: payload,
  });
}

export function formList(payload) {
  return Request({
    url: "/tracking",
    method: "get",
    params: payload,
  });
}
export function formById(payload) {
  return Request({
    url: `/tracking/${payload.id}/view`,
    method: "get",
  });
}

export function formDeleteById(payload) {
  return Request({
    url: `/tracking/${payload.id}`,
    method: "delete",
  });
}
