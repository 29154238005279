import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth";
import state from "./modules/errors/state";
import shared from "@/store/modules/shared";
import reports from "@/store/modules/reports";
import settings from "@/store/modules/settings";
import galleries from "@/store/modules/galleries";
import analytics from "@/store/modules/analytics";
import dashboard from "@/store/modules/dashboard";
import * as actions from "./modules/errors/actions";
import * as getters from "./modules/errors/getters";
import navigation from "@/store/modules/navigation";
import createPersistedState from "vuex-persistedstate";
import * as mutations from "./modules/errors/mutations";
import form_ec16d from "@/store/modules/forms/form_ec16d";
import form_ec16e from "@/store/modules/forms/form_ec16e";
import form_ec16f from "@/store/modules/forms/form_ec16f";
import breadcrumbsTitle from "@/store/modules/breadcrumbs_title";

import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  //   plugins: [createPersistedState({
  //     paths: ['auth']
  //   }),
  // ],
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
      key: "pfras",
      // paths: ["auth","navigation","breadcrumbsTitle","settings"],
      // paths: ["auth","breadcrumbsTitle","settings"],
    }),
  ],
  state,
  actions,
  mutations,
  getters,
  modules: {
    auth,
    shared,
    navigation,
    breadcrumbsTitle,
    galleries,
    form_ec16d,
    form_ec16e,
    form_ec16f,
    reports,
    analytics,
    dashboard,
    settings,
  },
});
