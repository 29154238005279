import { dashbaordData } from "@/api/dashboard";

// Get dashboard data
export const getDashboardData= (commit, payload) => {
  return new Promise((resolve) => {
    dashbaordData(payload).then((response) => {
      if (response.status_code === 200) {
        resolve(response.data);
      }
    });
  });
};
