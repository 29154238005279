<template>
	<div>
		<div
			class="card"
			:style="
				show_part ? 'border: 2px solid green' : 'box-shadow:unset;border:unset'
			"
		>
			<div v-if="portal_status">
				<div v-if="show_part" class="card-header">
					<h4 align="center" style="font-weight: 700" class="text-danger">
						{{ msg }}
					</h4>
				</div>
				<form @submit.prevent="saveAll()" action="" method="post">
					<div
						class="card-block"
						:style="show_part ? '' : 'padding: 20px 0px;'"
					>
						<Form @set-input="setSharedFormInput" />

						<div v-if="show_part" class="row">
							<div class="col-md-6">
								<div class="row mb-3">
									<label for="supporting_docs" class="col-sm-4 col-form-label"
										>SUPPORTING DOCUMENTS:<span class="text-danger">*</span>
									</label>
									<div class="col-sm-8">
										<select
											id="sp_docs"
											class="t_select"
											v-model="form.supporting_docs"
											autocomplete="off"
											multiple
											required
										></select>
										<div class="text-danger text-sm" style="font-size: 10px">
											(This helps auditors request appropriate documents during
											auditing)
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row mb-3">
									<label for="approved_by" class="col-sm-4 col-form-label"
										>Approved By:<span class="text-danger">*</span></label
									>
									<div class="col-sm-8">
										<input
											v-model="form.approved_by"
											name="approved_by"
											id="approved_by"
											type="text"
											class="form-control"
											required
										/>
									</div>
								</div>
							</div>
						</div>

						<hr />
						<div class="dt-responsive table-responsive">
							<table id="dt-live-dom" class="table table-bordered nowrap">
								<thead
									style="
										border-top: 2px solid green;
										border-bottom: 2px solid green;
									"
								>
									<tr>
										<!-- <th>S/NO</th> -->
										<th>EXPENDITURE HEAD</th>
										<th>NUMBER/QUANTITY</th>
										<th>UNIT COST</th>
										<th>TOTAL COST</th>
										<th>REMARKS/NARRATION</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="expenditure in expenses"
										:value="expenditure.id"
										:key="'ex' + expenditure.id"
									>
										<!-- <td>1</td> -->
										<td>
											<input
												class="form-control"
												type="text"
												id="row-1-position"
												name="row-1-position"
												:value="expenditure.name"
												style="border: none"
												readonly
											/>
										</td>
										<td>
											<input
												@input="
													inputMustBeUnsignedInteger('qid' + expenditure.id)
												"
												@change="setInput(expenditure.id, $event.target)"
												class="form-control"
												type="text"
												placeholder="Eg. 10"
												:id="'qid' + expenditure.id"
												name="quantity"
												:value="expenditure.quantity"
											/>
										</td>
										<td>
											<input
												class="form-control"
												type="text"
												name="unit_cost"
												placeholder="Eg. 10000"
												:id="'uid' + expenditure.id"
												@input="inputMustBeCurrency('uid' + expenditure.id)"
												@change="setInput(expenditure.id, $event.target)"
												:value="expenditure.unit_cost"
											/>
										</td>
										<td>
											<input
												class="form-control"
												type="text"
												id="row-1-position"
												name="total_cost"
												@change="setInput(expenditure.id, $event.target)"
												:value="expenditure.total_cost"
												readonly
											/>
										</td>
										<td>
											<textarea
												class="form-control"
												type="text"
												id="row-1-position"
												name="remarks"
												rows="1"
												placeholder="Enter remarks/narration"
												@change="setInput(expenditure.id, $event.target)"
												:value="expenditure.remarks"
											></textarea>
										</td>
									</tr>
								</tbody>
								<tfoot style="border-bottom: 2px solid green">
									<tr>
										<!-- <th>S/NO</th> -->
										<th>EXPENDITURE HEAD</th>
										<th>NUMBER/QUANTITY</th>
										<th>UNIT COST</th>
										<th>TOTAL COST</th>
										<th>REMARKS/NARRATION</th>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
					<div align="center" style="margin-bottom: 2rem">
						<router-link
							v-if="user_is_party"
							to="/forms/home/expenditures"
							class="btn btn-link"
							>Cancel</router-link
						>
						<LoadingButton class="btn btn-info">Submit</LoadingButton>
					</div>
				</form>
			</div>
			<div v-else class="alert alert-danger">
				<strong class="text-center">{{ msg }}</strong>
			</div>
		</div>
		<!-- <div v-else class="alert alert-danger">
			<strong class="text-center">You're not allowed to view this page!</strong>
		</div> -->
	</div>
</template>
<script>
import { mustBeUnsignedInteger, mustBeCurrency } from "@/utils/input_filter.js";
import { EventBus } from "@/utils/event_bus.js";
import Form from "@/components/Form.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import initializeMultipleSelect from "@/utils/multi_select.js";
import { userIsParty } from "@/utils/permissions";
import { mapGetters, mapActions } from "vuex";

export default {
	name: "ExpenditureAdd",
	props: {
		show_part: {
			type: Boolean,
			default: true,
			required: false,
		},
		candidate_name: {
			type: String,
			required: false,
		},
		advert_type_id: {
			type: String,
			required: false,
		},
	},
	components: {
		Form,
		LoadingButton,
	},
	data() {
		return {
			portal_status: false,
			msg: "Loading....please wait...!",
			expenses: [],
			documents: [],
			form: {
				expenses: [],
				supporting_docs: [],
				party_id: "",
				state_code: "",
				approved_by: "",
				elective_position_id: "",
				lga_id: "",
				date_reported: "",
				constituency_id: "",
				advert_type_id: this.advert_type_id ?? null, // Campaign ID
				candidate_name: this.candidate_name ?? null,
			},
		};
	},
	computed: {
		...mapGetters({
			expenditures: "settings/expenditures",
			user: "auth/currentUser",
		}),
		user_is_party() {
			return userIsParty();
		},
	},
	methods: {
		...mapActions({
			addForm: "form_ec16e/addForm",
			addCampaignForm: "form_ec16f/addCampaignForm",
			statusReportSubmission: "settings/statusReportSubmission",
			getSupportingDocuments: "settings/getSupportingDocuments",
		}),

		async saveAll() {
			if (this.validateExpenses()) {
				return alert("Please fill atleast one input");
			}

			this.form.expenses = JSON.stringify(this.expenses);

			if (this.candidate_name) {
				this.addFormF();
			} else {
				this.addFormE();
			}
		},

		validateExpenses() {
			let expense = this.expenses.find(
				(expense) => expense.unit_cost > 0 || expense.quantity > 0
			);

			if (expense === undefined) {
				return true;
			}

			return false;
		},

		async addFormE() {
			await this.addForm(this.form).then((response) => {
				if (response === "success") {
					this.$router.push("/forms/expenditures");
				}
			});
		},

		async addFormF() {
			await this.addCampaignForm(this.form).then((response) => {
				if (response === "success") {
					this.form.expenses = [];
					this.setExpenses();
				}
			});
		},

		setInput(expense_id, event) {
			let expense_index = this.expenses.findIndex(
				(expense) => expense.id === expense_id
			);

			if (expense_index < 0) {
				return;
			}

			if (
				(event && event.name === "quantity" && Number.isInteger(event.value)) ||
				(event.name === "unit_cost" && Number.isFinite(event.value))
			) {
				return (event.value = "");
			}

			let item = this.expenses[expense_index];
			item[event.name] = event.value;
			let total = parseInt(item["quantity"]) * parseFloat(item["unit_cost"]);
			item["total_cost"] = isNaN(total) ? 0 : total;

			this.$set(this.expenses, expense_index, item);
		},
		setExpenses() {
			if (this.expenditures.length) {
				this.expenses = this.expenditures.map((expense) => {
					return {
						id: expense.id,
						name: expense.name,
						quantity: null,
						unit_cost: null,
						total_cost: 0,
						remarks: null,
					};
				});
			}
		},
		inputMustBeUnsignedInteger(input_id) {
			return mustBeUnsignedInteger(input_id);
		},

		inputMustBeCurrency(input_id) {
			return mustBeCurrency(input_id);
		},

		setSharedFormInput(params) {
			this.form[params.name] = params.value;
		},

		getPortalStatus() {
			this.statusReportSubmission().then((response) => {
				this.portal_status = response.status;
				this.msg = response.msg;
			});
		},

		getDocuments() {
			// if (
			// 	this.supporting_documents &&
			// 	Object.keys(this.supporting_documents).length
			// ) {
			// 	this.documents = this.supporting_documents;
			// 	return this.reloadMultiSelect();
			// }

			this.getSupportingDocuments({ status: "enabled" }).then((response) => {
				this.documents = response.data;
				this.reloadMultiSelect();
			});
		},

		reloadMultiSelect() {
			initializeMultipleSelect("sp_docs", this.documents);
		},
	},

	created() {
		EventBus.$on("set-params", this.setSharedFormInput);
	},

	mounted() {
		if (!this.user_is_party) {
			this.portal_status = true;
		}

		if (this.user_is_party) {
			this.form.party_id = this.user_party_id;
			this.getPortalStatus();
		}

		if (!this.expenditures.length) {
			this.$store.dispatch("settings/getExpenditures");
		}

		this.setExpenses();
		this.getDocuments();
	},
};
</script>
<style scoped>
.btn {
	width: 200px;
	border-radius: 6px;
}
.input-error {
	outline: 1px solid red;
}
</style>
