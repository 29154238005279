<template>
	<div>
		<Sidebar></Sidebar>
		<Navigation></Navigation>
		<div class="pcoded-main-container">
			<div class="pcoded-content">
				<Breadcrumb></Breadcrumb>

				<Alert></Alert>

				<zoom-center-transition :duration="200" mode="out-in">
					<!-- your content here -->
					<router-view></router-view>
				</zoom-center-transition>
			</div>
		</div>

		<ModalChangePassword :user="user"></ModalChangePassword>
	</div>
</template>
<script>
import ModalChangePassword from "@/components/ModalChangePassword.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import { EventBus } from "@/utils/event_bus.js";
import Breadcrumb from "@/components/Breadcrumb";
import Navigation from "@/components/Navigation";
import Sidebar from "@/components/Sidebar";
import Alert from "@/components/Alert.vue";
import { mapGetters } from "vuex";

export default {
	components: {
		Navigation,
		Sidebar,
		ZoomCenterTransition,
		ModalChangePassword,
		Breadcrumb,
		Alert,
	},

	computed: {
		...mapGetters({
			user: "auth/currentUser",
		}),
	},
	mounted() {
		if (this.user && this.user.is_default_password) {
			EventBus.$emit("toggle-password-modal", "show");
		}
	},
};
</script>
<style>
.pcoded-navbar.menu-light .pcoded-inner-navbar > li.active > a {
	background: #29623c;
	border-color: #29623c;
}
.pcoded-navbar.menu-light .pcoded-inner-navbar > li.pcoded-trigger > a {
	background: #29623c;
	color: #fff;
}
.pcoded-navbar.menu-light .pcoded-inner-navbar li > a:hover {
	color: green;
	background: transparent;
}
.ps__thumb-y {
	background: #29623c;
}
.form-control {
	border: 1px solid #ced4da;
	background: #fff;
}
.form-control:focus {
	border-color: #02a438;
	background-image: unset;
}
.btn-info {
	background: #29623c;
	border-color: #29623c;
}
.btn-info:hover {
	background-color: #268e49;
	border-color: #268e49;
}
body {
	background: #29623c !important;
}
#nprogress .bar {
	background: #fff !important;
}

#nprogress .peg {
	box-shadow: 0 0 10px #fff, 0 0 5px #fff;
}

#nprogress .spinner-icon {
	border-top-color: #fff;
	border-left-color: #fff;
}
.btn {
	border-radius: 6px;
}
</style>
