import { 
  usersList, userCreate, userUpdate, userUpdatePassword,
  advertTypesList, electivePositionsList, zonesList, partiesList,rolesList, partiesCreate,partyOnDashboard, partiesUpdate, statesList, expendituresList, 
  federalConstituenciesList, stateConstituenciesList, lgasList, mediaChannelsList, SenatorialZonesList, 
  reportSubmissionList,reportSubmissionCreate, reportSubmissionUpdate, reportSubmissionStatus, locationsByState, 
  supportingDocumentsList, supportingDocumentsCreate, supportingDocumentsUpdate,supportingDocumentsStatus
} from "@/api/settings";
import store from "@/store";

/**
 * Get Advert Types List
 */
export const getAdvertTypes = (commit, payload) => {
  return new Promise((resolve) => {
    advertTypesList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setAdvertTypes", response.data);
      }
      resolve(response);
    });
  });
};

/**
 * Get Elective Positions List
 */
export const getElectivePositions = (commit, payload) => {
  return new Promise((resolve) => {
    electivePositionsList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setElectivePositions", response.data);
      }
      resolve(response);
    });
  });
};


/**
 * Get zones
 */
export const getZones = (commit, payload) => {
  return new Promise((resolve) => {
    zonesList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setZones", response.data);
      }
      resolve(response);
    });
  });
};

/**
 * Get Parties
 */
export const getParties = (commit, payload) => {
  return new Promise((resolve) => {
    partiesList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setParties", response.data);
      }
      resolve(response);
    });
  });
};

export const createParty = (commit, payload) => {
  return new Promise((resolve) => {
    partiesCreate(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setParties", response.data);
      }
      resolve(response);
    });
  });
};

export const updateParty = (commit, payload) => {
  return new Promise((resolve) => {
    partiesUpdate(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setParties", response.data);
      }
      resolve(response);
    });
  });
};

export const dashboardParty = (commit, payload) => {
  return new Promise((resolve) => {
    partyOnDashboard(payload).then((response) => {
      if (response.status_code == 206|| response.status_code == 200) {
        store.commit("settings/setParties", response.data);
      }
      resolve(response);
    });
  });
};

export const getRoles = (commit, payload) => {
  return new Promise((resolve) => {
    rolesList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setRoles", response.data);
      }
      resolve(response);
    });
  });
};

/**
 * Get States
 */
export const getStates = (commit, payload) => {
  return new Promise((resolve) => {
    statesList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setStates", response.data);
      }
      resolve(response);
    });
  });
};
 
/**
 * Get list of expenditures
 */
export const getExpenditures = (commit, payload) => {
  return new Promise((resolve) => {
    expendituresList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setExpenditures", response.data);
      }
      resolve(response);
    });
  });
};

export const getFederalConstituencies = (commit, payload) => {
  return new Promise((resolve) => {
    federalConstituenciesList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setFederalConstituencies", response.data);
      }
      resolve(response);
    });
  });
};


export const getStateConstituencies = (commit, payload) => {
  return new Promise((resolve) => {
    stateConstituenciesList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setStateConstituencies", response.data);
      }
      resolve(response);
    });
  });
};


export const getLgas = (commit, payload) => {
  return new Promise((resolve) => {
    lgasList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setLgas", response.data);
      }
      resolve(response);
    });
  });
};

export const getMediaChannels = (commit, payload) => {
  return new Promise((resolve) => {
    mediaChannelsList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setChannels", response.data);
      }
      resolve(response);
    });
  });
};

export const getSenatorialZones = (commit, payload) => {
  return new Promise((resolve) => { 
    SenatorialZonesList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setSenatorialZones", response.data);
      }
      resolve(response);
    });
  });
};

export const getUsers = (commit, payload) => {
  return new Promise((resolve) => {
    usersList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setUsers", response.data);
      }
      resolve(response);
    });
  });
};

export const createUser = (commit, payload) => {
  return new Promise((resolve) => {
    userCreate(payload).then((response) => {
      if (response.status_code == 201) {
        resolve("success");
      }
    });
  });
};

export const updateUser = (commit, payload) => {
  return new Promise((resolve) => {
    userUpdate(payload).then((response) => {
      if (response.status_code == 201) {
        resolve("success");
      }
    });
  });
};

export const updateUserPassword = (commit, payload) => {
  return new Promise((resolve) => {
    userUpdatePassword(payload).then((response) => {
      if (response.status_code == 201 || response.status_code == 206) {
        store.commit("auth/setDefaultPassword", response.data);

        resolve("success");
      }
    });
  });
};

export const getLocationsByState = (commit, payload) => {
  return new Promise((resolve) => {
    locationsByState(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setLgas", response.data.lgas);
        store.commit("settings/setFederalConstituencies", response.data.fed_constituencies);
        store.commit("settings/setStateConstituencies", response.data.state_constituencies);
        store.commit("settings/setSenatorialZones", response.data.sd);
        // store.commit("settings/setLocations", response.data);
      }
      resolve(response);
    });
  });
};

/**
 * Report submission settings starts here
 */
export const getReportSubmission = (commit, payload) => {
  return new Promise((resolve) => {
    reportSubmissionList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setReportSubmission", response.data);
      }
      resolve(response);
    });
  });
};

export const createReportSubmission = (commit, payload) => {
  return new Promise((resolve) => {
    reportSubmissionCreate(payload).then((response) => {
      if (response.status_code == 201) {
        getReportSubmission();
        resolve("success");
      }
    });
  });
};

export const updateReportSubmission = (commit, payload) => {
  return new Promise((resolve) => {
    reportSubmissionUpdate(payload).then((response) => {
      if (response.status_code == 201) {
        getReportSubmission();
        resolve("success");
      }
    });
  });
};

export const statusReportSubmission = (commit, payload) => {
  return new Promise((resolve) => {
    reportSubmissionStatus(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setReportSubmissionStatus", response.data);
        
        resolve(response.data);
      }
    });
  });
};

/**
 * Supporting documents settings starts here
 */
export const getSupportingDocuments = (commit, payload) => {
  return new Promise((resolve) => {
    supportingDocumentsList(payload).then((response) => {
      if (response.status_code == 200) {
        store.commit("settings/setSupportingDocuments", response.data);
      }
      resolve(response);
    });
  });
};

export const createSupportingDocuments = (commit, payload) => {
  return new Promise((resolve) => {
    supportingDocumentsCreate(payload).then((response) => {
      if (response.status_code == 201) {
        getSupportingDocuments();
        resolve("success");
      }
    });
  });
};

export const updateSupportingDocuments = (commit, payload) => {
  return new Promise((resolve) => {
    supportingDocumentsUpdate(payload).then((response) => {
      if (response.status_code == 201) {
        getSupportingDocuments();
        resolve("success");
      }
    });
  });
};

export const statusSupportingDocuments = (commit, payload) => {
  return new Promise((resolve) => {
    supportingDocumentsStatus(payload).then((response) => {
      if (response.status_code == 200) {
        resolve(response.data);
      }
    });
  });
};
/**
 * Supporting documents settings ends here
 */
