import Request from "@/Request";

export function createForm(payload) {
  return Request({
    url: "/income",
    method: "post",
    data: payload,
  });
}

export function updateForm(payload) {
  return Request({
    url: "/income/" + payload.id,
    method: "put",
    data: payload,
  });
}

export function formList(payload) {
  return Request({
    url: "/income",
    method: "get",
    params: payload,
  });
}

export function formToday(payload) {
  return Request({
    url: "/income/today",
    method: "get",
    params: payload,
  });
}
export function formById(payload) {
  return Request({
    url: `/income/${payload.id}/view`,
    method: "get",
  });
}

export function formDeleteById(payload) {
  return Request({
    url: `/income/${payload.id}`,
    method: "delete",
  });
}

export function formImport(payload) {
  return Request({
    url: "/income/import",
    method: "post",
    data: payload,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}
