<template>
	<div class="" style="background: #29623c">
		<div class="main-menu-header" style="padding: 0">
			<img
				style="width: 100%; height: 95px; background: #fff"
				src="@/assets/images/logo_2.jpg"
				alt="User-Profile-Image"
			/>
			<div class="user-details" style="color: #fff">
				<div id="more-details">
					<router-link
						:to="'/profile'"
						style="color: #fff; text-transform: uppercase"
						>{{ user.name }}</router-link
					>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters({
			user: "auth/currentUser",
		}),
	},
};
</script>
