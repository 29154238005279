<template>
	<div class="align-items-center mb-3">
		<form @submit.prevent="search()">
			<div class="row">
				<div v-if="!user_is_party" class="col-md-2">
					<div class="form-group text-center">
						<label class="form-label" for="party_id">Political Party:</label>
						<select class="form-select" id="party_id" v-model="form.party_id">
							<option value="">--select--</option>
							<option
								v-for="party in parties"
								:value="party.id"
								:key="'p' + party.id"
							>
								{{ party.abbreviation }}
							</option>
						</select>
					</div>
				</div>
				<div v-if="user_is_staff_admin || user_is_party_admin" class="col-md-2">
					<div class="form-group text-center">
						<label class="form-label" for="state_code">State</label>
						<select
							v-model="form.state_code"
							class="form-select"
							id="state_code"
							@change="getByState()"
						>
							<option value="">--select--</option>
							<option
								v-for="state in states"
								:value="state.code"
								:key="'st_' + state.code"
							>
								{{ state.name }}
							</option>
						</select>
					</div>
				</div>
				<!-- <div class="col-md-2">
					<div class="form-group text-center">
						<label class="form-label" for="constituency_id"
							>Fed. Constituency</label
						>
						<select
							v-model="form.constituency_id"
							class="form-select"
							id="constituency_id"
						>
							<option value="">--select--</option>
							<option
								v-for="constituency in constituencies"
								:value="constituency.id"
								:key="'c' + constituency.id"
							>
								{{ constituency.name + " (" + constituency.code + ")" }}
							</option>
						</select>
					</div>
				</div> -->
				<div class="col-md-2">
					<div class="form-group text-center">
						<label class="form-label" for="elective_position_id"
							>Type of Election</label
						>
						<select
							@change="emitInput($event)"
							v-model="form.elective_position_id"
							class="form-select"
							id="elective_position_id"
						>
							<option value="">--select--</option>
							<option
								v-for="position in positions"
								:value="position.id"
								:key="'ep' + position.id"
							>
								{{ position.name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-md-2">
					<div class="form-group text-center">
						<label class="form-label" for="date_range">Date Range</label>
						<select
							v-model="form.date_range"
							class="form-select"
							id="date_range"
						>
							<option value="">--select--</option>
							<option value="this_week">This Week</option>
							<option value="this_month">This Month</option>
							<option value="this_year">This Year</option>
							<option
								v-for="submission in submissions"
								:value="submission.id"
								:key="'sub_' + submission.id"
							>
								{{ submission.title }}
							</option>
							<option value="custom">Custom Date Range</option>
						</select>
					</div>
				</div>
				<div v-if="form.date_range == 'custom'" class="col-md-2">
					<label for="start_date" class="form-label">Start Date</label>
					<input
						v-model="form.start_date"
						type="date"
						id="start_date"
						class="form-control date"
						data-mask="99/99/9999"
						required
					/>
				</div>
				<div v-if="form.date_range == 'custom'" class="col-md-2">
					<label for="end_date" class="form-label">End Date</label>
					<input
						v-model="form.end_date"
						type="date"
						id="end_date"
						class="form-control date"
						data-mask="99/99/9999"
					/>
				</div>

				<slot></slot>

				<div class="col-md-2" style="margin-top: 1.3rem">
					<div class="form-group">
						<LoadingButton
							type="submit"
							class="btn btn-info"
							style="border-radius: 6px"
							>Search</LoadingButton
						>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import LoadingButton from "@/components/LoadingButton.vue";
import {
	userIsStaffAdmin,
	userIsStaff,
	userIsParty,
	userIsPartyAdmin,
} from "@/utils/permissions";

export default {
	components: {
		LoadingButton,
	},
	data() {
		return {
			form: {
				party_id: "",
				state_code: "",
				constituency_id: "",
				elective_position_id: "",
				date_range: "",
				start_date: "",
				end_date: "",
			},
		};
	},
	watch: {
		"form.date_range": function (val) {
			if (val != "custom") {
				this.form.start_date = "";
				this.form.end_date = "";
			}
		},
	},
	computed: {
		...mapGetters({
			states: "settings/states",
			parties: "settings/parties",
			// constituencies: "settings/federal_constituencies",
			positions: "settings/elective_positions",
			submissions: "settings/report_submission",
		}),
		user_is_staff() {
			return userIsStaff();
		},
		user_is_staff_admin() {
			return userIsStaffAdmin();
		},
		user_is_party_admin() {
			return userIsPartyAdmin();
		},
		user_is_party() {
			return userIsParty();
		},
	},

	methods: {
		...mapActions({
			getFederalConstituencies: "settings/getFederalConstituencies",
			getParties: "settings/getParties",
			getElectivePositions: "settings/getElectivePositions",
			getStates: "settings/getStates",
			getLgas: "settings/getLgas",
		}),

		listParties() {
			let params = { keys: JSON.stringify(["id", "abbreviation"]) };
			this.getParties(params);
		},
		listStates() {
			let params = { keys: JSON.stringify(["id", "name", "code"]) };
			this.getStates(params);
		},
		listFederalConstituencies() {
			let params = { keys: JSON.stringify(["id", "name"]) };
			this.getFederalConstituencies(params);
		},
		async getByState() {
			// let params = { state_code: this.form.state_code };
			// await this.getFederalConstituencies(params);
		},
		search() {
			this.$emit("search", this.form);
		},
		emitInput(event) {
			let params = {
				name: event.target.id,
				value: event.target.value,
			};
			this.$emit("toggle-input", params);
		},
	},

	created() {
		if (this.constituencies && this.constituencies.length) {
			this.$store.commit("settings/setFederalConstituencies", []);
		}
	},

	mounted() {
		if (!this.parties.length) {
			this.listParties();
		}

		if (!this.states.length || this.states.length < 36) {
			this.listStates();
		}

		if (!this.positions.length) {
			this.getElectivePositions();
		}

		if (!this.submissions.length) {
			this.$store.dispatch("settings/getReportSubmission");
		}

		if (this.user_is_party) {
			this.form.party_id = this.user_party_id;
		}
	},
};
</script>
