var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav pcoded-inner-navbar"},[_vm._m(0),_vm._l((_vm.links),function(link,index){return [(_vm.user_has_permission(link.permission))?_c('li',{key:'menu_' + index,class:{
				'pcoded-hasmenu': link.has_sub_menu,
				'pcoded-trigger': link.open,
				active: _vm.$route.path.includes(link.url),
			}},[_c('a',{staticClass:"nav-link",attrs:{"href":link.url},on:{"click":function($event){$event.preventDefault();return _vm.gotoUrl(link)}}},[_c('span',{staticClass:"pcoded-micon"},[_c('i',{staticClass:"feather",class:link.icon})]),_c('span',{staticClass:"pcoded-mtext"},[_vm._v(_vm._s(link.title))])]),(link.has_sub_menu)?_c('ul',{staticClass:"pcoded-submenu",class:{
					'pcoded-showmenu': link.open,
				},staticStyle:{"padding":"0"}},[_vm._l((link.sub_menus),function(sub_link,key){return [(
							sub_link.permission && _vm.user_has_permission(sub_link.permission)
						)?_c('li',{key:'sub_menu_' + key,class:{
							'pcoded-hasmenu': sub_link.has_sub_menu,
							'pcoded-trigger': sub_link.open,
							active: _vm.$route.path.includes(sub_link.url),
						}},[_c('a',{staticClass:"has-ripple",on:{"click":function($event){$event.preventDefault();return _vm.gotoUrl(sub_link)}}},[_vm._v(" "+_vm._s(sub_link.title)+" "),_c('span',{staticClass:"ripple ripple-animate",staticStyle:{"height":"230px","opacity":"0.4","left":"189px","top":"-96px"}})]),(sub_link.has_sub_menu)?_c('ul',{staticClass:"pcoded-submenu",class:{ 'pcoded-showmenu': sub_link.open },staticStyle:{"padding":"0"}},[_vm._l((sub_link.sub_menus),function(sub_link2,sub){return [(
										sub_link2.permission &&
										_vm.user_has_permission(sub_link2.permission)
									)?_c('li',{key:'sub' + sub,class:{
										active: _vm.$route.path.includes(sub_link.url),
									},on:{"click":function($event){sub_link.open = !sub_link.open}}},[_c('router-link',{staticClass:"has-ripple",attrs:{"to":sub_link2.url}},[_vm._v(_vm._s(sub_link2.title)+" "),_c('span',{staticClass:"ripple ripple-animate",staticStyle:{"height":"230px","width":"230px","animation-duration":"0.7s","animation-timing-function":"linear","background":"rgb(70, 128, 255)","opacity":"0.4","top":"-100px","left":"36px"}})])],1):_vm._e()]})],2):_vm._e()]):_vm._e()]})],2):_vm._e()]):_vm._e()]}),_c('ModalLogout')],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"nav-item pcoded-menu-caption"},[_c('label',{staticStyle:{"color":"#268e49","font-size":"20px","font-weight":"700"}},[_vm._v("Navigation")])])
}]

export { render, staticRenderFns }