<template>
	<Modal title="Edit Expenditure">
		<form @submit.prevent="submitForm()">
			<Alert />
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label" for="expenditure_head"
							>Expenditure Head</label
						>
						<input
							v-model="expenditure"
							name="expenditure_head"
							id="expenditure_head"
							type="text"
							class="form-control"
							disabled
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label" for="quantity">Quantity</label>
						<input
							@input="inputMustBeUnsignedInteger('quantity')"
							@change="calculateTotalCost()"
							v-model="form.quantity"
							name="quantity"
							id="quantity"
							type="text"
							class="form-control"
							placeholder="eg. 100"
							required
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label" for="unit_cost"
							>Unit Cost
							<span class="text-danger" style="font-size: 12px"
								>(eg. 1,000,000)</span
							></label
						>
						<input
							@change="calculateTotalCost()"
							v-model="form.unit_cost"
							name="unit_cost"
							id="unit_cost"
							type="text"
							class="form-control"
							placeholder="Eg. 1,000,000"
							required
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="form-label" for="contribution_description"
							>Total Cost
							<span class="text-danger" style="font-size: 12px"
								>(eg. 1,000,000)</span
							></label
						>
						<input
							v-model="total_cost"
							class="form-control"
							type="text"
							id="total_cost"
							name="total_cost"
							disabled
						/>
					</div>
				</div>
				<div class="col-md-12">
					<div class="form-group">
						<label class="form-label" for="remarks">Remarks/Narration:</label>
						<textarea
							v-model="form.remarks"
							class="form-control"
							id="remarks"
							name="remarks"
							rows="2"
							placeholder="Enter description"
							required
						></textarea>
					</div>
				</div>

				<div class="col-sm-12" style="border-top: 1px solid #e3e3e3">
					<div align="right" style="margin-top: 15px">
						<button
							@click.prevent="toggleModal('hide')"
							class="btn btn-secondary"
							style="margin-right: 5px"
						>
							Cancel
						</button>
						<LoadingButton class="btn-info">Update</LoadingButton>
					</div>
				</div>
			</div>
		</form>
	</Modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
import { EventBus } from "@/utils/event_bus.js";
import LoadingButton from "@/components/LoadingButton.vue";
import { mustBeUnsignedInteger, mustBeCurrency } from "@/utils/input_filter.js";
import Alert from "@/components/Alert.vue";

export default {
	props: {
		report: {
			type: Object,
			default: null,
		},
	},
	components: {
		Modal,
		LoadingButton,
		Alert,
	},

	data() {
		return {
			expenditure: "",
			total_cost: "",
			form: {
				quantity: "",
				unit_cost: "",
				remarks: "",
				batch_id: null,
				id: null,
			},
		};
	},

	watch: {
		report: {
			handler(report) {
				this.expenditure = report.expenditure;
				this.total_cost = report.total_cost;

				this.form.quantity = report.quantity;
				this.form.unit_cost = report.unit_cost_unformatted;
				this.total_cost = report.total_cost_unformatted;
				this.form.remarks = report.remarks;
				this.form.batch_id = report.batch_id;
				this.form.id = report.id;
			},
		},
	},

	methods: {
		async submitForm() {
			await this.$store
				.dispatch("form_ec16e/editForm", this.form)
				.then((res) => {
					if (res && res === "success") {
						this.clearInputs();
						this.toggleModal("hide");
					}
				});
		},
		calculateTotalCost() {
			let quantity = 1;
			let total = 0;

			if (this.form.quantity && !isNaN(this.form.quantity)) {
				quantity = parseInt(this.form.quantity);
			}

			if (
				!isNaN(parseFloat(this.form.unit_cost)) &&
				isFinite(this.form.unit_cost)
			) {
				total = parseFloat(this.form.unit_cost);
			}

			this.total_cost = quantity * total;
		},
		toggleModal(type) {
			EventBus.$emit("toggle-modal", type);
		},
		inputMustBeUnsignedInteger(input_id) {
			return mustBeUnsignedInteger(input_id);
		},
		inputMustBeCurrency(input_id) {
			return mustBeCurrency(input_id);
		},

		clearInputs() {
			this.expenditure = "";
			this.total_cost = "";

			this.form.quantity = "";
			this.form.unit_cost = "";
			this.form.remarks = "";
			this.form.batch_id = null;
			this.form.id = null;
		},
	},
};
</script>
<style scoped>
.form-control {
	padding: 5px !important;
}
</style>
