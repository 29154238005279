export const setForms = (state, payload) => {
  state.forms = payload;
};

export const setSingleForm = (state, payload) => {
  state.single_form = payload;
};

export const deleteByIndex = (state, payload) => {
  state.forms.expenditures.splice(payload.form_index, 1);
};
