import { createForm, updateForm, formList, formById, deleteForm, formImport } from "@/api/form_ec16e";
import store from "@/store";

export const addForm = (commit, payload) => {
  return new Promise((resolve) => {
    createForm(payload).then((response) => {
      if (response.status_code === 201) {
        resolve("success");
      }
    });
  });
};

export const editForm = (commit, payload) => {
  return new Promise((resolve) => {
    updateForm(payload).then((response) => {
      if (response.status_code === 201) {
        getFormById(commit, {batch_id: response.data.batch_id});
      }
      resolve("success");
    });
  });
};

export const getFormList = (commit, payload) => {
  return new Promise((resolve) => {
    formList(payload).then((response) => {
      if (response.status_code === 200) {
        store.commit("form_ec16e/setForms", response.data);
      }
      resolve();
    });
  });
};

export const getFormById = (commit, payload) => {
  return new Promise((resolve) => {
    formById(payload).then((response) => {
      if (response.status_code === 200) {
        store.commit("form_ec16e/setSingleForm", response.data);
      }
      resolve();
    });
  });
};

export const deleteFormById = (commit, payload) => {
  return new Promise((resolve) => {
    deleteForm(payload).then((response) => {
      if (response.status_code === 200) {
        store.commit("form_ec16e/deleteByIndex", payload);
        
        resolve("success");
      }
    });
  });
};

export const formImportCSV = (commit, payload) => {
  return new Promise((resolve) => {
    formImport(payload).then((response) => {
      if (response.status_code === 201) {
        getFormList();
        resolve("success");
      }
    });
  });
};
