<template>
	<div class="align-items-center mb-3">
		<form @submit.prevent="search()">
			<div class="row">
				<div class="col-sm-2">
					<div class="form-group">
						<label class="form-label text-center" for="party_id"
							>Political Party:</label
						>
						<select
							id="party_id"
							class="t_select"
							v-model="form.party_ids"
							autocomplete="off"
							multiple
							required
						></select>
					</div>
				</div>
				<div class="col-sm-2">
					<div class="form-group text-center">
						<label class="form-label" for="prepare_by">Prepare Using:</label>
						<select
							v-model="form.prepare_by"
							@change="clearForm()"
							class="form-select"
							id="prepare_by"
							required
						>
							<option value="">--All--</option>
							<option value="by_zones">Expenses By Zones Only</option>
							<option value="by_zone_states">Expenses By States In Zone</option>
							<option value="by_all_states">Expenses By All States</option>
							<!-- <option value="by_states">Expenses By Parties In States</option> -->
							<option value="zones_breakdown">
								Expenses Breakdown By Zones
							</option>
							<option value="states_breakdown">
								Expenses Breakdown By States
							</option>
							<option value="positions_breakdown">
								Expenses By Elective Positions
							</option>
							<!-- <option value="by_advert_type">Expenses By Advert Types</option>
							<option value="by_states">Expenses By States</option>
							<option value="by_parties">Expenses By Parties</option> -->
						</select>
					</div>
				</div>
				<div class="col-sm-2" style="display: none">
					<div class="form-group text-center">
						<label class="form-label" for="advert_type_id">Expense Type</label>
						<select
							v-model="form.advert_type_id"
							name="advert_type_id"
							class="form-select"
							id="advert_type_id"
						>
							<option value="">--All--</option>
							<option
								v-for="advert in advert_types"
								:value="advert.id"
								:key="'ad' + advert.id"
							>
								{{ advert.name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-sm-2" v-if="form.prepare_by != 'positions_breakdown'">
					<div class="form-group text-center">
						<label class="form-label" for="elective_position_id"
							>Type of Election</label
						>
						<select
							v-model="form.elective_position_id"
							class="form-select"
							id="elective_position_id"
							required
						>
							<option value="">--All--</option>
							<option
								v-for="position in positions"
								:value="position.id"
								:key="'ep' + position.id"
							>
								{{ position.name }}
							</option>
						</select>
					</div>
				</div>
				<div v-if="form.prepare_by != 'by_zones'" class="col-sm-2">
					<div class="form-group text-center">
						<label class="form-label" for="zone_id">Zone</label>
						<select
							v-model="form.zone_id"
							name="zone_id"
							class="form-select"
							id="zone_id"
						>
							<option value="">--All--</option>
							<option
								v-for="zone in zones"
								:value="zone.id"
								:key="'zo' + zone.id"
							>
								{{ zone.name }}
							</option>
						</select>
					</div>
				</div>

				<div
					v-show="
						Object.keys(states).length && form.prepare_by == 'by_all_states'
					"
					class="col-sm-2"
				>
					<div class="form-group">
						<label class="form-label text-center" for="state_code">State</label>
						<select
							v-model="form.state_codes"
							id="state_code"
							class="t_select"
							autocomplete="off"
							multiple
						></select>
					</div>
				</div>
				<div v-if="Object.keys(lgas).length" class="col-sm-2">
					<div class="form-group text-center">
						<label class="form-label" for="lga_id">L.G.A</label>
						<select v-model="form.lga_id" class="form-select" id="lga_id">
							<option value="">--All--</option>
							<option v-for="lga in lgas" :value="lga.id" :key="'lg' + lga.id">
								{{ lga.name }}
							</option>
						</select>
					</div>
				</div>
				<div v-if="Object.keys(constituencies).length" class="col-sm-2">
					<div class="form-group text-center">
						<label class="form-label" for="constituency_id"
							>Fed. Constituency</label
						>
						<select
							v-model="form.constituency_id"
							class="form-select"
							id="constituency_id"
						>
							<option value="">--All--</option>
							<option
								v-for="constituency in constituencies"
								:value="constituency.id"
								:key="'c' + constituency.id"
							>
								{{ constituency.name + " (" + constituency.code + ")" }}
							</option>
						</select>
					</div>
				</div>

				<div class="col-sm-2">
					<div class="form-group text-center">
						<label class="form-label" for="date_range">Date Range</label>
						<select
							v-model="form.date_range"
							class="form-select"
							id="date_range"
						>
							<option value="">--All--</option>
							<option value="this_week">This Week</option>
							<option value="this_month">This Month</option>
							<option value="this_year">This Year</option>
							<option
								v-for="submission in submissions"
								:value="submission.id"
								:key="'sub_' + submission.id"
							>
								{{ submission.title }}
							</option>
							<option value="custom">Custom Date Range</option>
						</select>
					</div>
				</div>
				<div v-if="form.date_range == 'custom'" class="col-md-2">
					<label for="start_date" class="form-label">Start Date</label>
					<input
						v-model="form.start_date"
						type="date"
						id="start_date"
						class="form-control date"
						data-mask="99/99/9999"
						required
					/>
				</div>
				<div v-if="form.date_range == 'custom'" class="col-md-2">
					<label for="end_date" class="form-label">End Date</label>
					<input
						v-model="form.end_date"
						type="date"
						id="end_date"
						class="form-control date"
						data-mask="99/99/9999"
					/>
				</div>

				<div class="col-sm-2">
					<div class="form-group text-center">
						<label class="form-label" for="form_type">Form Type</label>
						<select v-model="form.form_type" class="form-select" id="form_type">
							<option value="">--Choose--</option>
							<option value="form_ec16e">FORM EC16E (Expenditure)</option>
							<option value="form_ec16f">FORM EC16F (Tracking)</option>
						</select>
					</div>
				</div>

				<div class="col-sm-2">
					<div class="form-group text-center">
						<label class="form-label" for="chart_type">Chart Type:</label>
						<select
							v-model="form.chart_type"
							class="form-select"
							id="chart_type"
							required
						>
							<option value="">--Choose--</option>
							<option value="column">Column Chart</option>
							<option value="pie">Pie Chart</option>
							<option value="bar">Bar Chart</option>
							<option value="spline">Spline Chart</option>
						</select>
					</div>
				</div>

				<div class="col-sm-2" style="margin-top: 1.2rem">
					<div class="form-group">
						<LoadingButton class="btn btn-info" style="width: 100%"
							>Search</LoadingButton
						>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import Datatable from "@/utils/datatable.js";
import initializeMultipleSelect from "@/utils/multi_select.js";
import { mapGetters, mapActions } from "vuex";
import LoadingButton from "@/components/LoadingButton.vue";
export default {
	components: {
		LoadingButton,
	},
	data() {
		return {
			form: {
				zone_id: "",
				party_ids: [],
				lga_id: "",
				chart_type: "",
				state_codes: [],
				prepare_by: "",
				constituency_id: "",
				elective_position_id: "",
				advert_type_id: "",
				date_range: "",
				start_date: "",
				end_date: "",
				form_type: "form_ec16f",
			},
		};
	},
	watch: {
		"form.date_range": function (val) {
			if (val != "custom") {
				this.form.start_date = "";
				this.form.end_date = "";
			}
		},
	},
	computed: {
		...mapGetters({
			states: "settings/states",
			parties: "settings/parties",
			constituencies: "settings/federal_constituencies",
			positions: "settings/elective_positions",
			advert_types: "settings/advert_types",
			zones: "settings/zones",
			lgas: "settings/lgas",
			submissions: "settings/report_submission",
		}),
	},

	methods: {
		...mapActions({
			getFederalConstituencies: "settings/getFederalConstituencies",
			getParties: "settings/getParties",
			getElectivePositions: "settings/getElectivePositions",
			getStates: "settings/getStates",
			getLgas: "settings/getLgas",
			getZones: "settings/getZones",
			getExpensesList: "analytics/getExpensesList",
		}),

		listParties() {
			let params = { keys: JSON.stringify(["id", "abbreviation"]) };
			this.getParties(params);
		},
		listStates() {
			let params = { keys: JSON.stringify(["id", "name", "code"]) };
			this.getStates(params);
		},
		listFederalConstituencies() {
			let params = { keys: JSON.stringify(["id", "name"]) };
			this.getFederalConstituencies(params);
		},
		async getByState(e) {
			let params = { state_code: e.target.value };
			this.emitInput(e);

			await this.getLgas(params);
			await this.getFederalConstituencies(params);
		},

		async search() {
			await this.getExpensesList(this.form).then(() => {
				this.$emit("plot-chart");
				Datatable("#data-table");
			});
		},

		prepareData(payload) {
			return payload.map((data) => {
				if (Object.prototype.hasOwnProperty.call(data, "abbreviation")) {
					return {
						id: data.id,
						title: data.abbreviation,
					};
				}

				if (Object.prototype.hasOwnProperty.call(data, "name")) {
					return {
						id: data.code,
						title: data.name,
					};
				}
			});
		},

		clearForm() {
			this.form.zone_id = "";
			this.form.lga_id = "";
			this.form.chart_type = "";
			this.form.state_codes = [];
			this.form.constituency_id = "";
			this.form.elective_position_id = "";
			this.form.advert_type_id = "";
			this.form.date_range = "";
		},
	},

	created() {
		if (this.constituencies && this.constituencies.length) {
			this.$store.commit("settings/setFederalConstituencies", []);
		}
	},

	mounted() {
		if (!this.parties.length) {
			this.listParties();
		}
		if (!this.positions.length) {
			this.getElectivePositions();
		}

		if (!this.advert_types.length) {
			this.$store.dispatch("settings/getAdvertTypes");
		}

		if (!this.submissions.length) {
			this.$store.dispatch("settings/getReportSubmission");
		}

		this.getZones({ q: "only" });
		this.listStates();

		initializeMultipleSelect("party_id", this.prepareData(this.parties));
		initializeMultipleSelect("state_code", this.prepareData(this.states));
	},
};
</script>
<style scoped>
.form-select,
.btn-info {
	padding: 8px 8px !important;
}
</style>
