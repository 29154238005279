<template>
	<div class="col-xl-12">
		<div class="card table-card">
			<div class="card-body px-0 py-0">
				<div id="container" style="height: 400px"></div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
var Highcharts = require("highcharts");
// Load module after Highcharts is loaded
require("highcharts/modules/exporting")(Highcharts);
export default {
	props: {
		response: {
			type: Object,
		},
	},
	computed: {
		...mapGetters({
			user: "auth/currentUser",
		}),
	},
	methods: {
		plotHightChart() {
			if (!Object.keys(this.response).length) {
				return;
			}

			Highcharts.chart("container", {
				chart: {
					type: "column",
				},
				title: {
					text: "PRESIDENTIAL ELECTION EXPENSES CHANNELS & AMOUNT SPENT",
				},
				subtitle: {
					text: "Source: FORM EC16F",
				},
				xAxis: {
					categories: this.response.expenses,
					crosshair: true,
				},
				yAxis: {
					title: {
						useHTML: true,
						text: "TOTAL AMOUNT",
					},
				},
				tooltip: {
					headerFormat:
						"<span style='font-size:10px'>{point.key}</span><table>",
					pointFormat:
						"<tr><td style='color:{series.color};padding:0'>{series.name}: </td>" +
						"<td style='padding:0'><b>{point.y:.1f}</b></td></tr>",
					footerFormat: "</table>",
					shared: true,
					useHTML: true,
				},
				plotOptions: {
					column: {
						pointPadding: 0.2,
						borderWidth: 0,
					},
				},
				series: this.response.expense_data,
			});
		},
	},

	mounted() {
		this.plotHightChart();
	},
};
</script>
