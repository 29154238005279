import { incomeList } from "@/api/reports";
import store from "@/store";

// Get expenses list
export const getIncomeList= (commit, payload) => {
  return new Promise((resolve) => {
    incomeList(payload).then((response) => {
      if (response.status_code === 200) {
        store.commit("reports/setIncome", response.data);
      }
      resolve();
    });
  });
};
