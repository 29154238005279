<template>
	<div class="table-responsive">
		<fieldset class="dt-responsive table-responsive">
			<legend>COST OF COVERAGE BY ELECTRONIC MEDIA:</legend>
			<table id="data-table" class="table table-bordered wrap">
				<thead>
					<tr>
						<th>PARTY</th>
						<th>POSITION</th>
						<th>CANDIDATE</th>
						<th>STATE</th>
						<!-- <th>CAMPAIGN</th> -->
						<th>MEDIA HOUSE</th>
						<th>DATE</th>
						<th>
							DURATION OF <br />
							COVERAGE
						</th>
						<th>SLOT</th>
						<th>RATE</th>
						<th>TOTAL COST</th>
						<th>REMARKS</th>
						<th>RECORDED BY</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="form in forms" :key="form.id">
						<td>{{ form.party ? form.party.abbreviation : null }}</td>
						<td>
							<span class="badge bg-primary">{{
								form.position ? form.position.name : null
							}}</span>
						</td>
						<td>{{ form.candidate }}</td>
						<td>{{ form.state ? form.state.name : null }}</td>
						<td>{{ form.media_house }}</td>
						<td>{{ form.date_reported }}</td>
						<td>{{ form.duration }}</td>
						<td>{{ form.slots }}</td>
						<td>{{ form.rate }}</td>
						<td>{{ form.unit_cost }}</td>
						<td>
							<span class="badge bg-danger">{{ form.total_cost }}</span>
						</td>
						<td>{{ form.remarks }}</td>
						<td>{{ form.user ? form.user.name : null }}</td>
					</tr>
				</tbody>
			</table>
		</fieldset>
	</div>
</template>
<script>
export default {
	props: {
		forms: {
			type: Array,
			required: true,
		},
	},
};
</script>
<style scoped>
fieldset {
	background-color: #eeeeee;
}

legend {
	background-color: #29623c;
	color: #fff;
	padding: 5px 10px;
}
</style>
