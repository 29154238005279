import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";

export default {
  namespaced: true, //eg To use in any component, use settings/getters
  state,
  getters,
  mutations,
};
