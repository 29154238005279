import Request from "@/Request";

export function advertTypesList(payload) {
  return Request({
    url: "/settings/advert-types",
    method: "get",
    params: payload,
  });
}

export function electivePositionsList(payload) {
  return Request({
    url: "/settings/elective-positions",
    method: "get",
    params: payload,
  });
}

export function zonesList(payload) {
  return Request({
    url: "/settings/zones",
    method: "get",
    params: payload,
  });
}

export function rolesList(payload) {
  return Request({
    url: "/settings/roles",
    method: "get",
    params: payload,
  });
}

export function partiesList(payload) {
  return Request({
    url: "/settings/parties",
    method: "get",
    params: payload,
  });
}

export function partyOnDashboard(payload) {
  return Request({
    url: "/settings/parties/dashboardParties",
    method: "post",
    data: payload,
  });
}

export function partiesCreate(payload) {
  return Request({
    url: "/settings/parties",
    method: "post",
    data: payload,
  });
}

export function partiesUpdate(payload) {
  return Request({
    url: "/settings/parties/" + payload.id,
    method: "put",
    data: payload,
  });
}

export function statesList(payload) {
  return Request({
    url: "/settings/states",
    method: "get",
    params: payload,
  });
}

export function expendituresList(payload) {
  return Request({
    url: "/settings/expenditures",
    method: "get",
    params: payload,
  });
}

export function federalConstituenciesList(payload) {
  return Request({
    url: "/settings/federal-constituencies",
    method: "get",
    params: payload,
  });
}

export function stateConstituenciesList(payload) {
  return Request({
    url: "/settings/state-constituencies",
    method: "get",
    params: payload,
  });
}

export function lgasList(payload) {
  return Request({
    url: "/settings/lgas",
    method: "get",
    params: payload,
  });
}

export function mediaChannelsList(payload) {
  return Request({
    url: "/settings/media-channels",
    method: "get",
    params: payload,
  });
}

export function SenatorialZonesList(payload) {
  return Request({
    url: "/settings/senatorial-zones",
    method: "get",
    params: payload,
  });
}

export function usersList(payload) {
  return Request({
    url: "/settings/users",
    method: "get",
    params: payload,
  });
}

export function reportSubmissionList(payload) {
  return Request({
    url: "/settings/report-submission",
    method: "get",
    params: payload,
  });
}

export function reportSubmissionStatus(payload) {
  return Request({
    url: "/settings/report-submission/status",
    method: "get",
    params: payload,
  });
}

export function reportSubmissionCreate(payload) {
  return Request({
    url: "/settings/report-submission",
    method: "post",
    data: payload,
  });
}

export function reportSubmissionUpdate(payload) {
  return Request({
    url: "/settings/report-submission/" + payload.id,
    method: "put",
    data: payload,
  });
}

export function userCreate(payload) {
  return Request({
    url: "/settings/users",
    method: "post",
    data: payload,
  });
}

export function userUpdate(payload) {
  return Request({
    url: "/settings/users/" + payload.id,
    method: "put",
    data: payload,
  });
}

export function userUpdatePassword(payload) {
  return Request({
    url: `/settings/users/${payload.id}/password`,
    method: "put",
    data: payload,
  });
}

export function locationsByState(payload) {
  return Request({
    url: "/settings/locations-by-state",
    method: "get",
    params: payload,
  });
}


export function supportingDocumentsList(payload) {
  return Request({
    url: "/settings/supporting-documents",
    method: "get",
    params: payload,
  });
}

export function supportingDocumentsStatus(payload) {
  return Request({
    url: "/settings/supporting-documents/status",
    method: "get",
    params: payload,
  });
}

export function supportingDocumentsCreate(payload) {
  return Request({
    url: "/settings/supporting-documents",
    method: "post",
    data: payload,
  });
}

export function supportingDocumentsUpdate(payload) {
  return Request({
    url: "/settings/supporting-documents/" + payload.id,
    method: "put",
    data: payload,
  });
}
