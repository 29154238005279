<template>
	<ul class="nav pcoded-inner-navbar">
		<li class="nav-item pcoded-menu-caption">
			<label style="color: #268e49; font-size: 20px; font-weight: 700"
				>Navigation</label
			>
		</li>

		<!-- Menus start here -->
		<template v-for="(link, index) in links">
			<li
				v-if="user_has_permission(link.permission)"
				:class="{
					'pcoded-hasmenu': link.has_sub_menu,
					'pcoded-trigger': link.open,
					active: $route.path.includes(link.url),
				}"
				:key="'menu_' + index"
			>
				<a @click.prevent="gotoUrl(link)" :href="link.url" class="nav-link">
					<span class="pcoded-micon"
						><i class="feather" :class="link.icon"></i></span
					><span class="pcoded-mtext">{{ link.title }}</span>
				</a>
				<ul
					v-if="link.has_sub_menu"
					class="pcoded-submenu"
					style="padding: 0"
					:class="{
						'pcoded-showmenu': link.open,
					}"
				>
					<!-- Submenu starts -->
					<template v-for="(sub_link, key) in link.sub_menus">
						<li
							v-if="
								sub_link.permission && user_has_permission(sub_link.permission)
							"
							:key="'sub_menu_' + key"
							:class="{
								'pcoded-hasmenu': sub_link.has_sub_menu,
								'pcoded-trigger': sub_link.open,
								active: $route.path.includes(sub_link.url),
							}"
						>
							<a @click.prevent="gotoUrl(sub_link)" class="has-ripple">
								{{ sub_link.title }}
								<span
									class="ripple ripple-animate"
									style="height: 230px; opacity: 0.4; left: 189px; top: -96px"
								></span>
							</a>

							<ul
								v-if="sub_link.has_sub_menu"
								class="pcoded-submenu"
								style="padding: 0"
								:class="{ 'pcoded-showmenu': sub_link.open }"
							>
								<template v-for="(sub_link2, sub) in sub_link.sub_menus">
									<li
										v-if="
											sub_link2.permission &&
											user_has_permission(sub_link2.permission)
										"
										:key="'sub' + sub"
										@click="sub_link.open = !sub_link.open"
										:class="{
											active: $route.path.includes(sub_link.url),
										}"
									>
										<router-link :to="sub_link2.url" class="has-ripple"
											>{{ sub_link2.title }}
											<span
												class="ripple ripple-animate"
												style="
													height: 230px;
													width: 230px;
													animation-duration: 0.7s;
													animation-timing-function: linear;
													background: rgb(70, 128, 255);
													opacity: 0.4;
													top: -100px;
													left: 36px;
												"
											></span
										></router-link>
									</li>
								</template>
							</ul>
						</li>
					</template>
					<!-- End of submenu -->
				</ul>
			</li>
		</template>
		<!-- Menus ends here -->

		<ModalLogout />
	</ul>
</template>
<script>
import { mapGetters } from "vuex";
import ModalLogout from "@/components/ModalLogout.vue";
import { EventBus } from "@/utils/event_bus.js";
import { userHasPermission } from "@/utils/permissions";

export default {
	components: {
		ModalLogout,
	},
	computed: {
		...mapGetters({
			links: "navigation/links",
		}),
	},
	methods: {
		gotoUrl(link) {
			if (link.title === "Log Out") {
				return EventBus.$emit("toggle-logout-modal", "show");
			}

			if (link.has_sub_menu) {
				return (link.open = !link.open);
			}

			this.$router.push(link.url).catch(() => {});
		},
		user_has_permission(permission) {
			if (permission === "dashboard") {
				return true;
			}

			return userHasPermission(permission);
		},
	},
};
</script>
<style scoped>
.pcoded-showmenu {
	display: block !important;
}
a {
	cursor: pointer !important;
}
</style>
