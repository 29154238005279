<template>
	<!-- [ Main Content ] start -->
	<div v-if="response" class="row">
		<div class="row" style="margin-bottom: 0">
			<div class="col-md-10"></div>
			<div class="card col-md-2">
				<div class="text-end">
					<div class="btn-group card-body" style="padding: 5px">
						<button
							class="btn waves-effect waves-light btn-sm btn-outline-secondary dropdown-toggle has-ripple"
							type="button"
							data-bs-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							--Filter By Position--<span
								class="ripple ripple-animate"
								style="
									height: 89.3438px;
									width: 89.3438px;
									animation-duration: 0.7s;
									animation-timing-function: linear;
									background: rgb(255, 255, 255);
									opacity: 0.4;
									top: -28.2344px;
									left: 1.68747px;
								"
							></span>
						</button>
						<div class="dropdown-menu" style="">
							<!-- <a
								v-for="position in positions"
								@click.prevent="getResultByFilter(position)"
								class="dropdown-item"
								href="#!"
								:key="'po_' + position.id"
								>{{ position.name }}</a
							> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- Zone statistics -->
		<template v-if="user_can_view_zonal_data">
			<div
				v-for="(party, index) in response.parties"
				:class="{
					'col-md-12': response.parties.length === 1,
					'col-md-6': response.parties.length === 2,
					'col-md-4': response.parties.length === 3,
					'col-md-3': response.parties.length >= 4,
				}"
				:key="party"
			>
				<div class="card">
					<div class="card-body text-center">
						<h5 class="m-t-10">
							<span :style="'color:' + response.colours[index]"
								>{{ party }} PRES. ELECTION ZONAL EXPENSES</span
							>
						</h5>
						<span style="font-size: 10px; font-weight: 700"
							>SOURCE: FORMEC16F</span
						>
						<div class="table-responsive">
							<table class="table">
								<thead></thead>
								<tbody style="border-bottom: 2px solid black">
									<tr
										v-for="(values, zone, index) in response.party_data[party]"
										:key="party + index"
									>
										<td>{{ zone }}</td>
										<td>{{ values.total_cost }}</td>
									</tr>
								</tbody>
							</table>
						</div>
						<!-- <button
								class="btn btn-primary btn-sm btn-round"
								data-bs-toggle="modal"
								data-title="Operation"
								data-bs-target="#modal-report"
							>
								View Report
							</button> -->
					</div>
					<div :id="party"></div>
				</div>
			</div>
		</template>

		<!-- Expense chart -->
		<ExpensesChart :response="response" />
	</div>
	<!-- [ Main Content ] end -->
</template>
<script>
import $ from "jquery";
import ApexCharts from "apexcharts";
import ExpensesChart from "./ExpensesChart.vue";
import { userHasPermission } from "@/utils/permissions";

export default {
	components: {
		ExpensesChart,
	},
	data() {
		return {
			response: null,
		};
	},
	computed: {
		user_can_view_zonal_data() {
			return userHasPermission("staff-zonal-data-view");
		},
	},
	methods: {
		plotCharts(id, color) {
			$(function () {
				var options = {
					chart: {
						type: "area",
						height: 50,
						sparkline: {
							enabled: true,
						},
					},
					dataLabels: {
						enabled: false,
					},
					colors: [color],
					fill: {
						type: "solid",
						opacity: 0.3,
					},
					markers: {
						size: 3,
						opacity: 0.9,
						colors: "#fff",
						strokeColor: color,
						strokeWidth: 2,
						hover: {
							size: 7,
						},
					},
					stroke: {
						curve: "straight",
						width: 3,
					},
					series: [
						{
							name: "series1",
							data: [
								25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54, 25, 66, 41, 89, 63,
								54, 25, 66, 41, 89,
							],
						},
					],
					tooltip: {
						fixed: {
							enabled: false,
						},
						x: {
							show: false,
						},
						y: {
							title: {
								formatter: function () {
									return "Operation ";
								},
							},
						},
						marker: {
							show: false,
						},
					},
				};
				var chart = new ApexCharts(document.querySelector("#" + id), options);
				chart.render();
			});
		},

		drawCharts() {
			if (!this.response.parties.length) {
				return;
			}

			this.response.parties.forEach((party, index) => {
				this.plotCharts(party, this.response.colours[index]);
			});
		},

		async getDashboardData() {
			await this.$store
				.dispatch("dashboard/getDashboardData")
				.then((response) => {
					this.response = response;

					if (this.user_can_view_zonal_data) {
						this.drawCharts();
					}
				});
		},
	},
	mounted() {
		this.getDashboardData();
	},
};
</script>
<style scoped>
.table td {
	padding: 0.5rem 0.75rem !important;
	text-align: left !important;
}
</style>
