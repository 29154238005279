import { 
  createBillBoardForm, createElectronicMediaForm, createPrintMediaForm, createMediaCoverageForm, 
  createCampaignForm, formList, formById, formDeleteById
} from "@/api/form_ec16f";
import store from "@/store";

export const addBillBoardForm = (commit, payload) => {
  return new Promise((resolve) => {
    createBillBoardForm(payload).then((response) => {
      if (response.status_code === 201) {
        resolve(response.data);
      }
    });
  });
};

export const addElectronicMediaForm = (commit, payload) => {
  return new Promise((resolve) => {
    createElectronicMediaForm(payload).then((response) => {
      if (response.status_code === 201) {
        resolve(response.data);
      }
    });
  });
};

export const addPrintMediaForm = (commit, payload) => {
  return new Promise((resolve) => {
    createPrintMediaForm(payload).then((response) => {
      if (response.status_code === 201) {
        resolve(response.data);
      }
    });
  });
};

export const addMediaCoverageForm = (commit, payload) => {
  return new Promise((resolve) => {
    createMediaCoverageForm(payload).then((response) => {
      if (response.status_code === 201) {
        resolve(response.data);
      }
    });
  });
};

export const addCampaignForm = (commit, payload) => {
  return new Promise((resolve) => {
    createCampaignForm(payload).then((response) => {
      if (response.status_code === 201) {
        resolve("success");
      }
    });
  });
};

export const getFormList = (commit, payload) => {
  return new Promise((resolve) => {
    formList(payload).then((response) => {
      if (response.status_code === 200) {
        store.commit("form_ec16f/setForms", response.data);
      }
      resolve();
    });
  });
};

export const getFormById = (commit, payload) => {
  return new Promise((resolve) => {
    formById(payload).then((response) => {
      if (response.status_code === 200) {
        store.commit("form_ec16e/setSingleForm", response.data);
      }
      resolve();
    });
  });
};

export const deleteById = (commit, payload) => {
  return new Promise((resolve) => {
    formDeleteById(payload).then((response) => {
      if (response.status_code === 201) {
        resolve("success");
      }
    });
  });
};
