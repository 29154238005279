<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<div
						v-if="toggled && form.visibilities.length"
						class="mb-2"
						align="left"
					>
						<form @submit.prevent="submitSelected()" action="" method="post">
							<LoadingButton class="btn-info" style="border-radius: 6px">
								Submit Selection</LoadingButton
							>
						</form>
					</div>
					<div class="table-responsive">
						<table
							id="report-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<th v-if="can_update_party" class="text-center">
										Dashboard <br />
										Visibility Status
									</th>
									<th>Icon</th>
									<th>Abbreviation</th>
									<th>Description</th>
									<th>Color Code</th>
									<th>Status</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="party in parties" :key="'p' + party.id">
									<td v-if="can_update_party" class="text-center">
										<div class="form-check form-switch">
											<input
												class="form-check-input"
												type="checkbox"
												:value="party.id"
												:id="'div' + party.id"
												v-model="form.visibilities"
												@change="toggled = true"
											/>
											<label class="form-check-label" :for="'div' + party.id">{{
												form.visibilities.includes(party.id)
													? "Visible"
													: "Hidden"
											}}</label>
										</div>
									</td>
									<td>
										<img :src="party.logo" height="60px" width="60px" />
									</td>
									<td>{{ party.abbreviation }}</td>
									<td>{{ party.description }}</td>

									<td>
										<strong>{{ party.color_code }}</strong>
									</td>
									<td>
										<span
											class="badge"
											:class="
												party.status == 'Active' ? 'bg-success' : 'bg-danger'
											"
											>{{ party.status }}</span
										>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- customar project  end -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import LoadingButton from "@/components/LoadingButton.vue";
import { userHasPermission } from "@/utils/permissions";
// import PartyModal from "./PartyModal.vue";

export default {
	components: {
		LoadingButton,
		// PartyModal,
	},

	data() {
		return {
			party: null,
			toggled: false,
			form: {
				visibilities: [],
			},
		};
	},

	computed: {
		...mapGetters({
			parties: "settings/parties",
		}),
		can_update_party() {
			return userHasPermission("parties-update");
		},
	},

	methods: {
		submitSelected() {
			this.$store.dispatch("settings/dashboardParty", this.form);
		},

		getParties() {
			if (this.parties.length) {
				this.setPartyIDs();
			} else {
				this.$store
					.dispatch("settings/getParties")
					.then(() => this.setPartyIDs());
			}
		},

		setPartyIDs() {
			if (this.parties && this.parties.length <= 0) {
				return;
			}

			this.form.visibilities = this.parties
				.filter((party) => party.dashboard_visibility)
				.map((party) => party.id);

			this.toggled = false;
		},
	},

	mounted() {
		this.getParties();
	},
};
</script>
