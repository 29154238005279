<template>
	<Modal :title="title">
		<Alert></Alert>

		<form @submit.prevent="submitForm()">
			<div class="form-group">
				<label class="form-label" for="user_type"
					>Please Select Account Type<span class="text-danger">*</span></label
				>
				<select v-model="user_type" class="form-select" id="user_type" required>
					<option value="">--select--</option>
					<option value="inec" v-if="user_is_staff_admin">INEC Staff</option>
					<option value="party">Party Staff</option>
				</select>
			</div>

			<div v-if="user_type" class="row">
				<div class="col-md-12">
					<div class="form-group">
						<label class="control-label" for="name"
							>Full Name<span class="text-danger">*</span></label
						>
						<input
							v-model="form.name"
							type="text"
							class="form-control"
							id="name"
							required
						/>
					</div>
				</div>
				<div class="col-md-12">
					<div class="form-group">
						<label class="control-label" for="name"
							>Email<span class="text-danger">*</span></label
						>
						<input
							v-model="form.email"
							type="email"
							class="form-control"
							id="email"
							required
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label" for="phone"
							>Phone Number<span class="text-danger">*</span></label
						>
						<input
							v-model="form.phone"
							type="tel"
							class="form-control"
							id="phone"
							placeholder="Eg. 08067690774"
							required
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label" for="designation">Designation:</label>
						<input
							v-model="form.designation"
							type="text"
							class="form-control"
							id="designation"
							placeholder="Eg. HOD"
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="form-label" for="gender"
							>Gender<span class="text-danger">*</span></label
						>
						<select
							v-model="form.gender"
							class="form-select"
							id="gender"
							required
						>
							<option value="">--select--</option>
							<option value="male">Male</option>
							<option value="female">Female</option>
						</select>
					</div>
				</div>
				<div v-if="user_is_staff_admin || user_is_party_admin" class="col-md-6">
					<div class="form-group">
						<label class="form-label" for="role"
							>Role<span class="text-danger">*</span></label
						>
						<select v-model="form.role" class="form-select" id="role" required>
							<option value="">--select--</option>
							<template v-if="user_is_party_admin || user_type == 'party'">
								<option
									v-for="role in partyRoles"
									:value="role.id"
									:key="'uparty_' + role.id"
								>
									{{ role.display_name }}
								</option>
							</template>
							<template v-else>
								<option
									v-for="role in otherRoles"
									:value="role.id"
									:key="'uinec_' + role.id"
								>
									{{ role.display_name }}
								</option>
							</template>
						</select>
					</div>
				</div>
				<div
					v-if="user_can_create_party && user_type == 'party'"
					class="col-md-6"
				>
					<div class="form-group">
						<label class="form-label" for="party_id"
							>Choose Party<span class="text-danger">*</span></label
						>
						<select
							v-model="form.party_id"
							class="form-select"
							id="party_id"
							required
						>
							<option value="">--select--</option>
							<option
								v-for="party in parties"
								:value="party.id"
								:key="'party_' + party.id"
							>
								{{ party.abbreviation }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="form-label" for="state_code"
							>Choose State<span class="text-danger">*</span></label
						>
						<select
							v-model="form.state_code"
							@change="getByState($event.target.value)"
							class="form-select"
							id="state_code"
							required
						>
							<option value="">--select--</option>
							<option
								v-for="state in states"
								:value="state.code"
								:key="'state_m_' + state.code"
							>
								{{ state.name }}
							</option>
						</select>
					</div>
				</div>
				<div
					v-if="form.state_code != 'NA' && Object.keys(lgas).length"
					class="col-md-6"
				>
					<div class="form-group">
						<label class="form-label" for="lga_id">L.G.A:</label>
						<select v-model="form.lga_id" class="form-select" id="lga_id">
							<option value="">--select--</option>
							<option
								v-for="lga in lgas"
								:value="lga.id"
								:key="'lga_' + lga.id"
							>
								{{ lga.name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-md-6 mb-3">
					<div class="form-group">
						<label class="form-label" for="status"
							>Status<span class="text-danger">*</span></label
						>
						<select
							v-model="form.status"
							class="form-select"
							id="status"
							required
						>
							<option value="">--select--</option>
							<option value="active">Active</option>
							<option value="inactive">In-Active</option>
						</select>
					</div>
				</div>
				<!-- <div class="col-md-6 mb-3">
						<label class="form-label" for="password">Password</label>
						<div class="input-group">
							<div
								class="input-group-prepend"
								style="cursor: pointer"
								@click="showPassword = !showPassword"
							>
								<span class="input-group-text" id="password"
									><i
										class="fa fa-1x"
										:class="[showPassword ? 'fa-eye' : 'fa-eye-slash']"
									></i
								></span>
							</div>
							<input
								v-model="form.password"
								:type="[showPassword ? 'text' : 'password']"
								class="form-control"
								id="password"
								placeholder="Enter password"
							/>
						</div>
					</div> -->
			</div>

			<div class="col-sm-12" style="border-top: 1px solid #e3e3e3">
				<div align="right" style="margin-top: 15px">
					<button
						@click.prevent="toggleModal('hide')"
						class="btn btn-secondary"
						style="margin-right: 5px; border-radius: 6px"
					>
						Cancel
					</button>
					<LoadingButton
						v-if="user_type"
						class="btn-info"
						style="border-radius: 6px"
					>
						Submit</LoadingButton
					>
				</div>
			</div>
		</form>
	</Modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
import Alert from "@/components/Alert.vue";
import { EventBus } from "@/utils/event_bus.js";
import { mapGetters, mapActions } from "vuex";
import LoadingButton from "@/components/LoadingButton.vue";
import {
	userHasPermission,
	userIsParty,
	userIsPartyAdmin,
	userIsStaffAdmin,
} from "@/utils/permissions";

export default {
	props: {
		user: {
			type: Object,
			default: null,
		},
	},
	components: {
		Modal,
		Alert,
		LoadingButton,
	},
	data() {
		return {
			user_type: "",
			role_name: "",
			showPassword: false,
			title: "Add new user",
			form: {
				name: "",
				email: "",
				state_code: "",
				lga_id: "",
				party_id: "",
				phone: "",
				designation: "",
				gender: "",
				role: "",
				status: "active",
				// password: "",
				// _method: "",
				id: null,
			},
		};
	},

	watch: {
		user: {
			// immediate: true,
			handler(user) {
				this.form.name = user.name;
				this.form.email = user.email;
				this.form.state_code = user.state_code;
				this.form.lga_id = user.lga_id;
				this.form.party_id = user.party_id;
				this.form.phone = user.phone;
				this.form.designation = user.designation;
				this.form.gender = user.gender.toLowerCase();
				this.form.role = user.role_id;
				this.form.status = user.status.toLowerCase();
				this.form.id = user.id;
				// this.form._method = "PUT";
				this.title = "Edit User";
				if (user.lga_id == 0) {
					this.$store.commit("settings/setLgas", []);
				}

				if (user.party_id) {
					this.user_type = "party";
				} else {
					this.user_type = "inec";
				}

				this.getByState(user.state_code);
			},
		},
		user_type(val) {
			// this.clearInputs();
			if (val == "inec") {
				this.form.party_id = "";
			}
		},
		// "form.role"(val) {
		// 	let role = this.roles.find((role) => role.id == val);

		// 	if (!role) {
		// 		return;
		// 	}

		// 	this.role_name = role.name.toLowerCase();
		// },
		"form.state_code"(val) {
			if (val == "NA") {
				this.form.lga_id = "";
			}
		},
	},

	computed: {
		...mapGetters({
			states: "settings/states",
			roles: "settings/roles",
			parties: "settings/parties",
			lgas: "settings/lgas",
			error: "shared/getErrorResponse",
		}),
		user_is_party() {
			return userIsParty();
		},
		user_is_staff_admin() {
			return userIsStaffAdmin();
		},
		user_is_party_admin() {
			return userIsPartyAdmin();
		},
		user_can_create_party() {
			return userHasPermission("parties-create");
		},
		partyRoles() {
			return this.roles.filter((role) => {
				return role.name.includes("party");
			});
		},
		otherRoles() {
			return this.roles.filter((role) => {
				return !role.name.includes("party");
			});
		},
	},

	methods: {
		...mapActions({
			getStates: "settings/getStates",
			getRoles: "settings/getRoles",
			getParties: "settings/getParties",
			getLgas: "settings/getLgas",
			createUser: "settings/createUser",
			updateUser: "settings/updateUser",
		}),

		async getByState(state_code) {
			if (!state_code || state_code == "NA") {
				return;
			}

			let params = { state_code: state_code };
			await this.getLgas(params);
		},

		async listParties() {
			let params = { keys: JSON.stringify(["id", "abbreviation"]) };
			await this.getParties(params);
		},

		async listRoles() {
			await this.getRoles();
		},

		togglePassword() {
			let password = document.getElementById("password");

			if (password.type == "text") {
				password.type = "password";
			} else {
				password.type = "text";
			}
		},

		async submitForm() {
			var form = null;

			if (this.user && this.form.id) {
				form = this.updateUser(this.form);
			} else {
				form = this.createUser(this.form);
			}

			await form.then((res) => {
				if (res && res === "success") {
					this.$store.dispatch("settings/getUsers");
					this.clearInputs();
					this.toggleModal("hide");
				}
			});
		},

		toggleModal(type) {
			EventBus.$emit("toggle-modal", type);
		},

		clearInputs() {
			this.form.name = "";
			this.form.email = "";
			this.form.state_code = "";
			this.form.lga_id = "";
			this.form.party_id = "";
			this.form.phone = "";
			this.form.designation = "";
			this.form.gender = "";
			this.form.role = "";
			// this.form.password = "";
			this.form.status = "active";
			this.showPassword = false;
			this.form.id = null;
			this.user_type = "";
			// this.form._method = "";
			this.title = "Add New User";

			this.$store.commit("settings/setLgas", []);

			if (this.user_is_party_admin) {
				this.user_type = "party";
			}
		},
	},

	mounted() {
		if (this.user_is_party) {
			this.form.party_id = this.user_party_id;
			this.form.role = "party";
		}
		if (!this.states.length) {
			this.getStates();
		}
		if (!this.parties.length) {
			this.listParties();
		}
		if (!this.roles.length) {
			this.listRoles();
		}

		if (this.error) {
			EventBus.$emit("toggle-modal", "hide");
		}

		EventBus.$on("toggle-modal", (type) => {
			if (type === "hide" && !this.error) {
				this.clearInputs();
			}
		});

		EventBus.$on("btn-type", (type) => {
			if (type === "add") {
				this.clearInputs();
			}
		});
	},
};
</script>
<style scoped>
.form-control {
	padding: 5px !important;
}
</style>
