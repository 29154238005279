<template>
	<table style="display: none" id="simple-data-table">
		<thead>
			<tr>
				<th
					v-for="(head, index) in response.tbl_heads.chart_heads"
					:key="'tt' + index"
				>
					{{ head }}
				</th>
			</tr>
		</thead>
		<tbody>
			<tr v-for="(expense, key) in response.expenses" :key="'kk' + key">
				<td>{{ expense.val1 }}</td>
				<td>{{ expense.total }}</td>
			</tr>
		</tbody>
	</table>
</template>
<script>
export default {
	props: {
		response: {
			required: true,
			type: Object,
		},
	},
};
</script>
