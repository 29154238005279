import { login, logout } from "@/api/auth";
import store from "@/store";

// Handles user login
export const Login = (commit, payload) => {
  return new Promise((resolve) => {
    login(payload).then((response) => {
      
      if (response.status_code == 200) {
        store.commit("auth/login", response.data);
        resolve("success");
      }
    })
  });
};

// Handles user logout
export const Logout = () => {
  return new Promise((resolve) => {
    logout().then((response) => {
      
      if (response.status_code == 200) {
        store.commit("auth/logout");
        resolve("success");
      }
    })
  });
};
