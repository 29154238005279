<template>
	<div class="row">
		<div class="col-sm-12 col-md-5">
			<div
				class="dataTables_info"
				id="report-table_info"
				role="status"
				aria-live="polite"
			>
				Showing {{ paginator.from }} to {{ paginator.to }} of
				{{ paginator.total }} entries
				<!-- Showing {{ meta.from }} to {{ meta.to }} entries -->
			</div>
		</div>
		<div class="col-sm-12 col-md-7">
			<div
				class="dataTables_paginate paging_simple_numbers"
				id="report-table_paginate"
			>
				<ul class="pagination">
					<li
						class="paginate_button page-item previous"
						:class="{ disabled: paginator.current_page == 1 }"
						id="report-table_previous"
					>
						<a @click.prevent="gotoPage('previous')" href="#" class="page-link"
							>Previous</a
						>
					</li>
					<li class="paginate_button page-item active">
						<a href="#" class="page-link disabled">{{
							paginator.current_page
						}}</a>
					</li>
					<li
						class="paginate_button page-item next"
						:class="{ disabled: !paginator.has_more_pages }"
						id="report-table_next"
					>
						<a
							@click.prevent="gotoPage('next')"
							href="#"
							aria-controls="report-table"
							data-dt-idx="2"
							tabindex="0"
							class="page-link"
							>Next</a
						>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
// import { EventBus } from "@/utils/event_bus.js";
export default {
	props: {
		paginator: {
			type: Object,
		},
	},

	methods: {
		gotoPage(type) {
			this.$emit("goto-page", type);
		},
	},
};
</script>
