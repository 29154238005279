
export const setAdvertTypes = (state, payload) => {
  state.advert_types = payload;
};

export const setElectivePositions = (state, payload) => {
  state.elective_positions = payload;
};

export const setZones = (state, payload) => {
  state.zones = payload;
};

export const setParties = (state, payload) => {
  state.parties = payload;
};

export const setRoles = (state, payload) => {
  state.roles = payload;
};

export const setStates = (state, payload) => {
  state.states = payload;
};

export const setExpenditures = (state, payload) => {
  state.expenditures = payload;
};

export const setFederalConstituencies = (state, payload) => {
  state.federal_constituencies = payload;
};

export const setStateConstituencies = (state, payload) => {
  state.state_constituencies = payload;
};

export const setLgas = (state, payload) => {
  state.lgas = payload;
};

export const setChannels = (state, payload) => {
  state.channels = payload;
};

export const setSenatorialZones = (state, payload) => {
  state.senatorial_zones = payload;
};

export const setUsers = (state, payload) => {
  state.users = payload;
};

export const setReportSubmission = (state, payload) => {
  state.report_submission = payload;
};

export const setReportSubmissionStatus = (state, payload) => {
  state.report_submission_status = payload;
};

export const setSupportingDocuments = (state, payload) => {
  state.supporting_documents = payload;
};

export const setLocations = (state, payload) => {
  state.locations = payload;
};


