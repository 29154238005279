<template>
	<Modal title="Upload Bulk Income Report">
		<form @submit.prevent="saveFiles()">
			<div class="row">
				<div v-if="error" class="col-sm-12 alert alert-danger">
					<strong>{{ error }}</strong>
				</div>
				<div v-if="user_is_party && user_state_code === 'NA'" class="col-sm-12">
					<div class="form-group">
						<label class="form-label" for="state_code"
							>Choose State:<span class="text-danger">*</span></label
						>
						<select
							v-model="state_code"
							class="form-select"
							id="state_code"
							required
						>
							<option value="">--select--</option>
							<option
								v-for="state in states"
								:value="state.code"
								:key="'s' + state.id"
							>
								{{ state.name }}
							</option>
						</select>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="form-group">
						<label class="form-label" for="date_reported"
							>Reporting Date <span class="text-danger">*</span></label
						>
						<input
							v-model="date_reported"
							type="date"
							name="date_reported"
							id="date_reported"
							class="form-control"
							placeholder="Eg. dd/mm/yyyy"
							required
						/>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="form-group">
						<label class="control-label" for="file"
							>Choose excel file <span class="text-danger">*</span></label
						>
						<input
							type="file"
							@change="getFiles()"
							ref="files"
							id="file"
							class="form-control"
							accept=".xlsx, .xls, .csv"
							required
						/>
					</div>
				</div>
				<div class="col-sm-12" style="border-top: 1px solid #e3e3e3">
					<div align="right" style="margin-top: 15px">
						<button
							@click.prevent="toggleModal('hide')"
							class="btn btn-danger"
							style="margin-right: 5px"
						>
							Cancel
						</button>
						<LoadingButton class="btn-info">Submit</LoadingButton>
					</div>
				</div>
			</div>
		</form>
	</Modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
import { EventBus } from "@/utils/event_bus.js";
import { mapGetters, mapActions } from "vuex";
import { userIsParty, userIsStaffAdmin } from "@/utils/permissions";
import LoadingButton from "@/components/LoadingButton.vue";

export default {
	name: "UploadIncomeReport",
	components: {
		Modal,
		LoadingButton,
	},

	data() {
		return {
			state_code: this.user_state_code === "NA" ? "NA" : "",
			party_id: "",
			date_reported: "",
			files: null,
		};
	},

	computed: {
		...mapGetters({
			states: "settings/states",
			error: "shared/getErrorResponse",
		}),
		user_is_staff_admin() {
			return userIsStaffAdmin();
		},
		user_is_party() {
			return userIsParty();
		},
	},

	methods: {
		...mapActions({
			getStates: "settings/getStates",
			formImportCSV: "form_ec16d/formImportCSV",
		}),

		getFiles() {
			this.file = this.$refs.files.files[0];
		},

		async saveFiles() {
			let formData = new FormData();

			formData.append("csv_upload", this.file);
			formData.append("state_code", this.state_code);
			formData.append("party_id", this.party_id);
			formData.append("date_reported", this.date_reported);

			await this.formImportCSV(formData).then((res) => {
				if (res && res === "success") {
					this.clearInputs();
					this.toggleModal("hide");
				}
			});
		},

		toggleModal(type) {
			EventBus.$emit("toggle-modal", type);
		},

		clearInputs() {
			// this.state_code = this.user_state_code === "NA" ? "NA" : "";
			// this.party_id = "";
			this.date_reported = "";
			this.file = null;
		},
	},

	mounted() {
		if (!this.states.length) {
			this.getStates();
		}

		if (!this.user_is_staff_admin) {
			this.state_code = this.user_state_code;
		}

		if (this.user_is_party) {
			this.party_id = this.user_party_id;
		}

		EventBus.$on("toggle-modal", (type) => {
			if (type === "hide" && !this.error) {
				this.clearInputs();
			}
		});
	},
};
</script>
<style scoped>
.form-control {
	padding: 5px;
}
</style>
