<template>
	<Modal title="Edit Report">
		<form @submit.prevent="submitForm()">
			<Alert />
			<div class="row">
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label" for="candidate_name"
							>Candidate Full Name
						</label>
						<input
							v-model="form.candidate_name"
							name="candidate_name"
							id="candidate_name"
							type="text"
							class="form-control"
							required
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label" for="approved_by">Approved By</label>
						<input
							v-model="form.approved_by"
							name="approved_by"
							id="approved_by"
							type="text"
							class="form-control"
							required
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label" for="donor_name">Donor Name</label>
						<input
							v-model="form.donor_name"
							name="donor_name"
							id="donor_name"
							type="text"
							class="form-control"
							required
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label" for="donor_address"
							>Donor Address</label
						>
						<input
							v-model="form.donor_address"
							name="donor_address"
							id="donor_address"
							type="text"
							class="form-control"
							required
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="control-label" for="donor_occupation"
							>Donor Occupation</label
						>
						<input
							v-model="form.donor_occupation"
							name="donor_occupation"
							id="donor_occupation"
							type="text"
							class="form-control"
							required
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="form-label" for="contribution_description"
							>Contribution Value:
							<span class="text-danger" style="font-size: 12px"
								>(eg. 1,000,000)</span
							></label
						>
						<input
							v-model="form.contribution_value"
							class="form-control"
							type="text"
							id="contribution_value"
							name="contribution_value"
							placeholder="Eg. 10000"
							required
						/>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="form-label" for="contribution_description"
							>Contribution Description:</label
						>
						<textarea
							v-model="form.contribution_description"
							class="form-control"
							id="contribution_description"
							name="contribution_description"
							rows="1"
							placeholder="Enter description"
							required
						></textarea>
					</div>
				</div>
				<div class="col-md-6">
					<div class="form-group">
						<label class="form-label" for="contribution_type"
							>Contribution Type:</label
						>
						<select
							v-model="form.contribution_type"
							class="form-select"
							id="contribution_type"
							required
						>
							<option value="">--select--</option>
							<option value="cash">Cash</option>
							<option value="in_kind">In-Kind</option>
						</select>
					</div>
				</div>

				<div class="col-md-6">
					<div class="form-group">
						<label class="form-label" for="date_received">Received Date:</label>
						<input
							v-model="form.date_received"
							class="form-control"
							type="date"
							id="date_received"
							name="date_received"
							required
						/>
					</div>
				</div>

				<div class="col-sm-12" style="border-top: 1px solid #e3e3e3">
					<div align="right" style="margin-top: 15px">
						<button
							@click.prevent="toggleModal('hide')"
							class="btn btn-secondary"
							style="margin-right: 5px"
						>
							Cancel
						</button>
						<LoadingButton class="btn-info">Update</LoadingButton>
					</div>
				</div>
			</div>
		</form>
	</Modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
import { EventBus } from "@/utils/event_bus.js";
import LoadingButton from "@/components/LoadingButton.vue";
import { mustBeCurrency } from "@/utils/input_filter.js";
import Alert from "@/components/Alert.vue";

export default {
	props: {
		report: {
			type: Object,
			default: null,
		},
	},
	components: {
		Modal,
		LoadingButton,
		Alert,
	},

	data() {
		return {
			form: {
				donor_name: "",
				donor_address: "",
				donor_occupation: "",
				contribution_type: "",
				contribution_description: "",
				contribution_value: null,
				date_received: null,
				candidate_name: "",
				approved_by: "",
				batch_id: null,
				id: null,
			},
		};
	},

	watch: {
		report: {
			handler(report) {
				this.form.donor_name = report.donor_name;
				this.form.donor_address = report.donor_address;
				this.form.donor_occupation = report.donor_occupation;
				this.form.contribution_type = report.contribution_type.toLowerCase();
				this.form.contribution_description = report.contribution_description;
				this.form.contribution_value = report.contribution_value_unformatted;
				this.form.date_received = report.date_received_unformatted;
				this.form.candidate_name = report.candidate_name;
				this.form.approved_by = report.approved_by;
				this.form.batch_id = report.batch_id;
				this.form.id = report.id;
			},
		},
	},

	methods: {
		async submitForm() {
			await this.$store
				.dispatch("form_ec16d/editForm", this.form)
				.then((res) => {
					if (res && res === "success") {
						this.clearInputs();
						this.toggleModal("hide");
					}
				});
		},

		toggleModal(type) {
			EventBus.$emit("toggle-modal", type);
		},

		inputMustBeCurrency(input_id) {
			return mustBeCurrency(input_id);
		},

		clearInputs() {
			this.form.donor_name = "";
			this.form.donor_address = "";
			this.form.donor_occupation = "";
			this.form.contribution_type = "";
			this.form.contribution_description = "";
			this.form.contribution_value = "";
			this.form.date_received = null;
			this.form.candidate_name = "";
			this.form.approved_by = "";
			this.form.batch_id = null;
			this.form.id = null;
		},
	},
};
</script>
<style scoped>
.form-control {
	padding: 5px !important;
}
</style>
