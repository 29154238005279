<template>
	<div class="row">
		<!-- customar project  start -->
		<div class="col-xl-12">
			<div class="card">
				<div class="card-body">
					<div class="table-responsive">
						<table
							id="report-table"
							class="table table-bordered table-striped mb-0"
						>
							<thead>
								<tr>
									<th>S/N</th>
									<th>Elective Position</th>
									<th>Description</th>
									<th>Limit</th>
									<!-- <th>Actions</th> -->
								</tr>
							</thead>
							<tbody>
								<tr v-for="position in positions" :key="'p' + position.id">
									<td>{{ position.id }}.</td>
									<td>{{ position.name }}</td>
									<td>{{ position.description }}</td>
									<td>{{ position.limit }}</td>
									<!-- <td>
										<a href="#!" class="btn btn-info btn-sm"
											><i class="feather icon-edit"></i>&nbsp;Edit
										</a>
										<a href="#!" class="btn btn-danger btn-sm"
											><i class="feather icon-trash-2"></i>&nbsp;Delete
										</a>
									</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
		<!-- customar project  end -->
	</div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
	computed: {
		...mapGetters({
			positions: "settings/elective_positions",
		}),
	},

	mounted() {
		if (!this.positions.length) {
			this.$store.dispatch("settings/getElectivePositions");
		}
	},
};
</script>
