<template>
	<div>
		<div v-if="user_is_party">
			<form v-if="portal_status" @submit.prevent="saveAll()" method="post">
				<div class="card" style="border: 2px solid green">
					<div class="card-header">
						<h4 align="center" style="font-weight: 700" class="text-danger">
							{{ msg }}
						</h4>
					</div>
					<div class="card-block">
						<FormInput @set-input="setInput" />
						<div class="row">
							<div class="col-md-6">
								<div class="row mb-3">
									<label
										for="candidate_name"
										class="col-sm-4 col-form-label text-capitalize"
										>Name of Candidate:<span class="text-danger">*</span></label
									>
									<div class="col-sm-8">
										<input
											v-model="form.candidate_name"
											name="candidate_name"
											id="candidate_name"
											type="text"
											class="form-control"
											required
										/>
									</div>
								</div>
							</div>
							<div class="col-md-6">
								<div class="row mb-3">
									<label for="approved_by" class="col-sm-4 col-form-label"
										>Approved By:<span class="text-danger">*</span></label
									>
									<div class="col-sm-8">
										<input
											v-model="form.approved_by"
											name="approved_by"
											id="approved_by"
											type="text"
											class="form-control"
											required
										/>
									</div>
								</div>
							</div>
						</div>

						<div class="card">
							<div class="card-header" align="center">
								<strong class="text-warning mb-2"
									>Note: Include all Monetary and tangible non-monetary
									contributions and donations.
								</strong>
							</div>
							<div class="card-body row">
								<div class="col-md-4 form-group">
									<label for="donor_name" class="control-label text-capitalize"
										>DONOR NAME <span class="text-danger">*</span>:</label
									>
									<input
										type="text"
										v-model="form.donor_name"
										class="form-control"
										id="donor_name"
										name="donor_name"
										placeholder="Enter donor name"
										required
									/>
								</div>
								<div class="col-md-4 form-group">
									<label
										for="donor_address"
										class="control-label text-capitalize"
										>DONOR ADDRESS:</label
									>
									<input
										type="text"
										v-model="form.donor_address"
										class="form-control"
										id="donor_address"
										name="donor_address"
										placeholder="Enter donor address"
									/>
								</div>
								<div class="col-md-4 form-group">
									<label
										for="donor_occupation"
										class="control-label text-capitalize"
										>DONOR OCCUPATION:</label
									>
									<input
										type="text"
										v-model="form.donor_occupation"
										class="form-control"
										id="donor_occupation"
										name="donor_occupation"
										placeholder="Enter donor occupation"
									/>
								</div>
								<div class="col-md-4 form-group">
									<label
										for="contribution_type"
										class="control-label text-capitalize"
										>TYPE OF CONTRIBUTION (CASH/IN-KIND)
										<span class="text-danger">*</span>:</label
									>
									<select
										v-model="form.contribution_type"
										class="form-select"
										id="contribution_type"
										required
									>
										<option value="">--select--</option>
										<option value="cash">Cash</option>
										<option value="in_kind">In-Kind</option>
									</select>
								</div>
								<div class="col-md-4 form-group">
									<label
										for="contribution_description"
										class="control-label text-capitalize"
										>DESCRIPTION OF CONTRIBUTION
										<span class="text-danger">*</span>:</label
									>
									<input
										type="text"
										v-model="form.contribution_description"
										class="form-control"
										id="contribution_description"
										name="contribution_description"
										placeholder="Enter description"
										required
									/>
								</div>
								<div class="col-md-4 form-group">
									<label
										for="contribution_value"
										class="control-label text-capitalize"
										>TOTAL VALUE(₦) <span class="text-danger">*</span>:</label
									>
									<input
										type="text"
										v-model="form.contribution_value"
										class="form-control"
										id="contribution_value"
										name="contribution_value"
										placeholder="Eg. 1,000,000"
										required
									/>
								</div>
								<div class="col-md-4 form-group">
									<label
										for="contribution_value"
										class="control-label text-capitalize"
										>DATE RECEIVED <span class="text-danger">*</span>:</label
									>
									<input
										v-model="form.date_received"
										class="form-control"
										type="date"
										id="date_received"
										name="date_received"
										required
									/>
								</div>
								<div class="col-md-4 form-group">
									<label
										for="supporting_docs"
										class="control-label text-capitalize"
										>SUPPORTING DOCUMENTS <span class="text-danger">*</span>:
									</label>
									<select
										id="supporting_docs"
										class="t_select"
										v-model="form.supporting_docs"
										autocomplete="off"
										multiple
										required
									></select>
									<div class="text-danger text-sm" style="font-size: 10px">
										(This helps auditors request appropriate documents during
										auditing)
									</div>
								</div>
							</div>
							<div class="card-footer" align="center">
								<div>
									<router-link to="/forms/home/reporting" class="btn btn-link"
										>Cancel</router-link
									>
									<LoadingButton class="btn btn-info">Submit</LoadingButton>
								</div>
							</div>
						</div>

						<!-- table here -->
						<ReportingAddTable />
					</div>
				</div>
				<div v-if="form.portal_status" class="alert alert-danger">
					<strong class="text-center">{{ form.msg }}</strong>
				</div>
			</form>
			<div v-else class="alert alert-danger">
				<strong class="text-center">{{ msg }}</strong>
			</div>
		</div>
		<div v-else class="alert alert-danger">
			<strong class="text-center">You're not allowed to view this page!</strong>
		</div>
	</div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import FormInput from "@/components/Form.vue";
import ReportingAddTable from "./Partials/ReportingAddTable.vue";
import { EventBus } from "@/utils/event_bus.js";
import initializeMultipleSelect from "@/utils/multi_select.js";
import LoadingButton from "@/components/LoadingButton.vue";
import { mustBeCurrency } from "@/utils/input_filter.js";
import { userIsParty } from "@/utils/permissions";

export default {
	components: {
		FormInput,
		LoadingButton,
		ReportingAddTable,
	},

	data() {
		return {
			documents: [],
			form: {
				donor_name: "",
				donor_address: "",
				donor_occupation: "",
				contribution_type: "",
				contribution_description: "",
				contribution_value: null,
				date_received: null,
				party_id: "",
				state_code: "",
				elective_position_id: "",
				lga_id: "",
				date_reported: "",
				constituency_id: "",
				candidate_name: "",
				approved_by: "",
				supporting_docs: [],
			},
			portal_status: false,
			msg: "Loading....please wait...!",
		};
	},
	computed: {
		...mapGetters({
			report_submission: "settings/report_submission_status",
		}),
		user_is_party() {
			return userIsParty();
		},
	},
	methods: {
		...mapActions({
			addForm: "form_ec16d/addForm",
			deleteById: "form_ec16d/deleteById",
			statusReportSubmission: "settings/statusReportSubmission",
			getSupportingDocuments: "settings/getSupportingDocuments",
		}),

		async saveAll() {
			await this.addForm(this.form).then((response) => {
				if (response) {
					EventBus.$emit("response_received", { data: response.data });

					this.clearFormInputs();
				}
			});
		},

		clearFormInputs() {
			this.form.donor_name = "";
			this.form.donor_address = "";
			this.form.donor_occupation = "";
			this.form.contribution_type = "";
			this.form.contribution_description = "";
			this.form.contribution_value = null;
			this.form.date_received = null;
			// this.form.supporting_docs = [];
		},

		inputMustBeCurrency(input_id) {
			return mustBeCurrency(input_id);
		},

		setInput(params) {
			this.form[params.name] = params.value;
		},

		getPortalStatus() {
			if (this.report_submission && this.report_submission.status) {
				this.portal_status = this.report_submission.status;
				this.msg = this.report_submission.msg;

				return;
			}

			this.statusReportSubmission().then((response) => {
				this.portal_status = response.status;
				this.msg = response.msg;
			});
		},

		reloadMultiSelect() {
			initializeMultipleSelect("supporting_docs", this.documents);
		},

		listSupportingDocuments() {
			this.getSupportingDocuments({ status: "enabled" }).then((response) => {
				this.documents = response.data;
				this.reloadMultiSelect();
			});
		},
	},
	mounted() {
		if (this.user_is_party) {
			this.getPortalStatus();
		}

		this.listSupportingDocuments();
	},
};
</script>
<style scoped>
.form-group .form-control {
	padding: 0.625rem 1.1875rem;
}
.btn-info {
	width: 200px;
	border-radius: 6px;
}
</style>
